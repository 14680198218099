import React from 'react';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ModalProps } from '@mui/material/Modal';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, Modal } from 'components/primitives';
import useStyles from './NoSpaceModal.styles';

interface INoSpaceModalProps {
  loading?: boolean;
  onConfirm?: (confirm: boolean) => void;
}

type NoSpaceModalProps = INoSpaceModalProps & ModalProps;

const NoSpaceModal: React.FC<NoSpaceModalProps & BoxProps> = ({
  loading = false,
  onConfirm,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const onTouchButton = (confirm: boolean) => {
    if (!loading) onConfirm && onConfirm(confirm);
  };

  return (
    <Modal {...props}>
      <>
        <Box sx={classes.container}>{props.children}</Box>
        <Box sx={classes.footer}>
          {!loading && (
            <Button sx={classes.cancelBtn} fullWidth onClick={() => onTouchButton(false)}>
              CANCEL
            </Button>
          )}
          <Button
            sx={{ pr: loading ? 5 : 2 }}
            fullWidth
            onClick={() => onTouchButton(true)}
            endIcon={loading && <CircularProgress color='inherit' size={16} sx={{ mr: '4px' }} />}>
            {loading ? 'Please wait' : 'LOGIN'}
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default NoSpaceModal;
