import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
import BuildIcon from '@mui/icons-material/Build';
import { Typo } from 'components/primitives';
import useStyles from './ListItem.styles';

interface ListItemViewProps {
  title: string;
  btnGroup?: React.ReactNode;
  hasPermission?: boolean;
  onClick?: () => void;
  handlePermission?: () => void;
}

const ListItemView: React.FC<ListItemViewProps & BoxProps> = ({
  title,
  hasPermission = false,
  ...props
}) => {
  const classes = useStyles(hasPermission);

  return (
    <Box sx={classes.container} {...props}>
      <Box sx={classes.contentWrapper}>
        <Box
          sx={classes.columnWrapper}
          onClick={() =>
            hasPermission
              ? props.handlePermission
                ? props.handlePermission()
                : undefined
              : undefined
          }>
          <WebAssetOutlinedIcon fontSize='medium' sx={{ color: '#252525' }} />
          <Typo sx={classes.title}>{title}</Typo>
          {hasPermission && (
            <BuildIcon fontSize='small' sx={{ color: '#252525', height: '14px' }} />
          )}
        </Box>
        <Box sx={classes.columnWrapper}>{props.btnGroup}</Box>
      </Box>
    </Box>
  );
};

export default ListItemView;
