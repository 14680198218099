const useStyles = () => ({
  wrapper: {
    flex: '1 1',
    flexBasis: '1e-9px',
    minWidth: '250px',
    marginLeft: '6px',
    marginRight: '6px',
    minHeight: '56px',
  },
});

export default useStyles;
