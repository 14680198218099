import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAuthEmptyState, ITokenState } from './AuthState';

const initialState: ITokenState = UserAuthEmptyState;

export const authSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<ITokenState>) => {
      return action.payload;
    },
    updateJWTToken: (state, action: PayloadAction<string>) => {
      state.jwToken = action.payload;
    },
  },
});

export const { updateToken, updateJWTToken } = authSlice.actions;

export default authSlice.reducer;
