import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserManageState, UserManageEmptyState } from './userManageState';
import {
  addUserToGroup,
  createAccount,
  deleteUserToGroup,
  fetchGroups,
  fetchSelectedUser,
  postRequestAccess,
  updateUserAttribute,
  updateUserToPositions,
  verifyRequestAccess,
} from './userManageThunk';
import { updateUser } from '../currentUser/currentUserThunk';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import { ProgramCodes } from 'common/enum';
import { DefineUserAttributesData } from 'common/utils';

const initialState: IUserManageState = UserManageEmptyState;

export const userManageSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserPermission: (state, action: PayloadAction<IPermissionList[]>) => {
      state.userPermission = action.payload;
    },
    setAppCode: (state, action: PayloadAction<ProgramCodes | string | null>) => {
      state.appCode = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSelectedUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        state.user = null;
      })
      .addCase(fetchSelectedUser.fulfilled, (state, action) => {
        const SelectedUser = action.payload;
        SelectedUser.attributes = DefineUserAttributesData(SelectedUser?.attributes || []);
        state.loading = false;
        state.user = SelectedUser;
      })
      .addCase(fetchSelectedUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        if (action.payload) {
          state.groupLists = action.payload.sort((a, b) =>
            a.pvGroupName.localeCompare(b.pvGroupName)
          );
        }
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(addUserToGroup.pending, (state) => {
        state.inProcess = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addUserToGroup.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(addUserToGroup.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(updateUserToPositions.pending, (state) => {
        state.inProcess = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateUserToPositions.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(updateUserToPositions.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(deleteUserToGroup.pending, (state) => {
        state.inProcess = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteUserToGroup.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(deleteUserToGroup.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(updateUser.pending, (state) => {
        state.inProcess = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(updateUserAttribute.pending, (state) => {
        state.inProcess = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateUserAttribute.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(updateUserAttribute.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(createAccount.pending, (state) => {
        state.inProcess = true;
        state.success = false;
        state.error = null;
      })
      .addCase(createAccount.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(postRequestAccess.pending, (state) => {
        state.inProcess = true;
        state.success = false;
      })
      .addCase(postRequestAccess.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(postRequestAccess.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(verifyRequestAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(verifyRequestAccess.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(verifyRequestAccess.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      });
  },
});

export const { updateUserPermission } = userManageSlice.actions;

export default userManageSlice.reducer;
