import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserAccount, IUserCreatePayload, IUserPortal } from 'interfaces/userinfo.interface';
import {
  IGroupList,
  IAssignGroupPayload,
  IRevokeGroupPayload,
  AssignGroupAdminPayload,
} from 'interfaces/groups.interface';
import { IPositionsPayload } from 'interfaces/positions.interface';
import { PayloadAttribute } from 'interfaces/attribute.interface';
import { IManageColumns } from 'interfaces/manage-columns.interface';
import { AppDispatch, RootState } from 'store/index';
import apiFetch from 'services/apiFetch';
import {
  getOneUser,
  createUser,
  requestAccess,
  verifyAccount,
  deleteUser,
  getManageColumns,
} from 'services/userEndpoint';
import {
  getGroups,
  addToGroup,
  updateUserPositions,
  removeUserToGroup,
  updateSiteAccess,
  updateGroupAdmin,
} from 'services/permissionsEndpoint';

export const fetchSelectedUser = createAsyncThunk(
  'fetch-selected-user',
  async (id: number, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = getOneUser(id);
      return await apiFetch<Partial<IUserPortal>>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('fetchSelectedUser catch:', error);
      throw Error(message);
    }
  }
);

export const fetchGroups = createAsyncThunk('fetch-groups', async (_, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch as AppDispatch;
    const endpoint = getGroups();
    return await apiFetch<IGroupList[]>(endpoint, store, dispatch).then((res) => res?.data || []);
  } catch (error) {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('fetch-groups catch:', error);
    throw Error(message);
  }
});

export const addUserToGroup = createAsyncThunk(
  'add-user-group',
  async (data: IAssignGroupPayload, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = addToGroup(data);
      return await apiFetch(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('add-user-group catch:', error);
      throw Error(message);
    }
  }
);

export const updateUserToPositions = createAsyncThunk(
  'update-user-position',
  async (data: IPositionsPayload, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = updateUserPositions(data);
      return await apiFetch<string>(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('update-user-position catch:', error);
      throw Error(message);
    }
  }
);

export const deleteUserToGroup = createAsyncThunk(
  'delete-user-group',
  async (data: IRevokeGroupPayload, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = removeUserToGroup(data);
      return await apiFetch<string>(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('delete-user-group catch:', error);
      throw Error(message);
    }
  }
);

export const updateUserAttribute = createAsyncThunk(
  'update-user-attribute',
  async (data: PayloadAttribute, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = updateSiteAccess(data);
      return await apiFetch<Partial<IUserPortal>>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('update-user-attribute catch:', error);
      throw Error(message);
    }
  }
);

export const createAccount = createAsyncThunk(
  'create-account',
  async (data: IUserCreatePayload, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = createUser(data);
      return await apiFetch<Partial<IUserAccount>>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('create-account catch:', error);
      throw Error(message);
    }
  }
);

export const postRequestAccess = createAsyncThunk(
  'request-access',
  async (data: IUserCreatePayload, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = requestAccess(data);
      return await apiFetch<Partial<IUserAccount>>(endpoint, store, dispatch).then(
        (res) => res?.data
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('request-access catch:', error);
      throw Error(message);
    }
  }
);

export const verifyRequestAccess = createAsyncThunk(
  'verify-request-access',
  async (data: { accessToken: string; userId: number }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = verifyAccount(data);
      return await apiFetch<string>(endpoint, store, dispatch).then((res) => res?.data);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('verify-request-access catch:', error);
      throw Error(message);
    }
  }
);

export const setGroupAdmin = createAsyncThunk(
  'set-group-admin',
  async (data: AssignGroupAdminPayload, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = updateGroupAdmin(data);
      return await apiFetch<string>(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      throw Error(message);
    }
  }
);

export const deleteUserEntry = createAsyncThunk('delete-user', async (id: number, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch as AppDispatch;
    const endpoint = deleteUser(id);
    return await apiFetch<{ success: boolean } | null>(endpoint, store, dispatch).then(
      (res) => res?.data || null
    );
  } catch (error) {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    throw Error(message);
  }
});

export const fetchTableManageColumns = createAsyncThunk(
  'fetch-table-manage-columns',
  async (_, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = getManageColumns({ accessToken: store.token.authorization || '' });
      return await apiFetch<IManageColumns>(endpoint, store, dispatch).then(
        (res) => res?.data || {}
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      throw Error(message);
    }
  }
);
