import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    height: '150px',
    minWidth: '315px',
    maxWidth: '450px',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '4px',
    background: theme.palette.common.white,
    // boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    minWidth: '370px',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    cursor: 'pointer',
  },
  lastStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export default useStyles;
