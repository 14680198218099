/* eslint-disable @typescript-eslint/no-explicit-any */
import { Attributes } from 'interfaces/attribute.interface';
import { SiteStatusCodes } from 'common/enum';
import { findKeyIndAttribute } from 'common/utils';

export function SetNewAttributesData(
  attribute: Attributes[],
  indexOfA: number,
  appCode: string,
  code: SiteStatusCodes,
  groupId: number,
  withGroup: boolean,
  permissionType?: unknown,
  isRevoke = false,
  isUpdate = false
) {
  const tempAttributes: Attributes[] = attribute.map((obj, idx) => {
    if (idx === indexOfA) {
      const arrayOfAppCode: any = obj[appCode];
      const isGroupCodeExist = findKeyIndAttribute(arrayOfAppCode, String(groupId)) > -1;
      const updatedAttribute = arrayOfAppCode.map((objA: any) => {
        const objCopy = { ...objA };
        if (Object.prototype.hasOwnProperty.call(objA, 'S')) {
          objCopy.S = code;
          return objCopy;
        }
        if (Object.prototype.hasOwnProperty.call(objA, String(groupId))) {
          // ?: Update Site Status for Selected Group
          const groupDataObj = objCopy[String(groupId)];
          if (Array.isArray(groupDataObj) && groupDataObj.length) {
            const idxOfSiteAccessCode = findKeyIndAttribute(groupDataObj, 'S');
            if (idxOfSiteAccessCode > -1) {
              // ?: Update Site Status for Selected Group
              const newObject = { S: code };
              objCopy[String(groupId)] = [
                ...groupDataObj.slice(0, idxOfSiteAccessCode),
                newObject,
                ...groupDataObj.slice(idxOfSiteAccessCode + 1),
              ];
            }
          }

          if (
            typeof groupDataObj === 'object' &&
            Object.prototype.hasOwnProperty.call(groupDataObj, 'S')
          ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            groupDataObj.S = code;
            objCopy[String(groupId)] = groupDataObj;
          }
          return objCopy;
        }
        return objA;
      });

      const indexOfA = findKeyIndAttribute(updatedAttribute, String(groupId));

      if (!isGroupCodeExist && code === SiteStatusCodes.ACTIVE) {
        // ?: Add Group Active Status
        updatedAttribute.push({ [String(groupId)]: [{ S: code }] });
      }

      if (withGroup && typeof permissionType === 'string') {
        const indexOfG = findKeyIndAttribute(updatedAttribute, String(groupId));
        if (indexOfG > -1) {
          const ObjGroupCopy = updatedAttribute[indexOfG];
          const tempGroupArr: { [key: string]: string | string[] }[] = [];
          if (ObjGroupCopy && Object.prototype.hasOwnProperty.call(ObjGroupCopy, String(groupId))) {
            const valueOfGroup = ObjGroupCopy[groupId];
            if (Array.isArray(valueOfGroup)) {
              const idxOfP = findKeyIndAttribute(valueOfGroup, 'P');
              tempGroupArr.push({ S: code });
              if (idxOfP > -1) {
                // ?: Add permission to existing permissions
                const copyOfPermissionObj = valueOfGroup[idxOfP];
                const copyOfArrP = copyOfPermissionObj.P;
                if (Array.isArray(copyOfArrP)) {
                  copyOfArrP.push(permissionType);
                }
                tempGroupArr.push({ P: copyOfArrP });
              } else {
                tempGroupArr.push({ P: [permissionType] });
              }
            }
          }
          updatedAttribute.splice(indexOfG, 1);
          updatedAttribute.push({ [groupId]: tempGroupArr });
        }
      }

      if (isRevoke && groupId && indexOfA > -1) {
        // ?: Remove the Assigned Group Object
        updatedAttribute.splice(indexOfA, 1);
      }

      if (isUpdate) {
        //  ?: Replace with the new Permission Type
        const copyOfAttribute = updatedAttribute;
        // *: Remove Existing Permission Type
        copyOfAttribute.splice(indexOfA, 1);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        copyOfAttribute.push({ [String(groupId)]: [{ S: 'ACT' }, { P: [permissionType] }] });
        return { [appCode]: copyOfAttribute };
      }

      return { [appCode]: updatedAttribute };
    }
    return obj;
  });
  if (indexOfA === -1) {
    tempAttributes.push({ [appCode]: [{ S: code }, { [String(groupId)]: [{ S: code }] }] });
  }
  return tempAttributes;
}
