import React from 'react';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { Box, Container, IconButton, ToggleButtonGroup, useTheme } from '@mui/material';
import PalmettoOTP from '@zawarski/palmetto-react-otp';
import MenuIcon from '@mui/icons-material/Menu';
import { MainLayout } from 'components/layout';
import { DrawerMenu, ToggleButton, Typo, Modal } from 'components/primitives';
import { AppCard } from 'components/snippets';
import { RequestModal } from 'components/modal';
import { Loading } from 'components/fragments';
import { ProgramCodes, ProgramTitles, SiteStatusCodes, ProgramSubTitles } from 'common/enum';
import { findKeyIndAttribute } from 'common/utils';
import { SCEMDCountyIDs } from 'common/static';
import { DashboardProps } from './Dashboard.props';
import useStyles from './Dashboard.styles';

const DashboardView: React.FC<DashboardProps> = ({
  handleMenu,
  openPage,
  openModal,
  detailsValue,
  onChangeDetails,
  activeGroupId,
  currentUser,
  toggleAllSites,
  handleOpenModal,
  groupName,
  isLoading,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const programLists = Object.keys(ProgramCodes);
  const userEmail = currentUser?.email || '';
  const userPhone = currentUser?.pvMobilePhone
    ? currentUser.pvMobilePhone
    : currentUser?.pvOfficePhone
    ? currentUser.pvOfficePhone
    : '';

  if (isLoading)
    return (
      <Box sx={classes.loadingContainer}>
        <Loading message='Loading data please wait...' />
      </Box>
    );

  return (
    <MainLayout toolbar={<DrawerMenu />}>
      <div className='layout vertical full-height'>
        <HeaderComponent
          icon={
            <IconButton onClick={handleMenu}>
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          title={
            <Typo variant='h5'>
              Home
              <span style={{ fontWeight: 500, color: 'rgba(255,255,255,0.5)', paddingLeft: '8px' }}>
                {groupName}
              </span>
            </Typo>
          }
        />
        <div className='flex form-shell container-overflow-y'>
          <Container disableGutters maxWidth='xl'>
            <Box sx={classes.btnGroupContainer}>
              <ToggleButtonGroup
                sx={classes.toggleBtnGroup}
                color='primary'
                value={toggleAllSites}
                exclusive
                onChange={props.handleToggle}
                aria-label='text alignment'>
                <ToggleButton value={true} aria-label='left aligned'>
                  <Box>ALL SITES</Box>
                </ToggleButton>
                <ToggleButton value={false} aria-label='centered'>
                  <Box>MY SITES</Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {/*<Box sx={classes.appWrapper}></Box>*/}
            <Box sx={classes.appContainer}>
              {programLists.length
                ? programLists.map((value) => {
                    const indexOfS = Object.values(ProgramCodes).indexOf(
                      value as unknown as ProgramCodes
                    );
                    const appCode = Object.keys(ProgramCodes)[indexOfS];
                    const appTitle = Object.values(ProgramTitles)[indexOfS];
                    const appSubtitle = Object.values(ProgramSubTitles)[indexOfS];
                    const IsNotSCEMDCountyGroup = !SCEMDCountyIDs.includes(activeGroupId);
                    let showCard = true;
                    // If the Current Group is NOT SCEMD
                    if (activeGroupId !== 1) {
                      if (appCode === ProgramCodes.CDOWN) {
                        // *: Hide Call Down APP
                        showCard = false;
                      }
                      if (
                        activeGroupId > 48 &&
                        (appCode === ProgramCodes.LEMPG ||
                          appCode === ProgramCodes.SUPPTL ||
                          appCode === ProgramCodes.SERCLEPC)
                      ) {
                        // *: Hide LEMPG / SUPPLEMENTAL / SERCLEPC APP for Non-State and Sub-group Users
                        showCard = false;
                      }
                    }

                    if (IsNotSCEMDCountyGroup && appCode === ProgramCodes.REP) {
                      // *: Hide REP APP for Non-SCEMD County Users
                      showCard = false;
                    }

                    if (currentUser && currentUser.attributes && !toggleAllSites) {
                      const userAttributes = currentUser.attributes;
                      const indexOfA = findKeyIndAttribute(userAttributes, appCode);
                      const attribute = userAttributes[indexOfA];
                      if (indexOfA > -1) {
                        const arrAttribute = attribute[appCode];
                        if (arrAttribute.length > 1) {
                          const indexOfGroup = findKeyIndAttribute(
                            arrAttribute,
                            String(activeGroupId)
                          );
                          if (indexOfGroup > -1) {
                            const groupObj = arrAttribute[indexOfGroup];
                            if (typeof groupObj === 'object') {
                              if (
                                Object.prototype.hasOwnProperty.call(
                                  groupObj,
                                  String(activeGroupId)
                                )
                              ) {
                                const groupArr = groupObj[activeGroupId];
                                if (Array.isArray(groupArr) && groupArr.length) {
                                  const appStatus = groupArr[0];
                                  if (Object.prototype.hasOwnProperty.call(appStatus, 'S')) {
                                    if (
                                      appStatus.S === SiteStatusCodes.DENY ||
                                      appStatus.S === SiteStatusCodes.SUSPEND
                                    )
                                      showCard = false;
                                  }
                                }

                                if (Array.isArray(groupArr) && groupArr.length === 0) {
                                  // To check has Group ID but has empty attributes
                                  const appStatus = arrAttribute[0];
                                  if (Object.prototype.hasOwnProperty.call(appStatus, 'S')) {
                                    if (
                                      appStatus.S === SiteStatusCodes.DENY ||
                                      appStatus.S === SiteStatusCodes.SUSPEND
                                    )
                                      showCard = false;
                                  }
                                }
                              }
                            }
                          } else {
                            showCard = false;
                          }
                        } else {
                          // If App is not assigned to a group ID
                          const appStatus = arrAttribute[0];
                          if (Object.prototype.hasOwnProperty.call(appStatus, 'S')) {
                            if (
                              appStatus.S === SiteStatusCodes.DENY ||
                              appStatus.S === SiteStatusCodes.SUSPEND
                            )
                              showCard = false;
                          }
                        }
                        // if (indexOfGroup === -1 && appCode !== ProgramCodes.PEOC) {
                        //   // Check if Active
                        //   const SiteStatus = arrAttribute[0] as { S: SiteStatusCodes };
                        //   if (
                        //     (SiteStatus.S === SiteStatusCodes.ACTIVE || SiteStatusCodes.REQUEST) &&
                        //     SiteStatus.S !== SiteStatusCodes.DENY
                        //   ) {
                        //     showCard = false;
                        //   }
                        // }
                      } else {
                        showCard = false;
                      }
                    }
                    if (!showCard) {
                      return null;
                    }
                    return (
                      <AppCard
                        key={appCode}
                        appCode={appCode}
                        title={appTitle}
                        currentUser={currentUser}
                        subtitle={appSubtitle}
                        activeGroupId={activeGroupId}
                        cb={openPage}
                        handleOpenModal={handleOpenModal}
                      />
                    );
                  })
                : null}
            </Box>
          </Container>
          <RequestModal
            open={openModal}
            loading={props.isProcessing}
            withDetails
            detailsValue={detailsValue}
            onChangeDetails={onChangeDetails}
            title='Thank you for your request!'
            onConfirm={props.onSubmitRequest}>
            <Typo color='common.black'>
              You can add additional details below in order to get approved faster.
            </Typo>
          </RequestModal>
          <Modal sx={{ overflow: 'hidden' }} open={props.otpModal}>
            <PalmettoOTP
              email={userEmail}
              phone={userPhone}
              idToken={props.jwtToken || ''}
              onClose={props.handleOTPModal}
            />
          </Modal>
        </div>
      </div>
    </MainLayout>
  );
};

export default DashboardView;
