import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import {
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  useTheme,
} from '@mui/material';

import useStyles from './SelectGroup.styles';
import { Typo } from 'components/primitives';
import { IGroupList } from 'interfaces/groups.interface';

const SelectGroup: React.FC<
  { disabled?: boolean; groupLists: IGroupList[]; isSubGroup?: boolean } & BoxProps
> = ({ disabled = false, isSubGroup = false, groupLists, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { control } = useFormContext();

  return (
    <Box {...props}>
      <FormControl variant='standard' size='small' sx={classes.wrapper}>
        <Typo color={theme.palette.grey[700]} fontSize={16}>
          {isSubGroup ? 'Subgroup Assignment' : 'Group'}
        </Typo>
        <Controller
          name={'groupId'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              fullWidth
              sx={classes.selectWrapper}
              value={value}
              onChange={onChange}
              disabled={disabled}>
              {groupLists && groupLists.length
                ? groupLists.map((val) => (
                    <MenuItem key={val.pvGroupID} value={val.pvGroupID}>
                      {val.pvGroupName}
                    </MenuItem>
                  ))
                : null}
            </Select>
          )}
        />
      </FormControl>
      <FormGroup sx={{ pl: 1 }}>
        <Controller
          name={'isGroupAdmin'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label={`Make ${isSubGroup ? 'Subgroup' : 'Group'} admin`}
            />
          )}
        />
      </FormGroup>
    </Box>
  );
};

export default SelectGroup;
