import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Typo } from 'components/primitives';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import useStyles from './InfoCard.styles';

interface InfoCardProps {
  title: string;
  subTitle?: string;
  item?: unknown;
}
const InfoCard: React.FC<InfoCardProps & BoxProps> = ({ title, subTitle, ...props }) => {
  const classes = useStyles();

  return (
    <Box sx={classes.container} {...props}>
      <Box sx={classes.contentWrapper}>
        <DescriptionOutlinedIcon fontSize='medium' />
        <Typo sx={classes.title}>{title}</Typo>
        {subTitle ? <Typo sx={classes.subTitle}>{subTitle}</Typo> : null}
      </Box>
    </Box>
  );
};

export default InfoCard;
