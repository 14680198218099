// noinspection JSUnusedGlobalSymbols

export enum ProgramCodes {
  PEOC = 'PEOC',
  LEMPG = 'LEMPG',
  MITG = 'MITG',
  SERCLEPC = 'SERCLEPC',
  PTRACK = 'PTRACK',
  PTRAIN = 'PTRAIN',
  SUPPTL = 'SUPPTL',
  EGRESS = 'EGRESS',
  DMA = 'DMA',
  DMAM = 'DMAM',
  CDOWN = 'CDOWN',
  REP = 'REP',
  VEOC = 'VEOC',
  HURH = 'HURH',
  GIS = 'GIS',
}

export enum ProgramTitles {
  PEOC = 'Palmetto EOC',
  LEMPG = 'LEMPG',
  MITG = 'Mitigation',
  SERCLEPC = 'SERC/LEPC',
  PTRACK = 'Patient Tracking',
  PTRAIN = 'Palmetto Training',
  SUPPTL = 'Supplemental',
  EGRESS = 'Entry / Egress',
  DMA = 'Main Damage Assessment',
  DMAM = 'Mobile Damage Assessment',
  CDOWN = 'Calldown',
  REP = 'Radiological Emergency Preparedness',
  VEOC = 'Virtual EOC',
  HURH = 'Hurricane Hub',
  GIS = 'GIS Hub',
}

export enum ProgramSubTitles {
  PEOC = 'South Carolina’s Common Operating Picture, the system of record for real-world events',
  LEMPG = 'Documents each step of the Local Emergency Management Performance Grant (LEMPG) process',
  MITG = 'Lifecycle of applicant’s mitigation plan and the details for mitigation projects',
  SERCLEPC = 'Gathers annual data from Local Emergency Planning Committees (LEPC) across the state and the State Emergency Planning Committee (SERC)',
  PTRACK = 'A mass causality program that assigns triage patients to an EMS vehicle and records which hospital the patients are dropped off',
  PTRAIN = 'Replica of the Palmetto site, available to anyone with a Palmetto user account',
  SUPPTL = 'Same features as LEMPG site to document additional grant funding process',
  EGRESS = 'Scan either a Salamander badge or SC Driver’s license, record the date/time people enter or leave a location',
  DMA = 'Gathers IA and PA entries, displays entries on a map, and produces FEMA reports',
  DMAM = 'For use by anyone going out in the field to gather IA and PA data after a real world disaster',
  CDOWN = 'After a real-world event, this site records calls to determine the event’s impact to businesses across the impacted area',
  REP = 'Tracks equipment calibration due dates, plant exercises, annual letter of certification workflow',
  VEOC = 'A site to help facilitate communication between EOC team members that are working in various locations',
  HURH = 'The Hurricane Hub serves as a one-stop-shop for hurricane preparedness information and coordination. Certain pages will only be visible during a tropical event.',
  GIS = 'The SCEMD GIS Hub serves as a one-stop shop for statewide GIS information.',
}

export enum SiteStatusCodes {
  ACTIVE = 'ACT',
  REQUEST = 'REQ',
  DENY = 'DNY',
  SUSPEND = 'SUS',
}

export enum ProgramSites {
  PEOC = 'https://www.palmettoeoc.com',
  LEMPG = 'https://lempg.palmettoeoc.com',
  MITG = 'https://mitigation.palmettoeoc.com',
  SERCLEPC = 'https://lepc.palmettoeoc.com',
  PTRACK = 'https://emt.palmettoeoc.com',
  PTRAIN = 'https://training.palmettoeoc.com',
  SUPPTL = 'https://supplemental.palmettoeoc.com',
  EGRESS = 'https://scan.palmettoeoc.com',
  DMA = 'https://damage.palmettoeoc.com',
  DMAM = 'https://dma.palmettoeoc.com',
  CDOWN = 'https://calldown.palmettoeoc.com',
  REP = 'https://rep.palmettoeoc.com',
  VEOC = 'https://veoc.palmettoeoc.com',
  HURH = 'https://experience.arcgis.com/experience/925d8c5313f34bf5aa49215a91121176',
  GIS = 'https://gis-scemd.hub.arcgis.com',
}

export default ProgramCodes;
