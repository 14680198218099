import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentControlPanelEmptyState, ICurrentControlPanelState } from './controlPanel.state';
import { fetchSelectedGroup, postGroupInfo, voidGroup } from './controlPanel.thunk';
import { genericErrorMessage } from 'services/apiFetch';
import { IGroupContact, IGroupList } from 'interfaces/groups.interface';

const initialState: ICurrentControlPanelState = CurrentControlPanelEmptyState;

export const controlPanelSlice = createSlice({
  name: 'controlPanel',
  initialState,
  reducers: {
    // Add reducers here
    updateSelectedGroup: (state, action: PayloadAction<IGroupList | null>) => {
      state.selectedGroup = action.payload;
      state.selectedGroupWithContacts = null;
    },
    updateProcessing: (state, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
    },
    resetState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // Add extra reducers here
    builder
      .addCase(fetchSelectedGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchSelectedGroup.fulfilled, (state, action) => {
        const currentGroup = state.selectedGroup;
        state.loading = false;
        if (currentGroup) {
          const cloneGroup = {
            ...currentGroup,
            contacts: [] as IGroupContact[],
          };
          cloneGroup.contacts = action.payload.dataset;
          state.selectedGroupWithContacts = cloneGroup;
        }
      })
      .addCase(fetchSelectedGroup.rejected, (state, action) => {
        state.loading = false;
        state.selectedGroup = null;
        state.error = action.error.message ?? genericErrorMessage;
      })
      .addCase(postGroupInfo.pending, (state) => {
        state.processing = true;
        state.error = null;
      })
      .addCase(postGroupInfo.fulfilled, (state) => {
        state.processing = false;
        state.success = true;
      })
      .addCase(postGroupInfo.rejected, (state, action) => {
        state.processing = false;
        state.success = false;
        state.error = action.error.message ?? genericErrorMessage;
      })
      .addCase(voidGroup.pending, (state) => {
        state.processing = true;
        state.error = null;
      })
      .addCase(voidGroup.fulfilled, (state) => {
        state.processing = false;
        state.success = true;
      })
      .addCase(voidGroup.rejected, (state, action) => {
        state.processing = false;
        state.success = false;
        state.error = action.error.message ?? genericErrorMessage;
      });
  },
});

export const { updateSelectedGroup, resetState } = controlPanelSlice.actions;

export default controlPanelSlice.reducer;
