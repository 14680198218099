/* eslint-disable react/prop-types */

import React from 'react';
import { findIndex } from 'lodash';
import { Controller, FormProvider } from 'react-hook-form';
import { HeaderComponent, HeaderButton } from '@zawarski/palmetto-ui-components';
import { MainLayout } from 'components/layout';
import { Typo, TextField, FormGroup, Button } from 'components/primitives';
import { AssignGroupModal } from 'components/modal';
import { SelectGroup, SelectPosition } from 'components/fragments';
import { StepProgress, GroupCard } from 'components/snippets';
import {
  CircularProgress,
  IconButton,
  Box,
  Stack,
  Grid,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel,
  Fab,
  Paper,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PhoneNumberFormat } from 'common/utils';
import { AssignGroupFormStep, AccessPermission } from 'common/enum';
import useStyles from './UserNew.styles';
import { UserNewProps } from './UserNew.props';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/AddCircle';
import { IAccountPositions } from 'interfaces/userinfo.interface';

const UserNewView: React.FC<UserNewProps> = ({
  step,
  stepHistory,
  isProcessing,
  showPassword,
  form,
  onSubmit,
  toggleShowPassword,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { control, watch, formState } = form;
  const stepIndex = findIndex(stepHistory, { step });
  const stepInfo = stepHistory[stepIndex];
  const lastStep = step === AssignGroupFormStep.POSITION;
  const hasGroup = watch('groupId') > 0;
  const isSubGroup = Number(watch('isSubGroup'));
  const formErrorGroup = formState.errors?.groupId?.message;

  const onNextStep = () => {
    if (lastStep) props.lastStepAction();
    else {
      const index = findIndex(stepHistory, { step: stepInfo?.step });
      const nextStep = stepHistory[index + 1];
      if (nextStep) {
        props.handleChangeStep(nextStep.step as AssignGroupFormStep);
      }
    }
  };

  const onBackStep = () => {
    const index = findIndex(stepHistory, { step: stepInfo?.step });
    const backStep = stepHistory[index - 1];
    props.handleChangeStep(backStep.step as AssignGroupFormStep);
  };

  const handlePhoneChange =
    (onChange: (value: string | undefined | null) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(PhoneNumberFormat(event.target.value));
    };

  const GroupCardList: React.FC<{ selectedPosition: IAccountPositions[] }> = ({
    selectedPosition,
  }) => {
    if (selectedPosition.length > 0) {
      const value = selectedPosition[0];
      return (
        <GroupCard
          key={value.i}
          value={value}
          maxWidth={'320px'}
          onClick={props.handleSelectGroupCard}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <MainLayout>
      <Box sx={classes.container}>
        <HeaderComponent
          icon={
            <IconButton onClick={props.navigateBack}>
              <ArrowBackIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          title={<Typo variant='h5'>New User</Typo>}
          suffix={
            isProcessing ? (
              <HeaderButton style={{ fontSize: 12 }}>
                <CircularProgress sx={{ color: 'white' }} size={24} />
              </HeaderButton>
            ) : (
              <HeaderButton style={{ fontSize: 16 }} onClick={form.handleSubmit(onSubmit)}>
                SAVE
              </HeaderButton>
            )
          }
        />
        <Stack sx={classes.wrapper}>
          <Box sx={{ px: 4, py: 2 }}>
            {formErrorGroup && formErrorGroup.length ? (
              <Box
                sx={{
                  borderRadius: 2,
                  border: `1px solid ${theme.palette.error.main}`,
                  px: 4,
                  py: 1,
                  textAlign: 'center',
                }}>
                <Typo color={theme.palette.error.main} fontSize={16}>
                  {formErrorGroup}
                </Typo>
              </Box>
            ) : null}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvPersonGivenName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='First name'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvPersonSurName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Last name'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='username'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Username'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='email'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Email address'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvOfficePhone'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Work Phone'
                      value={PhoneNumberFormat(value)}
                      onChange={handlePhoneChange(onChange)}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvMobilePhone'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Cell Phone'
                      value={PhoneNumberFormat(value)}
                      onChange={handlePhoneChange(onChange)}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvOrganizationUnitName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Organization'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typo fontWeight={600} color={theme.palette.primary.dark}>
                  CREDENTIALS
                </Typo>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='password'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Password'
                      type={showPassword ? 'text' : 'password'}
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ cursor: 'pointer', pr: 1 }}
                            position='end'
                            onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </InputAdornment>
                        ),
                        autoComplete: 'new-password',
                      }}
                      autoComplete='new-password'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='confirmPassword'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Confirm Password'
                      type={showPassword ? 'text' : 'password'}
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ cursor: 'pointer', pr: 1 }}
                            position='end'
                            onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </InputAdornment>
                        ),
                        autoComplete: 'new-password',
                      }}
                      autoComplete='new-password'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typo fontWeight={600} color={theme.palette.primary.dark}>
                  SETTINGS
                </Typo>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='subscribeToEmail'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='Subscribe to email notifications' error={error}>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value}
                        onChange={onChange}
                        sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                        />
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={false}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
              {props.accessPermission === AccessPermission.SUPERADMIN ? (
                <Grid item xs={12} md={6}>
                  <Controller
                    name='pvAdministrator'
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <FormGroup label='Super Admin' error={error}>
                        <RadioGroup
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name='controlled-radio-buttons-group'
                          value={value}
                          onChange={onChange}
                          sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            style={{ marginLeft: '0px' }}
                            value={1}
                            control={<Radio />}
                            label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                          />
                          <FormControlLabel
                            style={{ marginLeft: '0px' }}
                            value={0}
                            control={<Radio />}
                            label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                          />
                        </RadioGroup>
                      </FormGroup>
                    )}
                  />
                </Grid>
              ) : null}
              {props.accessPermission === AccessPermission.GROUPADMIN ? (
                <Grid item xs={12} md={6}>
                  <Controller
                    name='isSubGroup'
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <FormGroup label='Subgroup User' error={error}>
                        <RadioGroup
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name='controlled-radio-buttons-group'
                          value={value}
                          onChange={onChange}
                          sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            style={{ marginLeft: '0px' }}
                            value={1}
                            control={<Radio />}
                            label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                          />
                          <FormControlLabel
                            style={{ marginLeft: '0px' }}
                            value={0}
                            control={<Radio />}
                            label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                          />
                        </RadioGroup>
                      </FormGroup>
                    )}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} md={6}>
                <Controller
                  name='pvTrainingAccount'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='Limited to training account' error={error}>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value}
                        onChange={onChange}
                        sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={1}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                        />
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={0}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvMultipleUser'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='Multi-user account' error={error}>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value}
                        onChange={onChange}
                        sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={1}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                        />
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={0}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typo fontWeight={600} color={theme.palette.primary.dark}>
                  GROUP
                </Typo>
              </Grid>
              <Grid item xs={12}>
                {props.selectedPosition && props.selectedPosition.length ? (
                  <GroupCardList selectedPosition={props.selectedPosition} />
                ) : (
                  <Fab
                    color='primary'
                    variant='extended'
                    size='medium'
                    onClick={() => props.handleModal(true)}>
                    Add Group <AddIcon className='icon' style={{ marginLeft: '6px' }} />
                  </Fab>
                )}
              </Grid>
            </Grid>
            <AssignGroupModal handleModal={props.handleModal} open={props.openAssignGroup}>
              <Box sx={classes.modalContainer}>
                <FormProvider {...form}>
                  <SelectGroup
                    hidden={step !== AssignGroupFormStep.GROUP}
                    disabled={props.disableGroupSelect}
                    groupLists={props.groupLists}
                    isSubGroup={isSubGroup === 1}
                  />
                  <SelectPosition
                    maxWidth={'420px !important'}
                    sx={{ mb: '72px' }}
                    hidden={step !== AssignGroupFormStep.POSITION}
                    rootName={'positions'}
                  />
                </FormProvider>
                <Paper elevation={0} variant='outlined' sx={classes.stepContainer}>
                  {props.hasNoPositions ? null : <StepProgress stepIndex={stepIndex} />}
                  <Box sx={classes.btnGroup}>
                    {props.hasNoPositions ? (
                      <Button
                        sx={{ minWidth: '150px' }}
                        disabled={props.fetchingPosition || !hasGroup}
                        onClick={() => props.lastStepAction()}>
                        Add
                      </Button>
                    ) : (
                      <>
                        <Button
                          alternate
                          sx={{ minWidth: '150px' }}
                          disabled={props.fetchingPosition || !lastStep}
                          onClick={() => onBackStep()}>
                          Back
                        </Button>
                        <Button
                          sx={{ minWidth: '150px' }}
                          disabled={props.fetchingPosition}
                          onClick={() => onNextStep()}>
                          {lastStep ? 'Add' : 'Next'}
                        </Button>
                      </>
                    )}
                  </Box>
                </Paper>
              </Box>
            </AssignGroupModal>
          </Box>
        </Stack>
      </Box>
    </MainLayout>
  );
};

export default UserNewView;
