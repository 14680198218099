import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, ref } from 'yup';
import { Nullable } from 'common/utils';

export interface IMyAccountFormData {
  id?: number;
  pvPersonGivenName: string;
  pvPersonSurName: string;
  username: string;
  email: string;
  pvOfficePhone: Nullable<string>;
  pvMobilePhone: Nullable<string>;
  pvOrganizationUnitName: string;
  password: string;
  confirmPassword: string;
}

export const defaultValues = {
  pvPersonGivenName: '',
  pvPersonSurName: '',
  username: '',
  email: '',
  pvOfficePhone: '',
  pvMobilePhone: '',
  pvOrganizationUnitName: '',
  password: '',
  confirmPassword: '',
};

function useMyAccountForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        pvPersonGivenName: string().required('This field is required!').max(255),
        pvPersonSurName: string().required('This field is required!').max(255),
        username: string().required('This field is required!').max(255),
        email: string().email('Not a valid email!').required('This field is required!').max(255),
        pvOfficePhone: string().nullable().max(255),
        pvMobilePhone: string().nullable().max(255),
        pvOrganizationUnitName: string().required('This field is required!').max(255),
        password: string().nullable().max(50),
        confirmPassword: string().oneOf([ref('password')], 'Password must match'),
      }),
    []
  );

  return useForm<IMyAccountFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useMyAccountForm;
