/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import { FormControl, FormGroup, FormControlLabel, Radio, useTheme } from '@mui/material';
import { Typo } from 'components/primitives';
import useStyles from './OptionAccessForm.styles';
import { SpecialOptions } from 'pages/UserPermissionView/hook/useGrantAccess';

const OptionAccessFormView: React.FC<BoxProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { control, watch } = useFormContext();
  const selectionsField: SpecialOptions[] = watch('selections') || [];
  const permissionType = watch('permissionType');

  return (
    <Box {...props}>
      <FormControl sx={classes.wrapper} variant='standard' size='small'>
        <Typo color={theme.palette.grey[700]} fontSize={16}>
          Select Permission
        </Typo>
        <FormGroup>
          {selectionsField && selectionsField.length
            ? selectionsField.map((s, idx) => {
                return (
                  <FormControlLabel
                    key={idx}
                    value={s.value}
                    control={<Radio />}
                    label={<Typo fontWeight={500}>{s.title}</Typo>}
                  />
                );
              })
            : null}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default OptionAccessFormView;
