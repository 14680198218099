import { Theme } from '@mui/material';

import { styled } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = (theme: Theme) => ({
  menuWrapper: {
    height: '100%',
    width: '320px',
    borderRadius: '8px',
    backgroundColor: '#F7F7F7',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
    paddingY: '1.5rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    color: 'common.black',
  },
  upperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5rem',
    width: '100%',
    position: 'relative',
  },
  stackContainer: {
    overflow: 'hidden',
    display: 'flex',
  },
  groupContainer: {
    maxHeight: '520px',
    overflowY: 'auto',
  },
  groupTitle: {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  menuBtn: {
    whiteSpace: 'wrap',
    maxWidth: '200px',
    overflow: 'hidden',
    textAlign: 'left',
    // textOverflow: 'ellipsis',
  },
  activeIcon: {
    color: '#2961FD',
  },
  inActiveIcon: {
    color: '#C2C2C2',
  },
});

const Img = styled(`img`)`
  width: 80px;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
`;

export { Img };

export default useStyles;
