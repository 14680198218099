import { Nullable } from 'utils/Nullable';
import { IUserPortal } from 'interfaces/userinfo.interface';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import { IGroupList } from 'interfaces/groups.interface';
import { IPosition } from 'interfaces/positions.interface';
import { ProgramCodes } from 'common/enum';

export interface IUserManageState {
  user: Partial<IUserPortal> | null;
  userPermission: IPermissionList[];
  groupLists: IGroupList[];
  positionLists: IPosition[];
  appCode: ProgramCodes | null | string;
  loading: boolean;
  success: boolean;
  inProcess: boolean;
  error: Nullable<string> | undefined;
}

export const UserManageEmptyState: IUserManageState = {
  user: null,
  userPermission: [],
  groupLists: [],
  positionLists: [],
  appCode: null,
  loading: false,
  success: false,
  inProcess: false,
  error: null,
};
