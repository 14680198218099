import React from 'react';
import { Card, CardContent, Stack, useTheme } from '@mui/material';
import { Typo } from 'components/primitives';
import { IAccountPositions } from 'interfaces/userinfo.interface';

const GroupCardView: React.FC<{
  key: number;
  value: IAccountPositions;
  maxWidth?: string;
  onClick?: () => void;
}> = ({ value, maxWidth = 'unset', ...props }) => {
  const theme = useTheme();
  const { n, a, positions, i } = value;
  const headerName = `${n} ${a ? ' Group (Admin)' : 'Group'}`;

  return (
    <Card
      key={i}
      variant='outlined'
      sx={{ minWidth: 250, minHeight: 128, m: 1, maxWidth }}
      onClick={props.onClick}>
      <CardContent>
        <Typo fontWeight={500} fontSize={16} color='common.black'>
          {headerName}
        </Typo>
        <Stack direction='column' spacing={1} sx={{ pt: 1 }}>
          {positions && positions.length
            ? positions.map((p) => (
                <Typo key={p.i} fontWeight={400} fontSize={14} color={theme.palette.grey[700]}>
                  {p.n ? `- ${p.n}` : ''}
                </Typo>
              ))
            : `- [ No Positions Assigned ]`}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default GroupCardView;
