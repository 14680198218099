import { IPalmettoColumns } from './interfaces';

const GroupsTableColumns: IPalmettoColumns[] = [
  {
    flex: 1,
    field: 'pvGroupID',
    headerName: 'Group ID',
    numeric: true,
    enabled: false,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvGroupName',
    headerName: 'Group',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvGroupTitle',
    headerName: 'Title',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
];

export default GroupsTableColumns;
