/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection TypeScriptRedundantGenericType

import React from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { TabName } from 'store/slices/menu/menuState';
import { IAccountPositions } from 'interfaces/userinfo.interface';
import { UserPositionsFormData } from './hook/usePositions';
import { GrantAccessFormData } from './hook/useGrantAccess';
import { AccessPermission, AssignGroupFormStep, ProgramCodes } from 'common/enum';
import { UserGroupFormData } from 'hooks/useGroupForm';
import { StackableStep } from '../../common/static';
import { IGroupList } from '../../interfaces/groups.interface';

export interface ISiteStatusList {
  code: string;
  title: string;
  active: boolean;
  hasPermission?: boolean;
}

export enum SiteAction {
  DENY,
  GRANT,
  UPDATE,
}

interface IUserPermissionView {
  step: AssignGroupFormStep;
  stepHistory: StackableStep[];
  userPermission: AccessPermission;
  groupForm: UseFormReturn<UserGroupFormData>;
  positionsForm: UseFormReturn<UserPositionsFormData, any, undefined>;
  grantAccessForm: UseFormReturn<GrantAccessFormData, any, undefined>;
  activeSites: ISiteStatusList[];
  inActiveSites: ISiteStatusList[];
  siteRequests: ISiteStatusList[];
  groupLists: IGroupList[];
  singleGroupUser: boolean;
  isPositionLoading: boolean;
  fetchingUsers: boolean;
  isGroupAdmin: boolean;
  processingGroupAdmin: boolean;
  isRevokeGroupProcessing: boolean;
  groupPosition: IAccountPositions | null;
  tabName: TabName;
  openAssignGroup: boolean;
  openGrantSiteAccess: boolean;
  openGrantSiteRequest: boolean;
  openDenySiteRequest: boolean;
  openRevokeAccess: boolean;
  openRevokeGroup: boolean;
  openEditPositions: boolean;
  inProcessing: boolean;
  isProcessing: boolean;
  inProcessAttribute: boolean;
  hasNoPositions: boolean;
  hasNoPositionsGroupForm: boolean;
  navigateBack: () => void;
  fullName: string;
  messageModal: string;
  onSubmit: SubmitHandler<UserPositionsFormData>;
  handleChangeTab: (event: React.SyntheticEvent, tab: TabName) => void;
  handleModalDenyGrantSiteRequest: (
    appCode: ProgramCodes | string,
    appTitle: string,
    action: SiteAction
  ) => void;
  handleModalRevokeAccess: (appCode: ProgramCodes | string, appTitle: string) => void;
  handleModalRevokeGroup: (confirm: boolean) => void;
  handleOpenModalRevokeGroup: () => void;
  handleModalEditPositions: () => void;
  confirmRevokeAccess: (confirm: boolean) => void;
  onSubmitDenyGrantAccess: (confirm: boolean, action: SiteAction) => void;
  onSubmitOptionsGrantAccess: (confirm: boolean) => void;
  toggleGroupAdmin: () => void;
  handleModalGroup: () => void;
  onSubmitAddGroup: () => void;
  handleChangeStep: (next: AssignGroupFormStep) => void;
}

export type UserPermissionViewProps = IUserPermissionView;
