import { ICurrentGroups, IUserPortal } from 'interfaces/userinfo.interface';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import { AccessPermission, SiteStatusCodes } from '../enum';
import { findKeyIndAttribute } from './FindKeyIndexAttribute';
import { IGroupList } from '../../interfaces/groups.interface';

export function generateDataPermissions(
  userRecord: Partial<IUserPortal>,
  userAccessPermission: AccessPermission,
  userActiveID: number,
  groupLists: IGroupList[],
  currentUserGroupInfo: ICurrentGroups[],
  loggedInUserGroupInfo: ICurrentGroups[]
): IPermissionList[] {
  const dataPermissions: IPermissionList[] = [];
  const userAttributes = userRecord.attributes || [];
  if (userRecord && userRecord.account2position?.length) {
    // console.log('CREATE USER PERMISSIONS BY GROUP AND POSITIONS');
    const arrayToSort = [...userRecord.account2position];
    const sortedPositions = arrayToSort.sort((a, b) => a.n.localeCompare(b.n));
    for (let i = 0; i < sortedPositions.length; i++) {
      const record = sortedPositions[i];
      const currentGroupId = record.i;
      const filteredPositions = record.positions.filter(
        (value, index, self) => index === self.findIndex((t) => t.i === value.i)
      );
      let ttlRequest = 0;
      let ttlActive = 0;
      const ttlDeny = 0;
      const ttlSus = 0;
      for (const userAttribute of userAttributes) {
        if (typeof userAttribute === 'object') {
          const keyName = Object.keys(userAttribute)[0];
          const arrAttribute = userAttribute[keyName];
          if (Array.isArray(arrAttribute) && arrAttribute.length > 0) {
            // ?: User check for the status by group id
            const idxOfGrpIdAtt = findKeyIndAttribute(arrAttribute, String(record.i));
            if (idxOfGrpIdAtt > -1) {
              const grpAttribute = arrAttribute[idxOfGrpIdAtt];
              if (
                typeof grpAttribute === 'object' &&
                Object.prototype.hasOwnProperty.call(grpAttribute, String(record.i))
              ) {
                const groupObjAttr = grpAttribute[String(record.i)];
                const siteStatusObj = Array.isArray(groupObjAttr) && groupObjAttr[0];
                if (
                  typeof siteStatusObj === 'object' &&
                  Object.prototype.hasOwnProperty.call(siteStatusObj, 'S')
                ) {
                  const siteStatus = siteStatusObj.S;
                  if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
                  if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
                }
              }
            } else {
              if (arrAttribute.length === 1) {
                const siteStatusObj = Array.isArray(arrAttribute) && arrAttribute[0];
                if (
                  typeof siteStatusObj === 'object' &&
                  Object.prototype.hasOwnProperty.call(siteStatusObj, 'S')
                ) {
                  const siteStatus = siteStatusObj.S;
                  if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
                  if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
                }
              }
            }
          } else {
            // ?: Check for default Request Status
            const siteStatus = arrAttribute[0]['S'];
            const groupIdExist = findKeyIndAttribute(arrAttribute, String(record.i));
            if (groupIdExist > -1) {
              if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
              if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
            }
          }
        }
      }

      let isEditable = false;
      if (userAccessPermission === AccessPermission.SUPERADMIN) {
        isEditable = true;
      } else if (userAccessPermission === AccessPermission.GROUPADMIN) {
        const currentUserFilteredGroupInfo = loggedInUserGroupInfo.filter(
          (g) => g.i === currentGroupId
        );
        if (currentUserFilteredGroupInfo.length) {
          isEditable = currentUserFilteredGroupInfo[0].a === 1;
        } else {
          const groupInfo = groupLists.filter(
            (g) => g.pvGroupID === userActiveID || g.pvParentGroupID === userActiveID
          );
          if (groupInfo) {
            const mainGroup = groupInfo.filter((g) => g.pvGroupID === userActiveID);
            const subGroup = groupInfo.filter((g) => g.pvParentGroupID === userActiveID);
            if (mainGroup && mainGroup.findIndex((g) => g.pvGroupID === record.i) > -1) {
              isEditable = true;
            } else if (subGroup && subGroup.findIndex((g) => g.pvGroupID === record.i) > -1) {
              isEditable = true;
            }
          }
        }
      } else if (userAccessPermission === AccessPermission.SUBGROUPADMIN) {
        if (userActiveID === record.i) isEditable = true;
      }
      const payload: IPermissionList = {
        id: record.i,
        group: record.n,
        positions: `${filteredPositions.length} Positions`,
        access: `${ttlActive > 0 ? `${ttlActive} Active; ` : ''} ${
          ttlRequest > 0 ? `${ttlRequest} Requests; ` : ''
        } ${ttlDeny > 0 ? `${ttlDeny} Deny; ` : ''} ${ttlSus > 0 ? `${ttlSus} Suspend` : ''}`,
        isEditable,
      };
      dataPermissions.push(payload);
    }
  } else if (currentUserGroupInfo && currentUserGroupInfo.length) {
    // *: If a user has no position but has a group
    console.log('CREATE DEFAULT BY CURRENT USER GROUP INFO');
    let ttlRequest = 0;
    let ttlActive = 0;
    const ttlDeny = 0;
    const ttlSus = 0;
    for (const groupInfo of currentUserGroupInfo) {
      let isEditable = false;
      const currentGroupId = groupInfo.i;
      for (const attribute of userAttributes) {
        if (typeof attribute === 'object') {
          const appCode = Object.keys(attribute)[0];
          const arrAttribute = attribute[appCode];
          const siteStatus = arrAttribute[0]['S'];
          if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
          if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
        }
      }

      if (userAccessPermission === AccessPermission.SUPERADMIN) {
        isEditable = true;
      } else if (
        userAccessPermission === AccessPermission.GROUPADMIN ||
        userAccessPermission === AccessPermission.SUBGROUPADMIN
      ) {
        const currentUserFilteredGroupInfo = loggedInUserGroupInfo.filter(
          (g) => g.i === currentGroupId
        );
        if (currentUserFilteredGroupInfo.length) {
          isEditable = currentUserFilteredGroupInfo[0].a === 1;
        }
      }
      const payload: IPermissionList = {
        id: groupInfo.i,
        group: groupInfo.n,
        positions: `0 Positions`,
        access: `${ttlActive > 0 ? `${ttlActive} Active; ` : ''} ${
          ttlRequest > 0 ? `${ttlRequest} Requests; ` : ''
        } ${ttlDeny > 0 ? `${ttlDeny} Deny; ` : ''} ${ttlSus > 0 ? `${ttlSus} Suspend` : ''}`,
        isEditable: isEditable,
      };
      dataPermissions.push(payload);
    }
  } else {
    console.log('CREATE DEFAULT BY CURRENT ACTIVE GROUP ID');
    console.log('RETURN AN EMPTY ARRAY');
    // let ttlRequest = 0;
    // let ttlActive = 0;
    // const ttlDeny = 0;
    // const ttlSus = 0;

    // for (const userAttribute of userAttributes) {
    //   if (typeof userAttribute === 'object') {
    //     const keyName = Object.keys(userAttribute)[0];
    //     const arrAttribute = userAttribute[keyName];
    //     if (Array.isArray(arrAttribute) && arrAttribute.length > 0) {
    //       // ?: User check for the status by group id
    //       const idxOfGrpIdAtt = findKeyIndAttribute(arrAttribute, String(userActiveID));
    //       if (idxOfGrpIdAtt > -1) {
    //         const grpAttribute = arrAttribute[idxOfGrpIdAtt];
    //         if (
    //           typeof grpAttribute === 'object' &&
    //           Object.prototype.hasOwnProperty.call(grpAttribute, String(userActiveID))
    //         ) {
    //           const groupObjAttr = grpAttribute[String(userActiveID)];
    //           const siteStatusObj = Array.isArray(groupObjAttr) && groupObjAttr[0];
    //           if (
    //             typeof siteStatusObj === 'object' &&
    //             Object.prototype.hasOwnProperty.call(siteStatusObj, 'S')
    //           ) {
    //             const siteStatus = siteStatusObj.S;
    //             if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
    //             if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
    //           }
    //         }
    //       } else {
    //         if (arrAttribute.length === 1) {
    //           const siteStatusObj = Array.isArray(arrAttribute) && arrAttribute[0];
    //           if (
    //             typeof siteStatusObj === 'object' &&
    //             Object.prototype.hasOwnProperty.call(siteStatusObj, 'S')
    //           ) {
    //             const siteStatus = siteStatusObj.S;
    //             if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
    //             if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
    //           }
    //         }
    //       }
    //     } else {
    //       // ?: Check for default Request Status
    //       const siteStatus = arrAttribute[0]['S'];
    //       const groupIdExist = findKeyIndAttribute(arrAttribute, String(userActiveID));
    //       if (groupIdExist > -1) {
    //         if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
    //         if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
    //       } else {
    //         if (siteStatus === SiteStatusCodes.REQUEST) ttlRequest += 1;
    //         if (siteStatus === SiteStatusCodes.ACTIVE) ttlActive += 1;
    //       }
    //     }
    //   }
    // }
    // const grpIdx = groupLists.findIndex((g) => g.pvGroupID === userActiveID);
    // const payload: IPermissionList = {
    //   id: userActiveID,
    //   group: groupLists[grpIdx].pvGroupName,
    //   positions: `0 Positions`,
    //   access: `${ttlActive > 0 ? `${ttlActive} Active; ` : ''} ${
    //     ttlRequest > 0 ? `${ttlRequest} Requests; ` : ''
    //   } ${ttlDeny > 0 ? `${ttlDeny} Deny; ` : ''} ${ttlSus > 0 ? `${ttlSus} Suspend` : ''}`,
    //   isEditable: true,
    // };
    // dataPermissions.push(payload);
  }
  return dataPermissions;
}
