/* eslint-disable react/prop-types */
import React from 'react';
import { ISiteStatusList, SiteAction, UserPermissionViewProps } from './UserPermissionView.props';
import { Box, CircularProgress, LinearProgress, Paper, styled, useTheme } from '@mui/material';
import useStyles from './UserPermissionView.styles';
import { MainLayout } from 'components/layout';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Groups3SharpIcon from '@mui/icons-material/Groups3Sharp';
import { Button, Typo } from 'components/primitives';
import { HeaderButton, HeaderComponent, PaperFabButton } from '@zawarski/palmetto-ui-components';
import {
  InfoCard,
  ListItemView,
  OptionAccessForm,
  SectionContainer,
  SelectGroup,
  SelectPosition,
} from 'components/fragments';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form';
import { AssignGroupModal, ConfirmationModal } from 'components/modal';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import useMedia, { MOBILE_SIZE } from 'hooks/useMediaQuery';
import { AccessPermission, AssignGroupFormStep } from '../../common/enum';
import AddIcon from '@mui/icons-material/Add';
import { findIndex } from 'lodash';
import { UserGroupFormData } from 'hooks/useGroupForm';
import { StepProgress } from 'components/snippets';

const CustomFab = styled(PaperFabButton)({
  bottom: '80px !important',
});
const UserPermissionView: React.FC<UserPermissionViewProps> = ({
  groupForm,
  grantAccessForm,
  positionsForm,
  groupPosition,
  navigateBack,
  isPositionLoading,
  openEditPositions,
  hasNoPositions,
  fullName,
  isGroupAdmin,
  processingGroupAdmin,
  userPermission,
  singleGroupUser,
  step,
  stepHistory,
  ...props
}) => {
  const { isMobileDevice, windowDimensions } = useMedia();
  const isMobile =
    isMobileDevice || (windowDimensions?.width !== null && windowDimensions.width < MOBILE_SIZE);
  const theme = useTheme();
  const classes = useStyles(theme, isGroupAdmin);
  const stepIndex = findIndex(stepHistory, { step });
  const stepInfo = stepHistory[stepIndex];
  const lastStep = step === AssignGroupFormStep.POSITION;
  const hasGroupID = groupForm.watch('groupId') > 0;

  const PositionsList = (positions: { i: number; n: string }[]) => {
    if (positions.length) {
      return positions.map((value) => <InfoCard key={value.i} title={value.n} />);
    } else return null;
  };

  const RequestSiteList = (sites: ISiteStatusList[]) => {
    if (sites.length) {
      return sites.map((value) => {
        const BtnElement = (
          <>
            <Typo
              sx={{ cursor: 'pointer' }}
              fontWeight={700}
              color={theme.palette.error.main}
              textTransform='uppercase'
              onClick={() =>
                props.handleModalDenyGrantSiteRequest(value.code, value.title, SiteAction.DENY)
              }>
              Deny
            </Typo>
            <Typo
              sx={{ cursor: 'pointer' }}
              fontWeight={700}
              color={theme.palette.primary.main}
              textTransform='uppercase'
              onClick={() =>
                props.handleModalDenyGrantSiteRequest(value.code, value.title, SiteAction.GRANT)
              }>
              Grant
            </Typo>
          </>
        );
        return <ListItemView key={value.code} title={value.title} btnGroup={BtnElement} />;
      });
    }
  };

  const ActiveSiteList = (sites: ISiteStatusList[]) => {
    if (sites.length) {
      return sites.map((value) => {
        const BtnElement = (
          <>
            <Typo
              sx={{ cursor: 'pointer' }}
              fontWeight={700}
              color={theme.palette.error.main}
              textTransform='uppercase'
              onClick={() => props.handleModalRevokeAccess(value.code, value.title)}>
              {isMobile ? 'Revoke' : 'Revoke Access'}
            </Typo>
          </>
        );
        return (
          <ListItemView
            key={value.code}
            title={value.title}
            hasPermission={value?.hasPermission}
            btnGroup={BtnElement}
            handlePermission={() =>
              props.handleModalDenyGrantSiteRequest(value.code, value.title, SiteAction.UPDATE)
            }
          />
        );
      });
    }
  };

  const InActiveSiteList = (sites: ISiteStatusList[]) => {
    if (sites.length) {
      return sites.map((value) => {
        const BtnElement = (
          <Typo
            sx={{ cursor: 'pointer' }}
            fontWeight={700}
            color={theme.palette.primary.main}
            textTransform='uppercase'
            onClick={() =>
              props.handleModalDenyGrantSiteRequest(value.code, value.title, SiteAction.UPDATE)
            }>
            Grant
          </Typo>
        );
        return <ListItemView key={value.code} title={value.title} btnGroup={BtnElement} />;
      });
    }
  };

  const TitleContent = () => {
    return (
      <Box sx={classes.titleContainer}>
        <Box sx={classes.titleWrapper}>
          <Typo sx={classes.titleBig}>Manage positions and sites</Typo>
          <Typo sx={classes.titleSmall}>
            {isPositionLoading
              ? 'Loading'
              : groupPosition && Object.prototype.hasOwnProperty.call(groupPosition, 'n')
              ? `${fullName} - ${groupPosition.n}`
              : `${fullName}`}
          </Typo>
        </Box>
        <Box
          onClick={!processingGroupAdmin ? () => props.toggleGroupAdmin() : undefined}
          sx={classes.btnGrpAdminActive}
          hidden={isPositionLoading}>
          {processingGroupAdmin ? (
            <CircularProgress sx={{ color: '#FFF' }} size={18} />
          ) : (
            <Groups3SharpIcon />
          )}
          Group Admin
        </Box>
      </Box>
    );
  };

  const handleSubmitAddGroup: SubmitHandler<UserGroupFormData> = () => {
    props.onSubmitAddGroup();
  };

  const onNextStep: SubmitHandler<UserGroupFormData> = () => {
    if (lastStep) props.onSubmitAddGroup();
    else {
      const index = findIndex(stepHistory, { step: stepInfo?.step });
      const nextStep = stepHistory[index + 1];
      if (nextStep) {
        props.handleChangeStep(nextStep.step as AssignGroupFormStep);
      }
    }
  };

  const onBackStep = () => {
    const index = findIndex(stepHistory, { step: stepInfo?.step });
    const backStep = stepHistory[index - 1];
    props.handleChangeStep(backStep.step as AssignGroupFormStep);
  };

  return (
    <MainLayout>
      <div className='layout vertical full-height'>
        <HeaderComponent
          icon={
            <IconButton onClick={navigateBack}>
              <ArrowBackIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          title={<TitleContent />}
          suffix={
            isPositionLoading ? null : (
              <HeaderButton style={{ fontSize: 16 }} onClick={props.handleOpenModalRevokeGroup}>
                REVOKE GROUP
              </HeaderButton>
            )
          }
        />
        <Box sx={classes.container}>
          <Box sx={classes.content}>
            <SectionContainer
              title='PALMETTO POSITIONS'
              hideButton={false}
              noChildren={hasNoPositions}
              btnTitle={`${hasNoPositions ? 'Add' : 'Edit'} position`}
              btnIcon={<ManageAccountsIcon fontSize='small' />}
              onClick={() => props.handleModalEditPositions()}>
              {isPositionLoading ? (
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              ) : null}
              {!isPositionLoading && !hasNoPositions ? (
                <Box sx={classes.card}>
                  {groupPosition?.positions.length ? PositionsList(groupPosition.positions) : null}
                </Box>
              ) : null}
            </SectionContainer>
            {props.siteRequests.length ? (
              <SectionContainer title='SITE REQUESTS' noChildren={props.siteRequests.length === 0}>
                <Box sx={classes.card}>
                  {props.siteRequests.length ? RequestSiteList(props.siteRequests) : null}
                </Box>
              </SectionContainer>
            ) : null}
            <SectionContainer title='ACTIVE SITES'>
              <Box sx={classes.card}>
                {props.activeSites.length ? ActiveSiteList(props.activeSites) : null}
              </Box>
            </SectionContainer>
            {props.inActiveSites.length ? (
              <SectionContainer title='INACTIVE SITES'>
                <Box sx={classes.card}>
                  {props.inActiveSites.length ? InActiveSiteList(props.inActiveSites) : null}
                </Box>
              </SectionContainer>
            ) : null}
            <ConfirmationModal
              width={isMobile ? '95%' : '320px !important'}
              open={props.openGrantSiteRequest}
              loading={props.inProcessAttribute}
              title='Grant Access'
              titleBtnLeft='CANCEL'
              titleBtnRight='GRANT'
              onConfirm={(confirm: boolean) =>
                props.onSubmitDenyGrantAccess(confirm, SiteAction.GRANT)
              }>
              <Typo color='common.black'>{props.messageModal}</Typo>
            </ConfirmationModal>
            <ConfirmationModal
              width={isMobile ? '95%' : '320px !important'}
              open={props.openDenySiteRequest}
              loading={props.inProcessAttribute}
              title='Deny Access'
              titleBtnLeft='CANCEL'
              titleBtnRight='DENY'
              onConfirm={(confirm: boolean) =>
                props.onSubmitDenyGrantAccess(confirm, SiteAction.DENY)
              }>
              <Typo color='common.black'>{props.messageModal}</Typo>
            </ConfirmationModal>
            <ConfirmationModal
              width={isMobile ? '95%' : '320px !important'}
              open={props.openRevokeAccess}
              loading={props.inProcessAttribute}
              title='Revoke Access'
              titleBtnLeft='CANCEL'
              titleBtnRight='REVOKE'
              onConfirm={props.confirmRevokeAccess}>
              <Typo color='common.black'>{props.messageModal}</Typo>
            </ConfirmationModal>
            <ConfirmationModal
              width={isMobile ? '95%' : '320px !important'}
              open={props.openRevokeGroup}
              loading={props.isRevokeGroupProcessing}
              title='Revoke Group'
              titleBtnLeft='CANCEL'
              titleBtnRight='REVOKE'
              onConfirm={props.handleModalRevokeGroup}>
              <Typo color='common.black'>
                You are about to revoke the selected group for this user. Do you want to continue?
              </Typo>
            </ConfirmationModal>
            <AssignGroupModal
              open={props.openGrantSiteAccess}
              title='Grant Access'
              handleModal={(confirm: boolean) => props.onSubmitOptionsGrantAccess(confirm)}>
              <Box sx={{ height: 'auto', mt: '50px' }}>
                <Stack direction='column' spacing={1}>
                  <Controller
                    control={grantAccessForm.control}
                    name={'permissionType'}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        value={value}
                        onChange={onChange}>
                        <FormProvider {...grantAccessForm}>
                          <OptionAccessForm />
                        </FormProvider>
                      </RadioGroup>
                    )}
                  />
                  <Button
                    fullWidth
                    progress={props.inProcessAttribute}
                    onClick={() => props.onSubmitOptionsGrantAccess(true)}>
                    Submit
                  </Button>
                </Stack>
              </Box>
            </AssignGroupModal>
            <AssignGroupModal
              title='Positions'
              maxWidth={'450px'}
              handleModal={() => props.handleModalEditPositions()}
              open={openEditPositions}>
              <Box sx={classes.positionBox}>
                <FormProvider {...positionsForm}>
                  <SelectPosition rootName={'positions'} sx={{ width: 'calc(100% - 120px)' }} />
                </FormProvider>
                <Box sx={classes.btnWrapper}>
                  <Button
                    fullWidth
                    sx={{ mt: 2 }}
                    progress={props.inProcessing}
                    onClick={positionsForm.handleSubmit(props.onSubmit)}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </AssignGroupModal>
            <AssignGroupModal open={props.openAssignGroup} handleModal={props.handleModalGroup}>
              <Box sx={classes.modalContainer}>
                <FormProvider {...groupForm}>
                  <SelectGroup
                    hidden={step !== AssignGroupFormStep.GROUP}
                    groupLists={props.groupLists}
                  />
                  <SelectPosition
                    sx={{ mb: '75px' }}
                    hidden={step !== AssignGroupFormStep.POSITION}
                    rootName={'positions'}
                  />
                </FormProvider>
                <Paper elevation={0} variant='outlined' sx={classes.stepContainer}>
                  {props.hasNoPositionsGroupForm ? null : <StepProgress stepIndex={stepIndex} />}
                  <Box sx={classes.btnGroup}>
                    {props.hasNoPositionsGroupForm ? (
                      <Button
                        sx={{ minWidth: '150px' }}
                        progress={props.isProcessing || props.fetchingUsers}
                        disabled={isPositionLoading || !hasGroupID}
                        onClick={groupForm.handleSubmit(handleSubmitAddGroup)}>
                        Submit
                      </Button>
                    ) : (
                      <>
                        <Button
                          alternate
                          sx={{ minWidth: '150px' }}
                          disabled={!lastStep}
                          onClick={() => onBackStep()}>
                          Back
                        </Button>
                        <Button
                          sx={{ minWidth: '150px' }}
                          progress={props.isProcessing || props.fetchingUsers}
                          disabled={isPositionLoading || !hasGroupID}
                          onClick={groupForm.handleSubmit(onNextStep)}>
                          {lastStep ? 'Submit' : 'Next'}
                        </Button>
                      </>
                    )}
                  </Box>
                </Paper>
              </Box>
            </AssignGroupModal>
          </Box>
        </Box>
        <CustomFab
          hidden={
            !(
              singleGroupUser &&
              (userPermission === AccessPermission.SUPERADMIN ||
                userPermission === AccessPermission.GROUPADMIN)
            )
          }
          onClick={props.handleModalGroup}>
          <AddIcon className='icon' />
        </CustomFab>
      </div>
    </MainLayout>
  );
};

export default UserPermissionView;
