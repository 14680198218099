import React from 'react';
import { Box, Divider, Paper, useTheme } from '@mui/material';
import { MainLayout } from 'components/layout';
import { PreLoaderSnippet } from 'components/snippets';
import ErrorIcon from '@mui/icons-material/Error';
import { VerifyAccountProps } from './VerifyNewAccount.props';
import useStyles from './VerifyNewAccount.styles';
import { Typo } from 'components/primitives';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const VerifyNewAccountView: React.FC<VerifyAccountProps> = ({ isLoading, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <MainLayout>
      {isLoading ? (
        <Box sx={classes.root}>
          <PreLoaderSnippet visible={isLoading} />
        </Box>
      ) : props.hasError ? (
        <Box sx={classes.root}>
          <Paper variant='outlined' sx={classes.container} style={{ maxHeight: '300px' }}>
            <Box sx={classes.lastStepContainer}>
              <Box sx={classes.iconContainer}>
                <ErrorIcon
                  sx={{ color: theme.palette.error.main, height: '56px', width: '56px' }}
                />
              </Box>
            </Box>
            <Typo fontSize={20} fontWeight={500}>
              Account verification failed
            </Typo>
            <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {props.infoMsg}
            </Typo>
            <Divider variant='middle' sx={{ width: '100%' }} />
            <Typo
              fontSize={16}
              sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={() => props.handleBack()}>
              Return to login
            </Typo>
          </Paper>
        </Box>
      ) : (
        <Box sx={classes.root}>
          <Paper variant='outlined' sx={classes.container} style={{ maxHeight: '300px' }}>
            <Box sx={classes.lastStepContainer}>
              <Box sx={classes.iconContainer}>
                <CheckCircleIcon sx={{ color: 'green', height: '56px', width: '56px' }} />
              </Box>
              <Typo fontSize={20} fontWeight={500}>
                Account request verified
              </Typo>
              <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                {props.infoMsg}
              </Typo>
              <Divider variant='middle' sx={{ width: '100%' }} />
              <Typo
                fontSize={16}
                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => props.handleBack()}>
                Return to login
              </Typo>
            </Box>
          </Paper>
        </Box>
      )}
    </MainLayout>
  );
};

export default VerifyNewAccountView;
