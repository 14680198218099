import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';

async function HandleDownloadExcelFile(fileName: string, url: string, token: string) {
  try {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: {
        // eslint-disable-next-line camelcase
        access_token: token,
      },
    };
    const DocumentServiceResponse = await axios.get(url, config);

    const AnchorTag = document.createElement('a');
    // Define the url for the document
    AnchorTag.href = window.URL.createObjectURL(new Blob([DocumentServiceResponse.data]));

    // Defining the filename
    const ExcelFileName = `${fileName}-${moment().local().format('MMDDYYHHmm')}.xlsx`;
    AnchorTag.setAttribute('download', ExcelFileName);

    // Attach link to the body of the body of our public html file
    document.body.appendChild(AnchorTag);

    // Click the link
    AnchorTag.click();

    // Remove the element
    AnchorTag.remove();
  } catch (error) {
    console.log(error);
  }
}

export default HandleDownloadExcelFile;
