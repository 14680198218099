import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISetting,
  IChangeActiveGroup,
  IUserPortal,
  IUserUpdatePayload,
} from 'interfaces/userinfo.interface';
import { IUserAccount, IChangeUserName } from 'interfaces/userinfo.interface';
import { ISendContactMsg } from 'interfaces/notification.interface';
import apiFetch from 'services/apiFetch';
import {
  changeActiveGroup,
  getOneUser,
  updateUserInfo,
  updateUserName,
} from 'services/userEndpoint';
import { sendSupportMsg } from 'services/notificationsEndpoint';
import { AppDispatch, RootState } from 'store/index';

export const fetchCurrentUserInfo = createAsyncThunk(
  'fetch-user-info',
  async (id: number, thunkAPI) => {
    try {
      // const data = userInfo;
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = getOneUser(id);
      return await apiFetch<Partial<IUserPortal>>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('fetchCurrentUserInfo catch:', error);
      throw Error(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  'update-user',
  async (payload: Partial<IUserUpdatePayload>, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = updateUserInfo(payload);
      return await apiFetch<IUserAccount>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('update-user catch:', error);
      throw Error(message);
    }
  }
);

export const postSupportMsg = createAsyncThunk(
  'send-support-msg',
  async (data: ISendContactMsg, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = sendSupportMsg(data);
      return await apiFetch<string>(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('send-support-msg catch:', error);
      throw Error(message);
    }
  }
);

export const changePortalUsername = createAsyncThunk(
  'change-username',
  async (data: IChangeUserName, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = updateUserName(data);
      return await apiFetch<IUserAccount>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('change-username catch:', error);
      throw Error(message);
    }
  }
);

export const updateUserActiveGroup = createAsyncThunk(
  'update-active-group',
  async (data: IChangeActiveGroup, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = changeActiveGroup(data);
      return await apiFetch<ISetting>(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('update-active-group catch:', error);
      throw Error(message);
    }
  }
);
