import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultMenuState, IMenuState, TabName } from './menuState';

const initialState: IMenuState = DefaultMenuState;

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    changeMenu: (state, action: PayloadAction<{ BtnAction: boolean }>) => {
      state.openDrawer = action.payload.BtnAction;
    },
    changeTab: (state, action: PayloadAction<{ tabName: TabName }>) => {
      state.tabName = action.payload.tabName;
    },
  },
});

export const { changeMenu, changeTab } = menuSlice.actions;

export default menuSlice.reducer;
