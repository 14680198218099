import type { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  form: {
    container: {
      padding: '12px',
    },
  },
  contacts: {
    container: {
      display: 'flex',
      padding: '16px',
      alignItems: 'center',
      gap: '4px',
    },
    wrapper: {
      display: 'flex',
      width: '100%',
      padding: '12px',
      alignItems: 'flex-start',
      alignContent: 'space-between',
      gap: '4px',
      alignSelf: 'stretch',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.grey[100]}`,
      background: '#F0F0F0',
      flexWrap: 'wrap',
    },
  },
  emptyContainer: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '32px',
      padding: '64px',
      width: '100%',
    },
    text: {
      color: theme.palette.grey[400],
      fontWeight: 600,
      fontSize: '14px',
    },
  },
});

export default useStyles;
