import { useForm } from 'react-hook-form';
import { ProgramCodes } from 'common/enum';
import { Nullable } from 'common/utils';
import { Attributes } from 'interfaces/attribute.interface';

export type SpecialOptions = {
  title: string;
  value: string;
};

export interface GrantAccessFormData {
  programCode: Nullable<ProgramCodes | string>;
  userId: number;
  groupId: number;
  permissionType: Nullable<string>;
  attributes: Attributes[];
  selections: SpecialOptions[];
  isUpdate: boolean;
}

export const defaultSelections = [
  {
    title: 'Administrator',
    value: 'A',
  },
  {
    title: 'User',
    value: 'U',
  },
];

export const defaultGrantAccessForm: GrantAccessFormData = {
  programCode: null,
  userId: 0,
  groupId: 0,
  permissionType: null,
  attributes: [],
  selections: defaultSelections,
  isUpdate: false,
};

function useGrantAccess() {
  return useForm<GrantAccessFormData>({
    defaultValues: defaultGrantAccessForm,
  });
}

export default useGrantAccess;
