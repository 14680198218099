import { IEndpoint } from 'interfaces/IEndPoint';
import { AuthProtect } from '../common/enum';
import { ContactFilterParams, IGroupWithContacts } from 'interfaces/groups.interface';

function setContactParams(filter: Partial<ContactFilterParams>): Partial<ContactFilterParams> {
  const params: ContactFilterParams = { page: 0 };
  if (filter.page) {
    params.page = filter.page;
  }
  if (filter.search) {
    params['filters[search]'] = filter.search;
  }
  if (filter.activeGroup) {
    params.activeGroup = filter.activeGroup;
  }
  return params;
}

export const getGroupById = (id: number): IEndpoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `/manage/groups/${id}`,
});

export const getContacts = (filter: ContactFilterParams): IEndpoint => {
  const params = setContactParams(filter);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/manage/contacts',
    params,
  };
};

export const saveGroupInfo = (data: Partial<IGroupWithContacts>): IEndpoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'POST',
  url: '/manage/group',
  data,
});

export const deleteGroup = (id: number): IEndpoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/manage/group/${id}`,
});
