import { Nullable } from 'common/utils/Nullable';

export interface ITokenState {
  authorization: string | null | undefined;
  jwToken: Nullable<string>;
  ttl: number | undefined;
  createdAt: number | undefined;
  user: string | undefined;
  email: string | undefined;
}

export const UserAuthEmptyState: ITokenState = {
  authorization: null,
  jwToken: null,
  ttl: 0,
  createdAt: 0,
  user: undefined,
  email: undefined,
};
