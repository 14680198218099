import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppTheme from './theme';
import JSXRoutes from './routes';
import { RefreshTokenTimer } from 'components/fragments';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AppTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <RefreshTokenTimer />
          <RouterProvider router={JSXRoutes} />
          <ToastContainer
            limit={5}
            position='top-right'
            autoClose={3000}
            closeOnClick={true}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            draggable={false}
            icon={false}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
