import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { postSupportMsg } from 'store/slices/currentUser/currentUserThunk';
import { selectUserAccount } from 'store/slices/currentUser/currentUser.selector';
import { selectActiveGroupId } from 'store/slices/cognito/cognito.selector';
import useContactForm, { ContactFormData } from './hooks/useContactForm';
import ContactSupportView from './ContactSupport.view';
import { ToastMessage } from 'components/primitives';
import { ContactSupportProps } from './ContactSupport.props';
import { ISendContactMsg } from 'interfaces/notification.interface';
import { ToastStatus } from 'common/enum';

const ContactSupport = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUserAccount);
  const userActiveGroupId = useAppSelector(selectActiveGroupId);
  const form = useContactForm();
  const [inProcess, setInProcess] = useState(false);

  useEffect(() => {
    if (currentUser && userActiveGroupId) {
      const currentGroup =
        currentUser &&
        currentUser.currentGroups &&
        currentUser?.currentGroups.find((v) => v.i === userActiveGroupId);

      const positions =
        (currentUser &&
          currentUser?.currentPositions &&
          currentUser?.currentPositions.map((v) => v.n)) ||
        [];
      const formValue: ContactFormData = {
        name: currentUser.pvPersonGivenName + ' ' + currentUser.pvPersonSurName,
        email: currentUser.email || '',
        group: (currentGroup && currentGroup.n) || '',
        positions: positions,
        message: '',
        subject: '',
      };
      form.reset(formValue);
    }
  }, [currentUser, userActiveGroupId, form]);

  const onSubmit: SubmitHandler<ContactFormData> = async (data) => {
    try {
      setInProcess(true);
      const payload: ISendContactMsg = {
        email: data.email,
        name: data.name,
        subject: data.subject,
        group: data.group,
        positions: data.positions,
        message: data.message.replace(/\r\n|\r|\n/g, '<br />'),
      };

      await dispatch(postSupportMsg(payload))
        .unwrap()
        .then(() => {
          toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Process' />);
          setTimeout(() => navigate('/dashboard'), 5000);
        })
        .catch(() => {
          toast.error(
            <ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />
          );
        })
        .finally(() => {
          setInProcess(false);
        });
    } catch (error) {
      console.log(error);
      setInProcess(false);
    }
  };

  const combineProps: ContactSupportProps = {
    inProcess,
    form,
    onSubmit,
  };
  return <ContactSupportView {...combineProps} />;
};

export default ContactSupport;
