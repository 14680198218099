import { Nullable } from 'utils/Nullable';
import { IGroupList, IGroupWithContacts } from 'interfaces/groups.interface';

export interface ICurrentControlPanelState {
  // Add properties here
  loading: boolean;
  processing: boolean;
  success: boolean;
  error: Nullable<string>;
  selectedGroup: Nullable<IGroupList>;
  selectedGroupWithContacts: Nullable<IGroupWithContacts>;
}

export const CurrentControlPanelEmptyState: ICurrentControlPanelState = {
  // Add properties here
  loading: false,
  processing: false,
  success: false,
  error: null,
  selectedGroup: null,
  selectedGroupWithContacts: null,
};
