import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { CheckButton, Modal, TextField, Typo } from 'components/primitives';
import { IconButton, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import useFetchContact from '../../hook/useFetchContact';
import useStyles from './ModalContacts.styles';
import { ActionButtons } from 'components/fragments';
import { IAccount2Group } from 'interfaces/userinfo.interface';
import { IGroupContact } from 'interfaces/groups.interface';

type ModalContactsProps = {
  title: string;
  isOpen: boolean;
  activeGroupID: number | null;
  existingContacts: Partial<IGroupContact>[];
  onClose: () => void;
  callBack: (contacts: IAccount2Group[]) => void;
  maxWidth?: string;
};

const ModalContacts: React.FC<ModalContactsProps> = ({
  activeGroupID,
  title,
  isOpen,
  existingContacts,
  ...props
}) => {
  const {
    loading,
    setPage,
    contacts,
    setContacts,
    search,
    setSearch,
    onSelectedContact,
    selectedCount,
    setSelectedCount,
  } = useFetchContact(isOpen, activeGroupID, existingContacts);

  const theme = useTheme();
  const classes = useStyles(theme);

  const { ref, inView } = useInView();

  const handleScroll = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, [setPage]);

  useEffect(() => {
    if (isOpen && inView) {
      handleScroll();
    }
  }, [isOpen, inView, handleScroll]);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { checked } = e.target;
    onSelectedContact(checked, id);
  };

  const handleClose = () => {
    setContacts([]);
    setSelectedCount(0);
    setPage(0);
    props.onClose();
  };

  const onSave = () => {
    const selectedContacts = contacts.filter((contact) => contact.isChecked);
    props.callBack(selectedContacts);
    handleClose();
  };

  return (
    <Modal
      key={isOpen ? 'open' : 'closed'}
      sx={{ overflow: 'hidden' }}
      open={isOpen}
      onClose={handleClose}>
      <Box
        sx={{
          maxWidth: props.maxWidth ? props.maxWidth : 'unset',
          minHeight: '400px',
          width: '100%',
          minWidth: '345px',
        }}>
        <Box sx={classes.header}>
          <Box sx={classes.headerTitle.container}>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
            <Box sx={classes.headerTitle.group}>
              <Typo variant='h4' sx={{ ml: theme.spacing(1) }}>
                {title}
              </Typo>
              <Typo sx={{ fontSize: 12, ml: theme.spacing(1) }}>
                {selectedCount > 0 ? `${selectedCount} selected` : ' '}
              </Typo>
            </Box>
          </Box>
        </Box>
        <TextField
          sx={{ mt: '56px' }}
          label='Search accounts'
          placeholder='Enter search'
          value={search}
          onChange={onChangeSearch}
          fullWidth
        />
        <Box
          style={{ maxHeight: '400px', height: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
          {contacts.length
            ? contacts.map((contact, index) => {
                const fullName = `${contact.pvFirstName ? contact.pvFirstName : ''} ${
                  contact.pvLastName ? contact.pvLastName : ''
                }`;
                return (
                  <Box key={index} sx={{ py: '2px' }}>
                    <CheckButton
                      value={Boolean(contact.isChecked)}
                      label={fullName}
                      subTitle={contact.pvUserName}
                      onChange={(e) => onChecked(e, contact.pvAccountID)}
                    />
                  </Box>
                );
              })
            : null}
          <div ref={ref} />
        </Box>
        {loading && <LinearProgress />}
        <Box sx={{ mt: '80px' }} />
        <ActionButtons
          readOnly={false}
          isSaving={false}
          withSave
          withCancel
          isModal
          cancel={handleClose}
          save={onSave}
        />
      </Box>
    </Modal>
  );
};

export default ModalContacts;
