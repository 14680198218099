import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => {
  return {
    footer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      left: 0,
      bottom: 0,
      padding: `0 ${theme.spacing(3)}`,
      width: '100%',
      height: '58px',
      backgroundColor: theme.palette.common.white,
      color: '0000',
    },
    modalTitle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      gap: '16px',
    },
    inputWrapper: {
      // margin: '24px 0',
      width: '100%',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    linkWrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    linkText: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontSize: '14px',
      fontWeight: 500,
    },
    resetInfoContainer: {
      display: 'flex',
      border: '2px solid #59981A',
      backgroundColor: '#D2FBA4',
      borderRadius: 1,
      maxWidth: '325px',
      padding: theme.spacing(1),
      color: '#59981A',
    },
    errorStatusContainer: {
      display: 'flex',
      border: `2px solid ${theme.palette.error.main}`,
      backgroundColor: '#FDDDD7',
      borderRadius: 1,
      maxWidth: '325px',
      padding: theme.spacing(1),
      color: theme.palette.error.main,
    },
  };
};

const Img = styled(`img`)`
  width: 96px;
  height: 100%;
  object-fit: contain;
`;

export { Img, useStyles };
