import { createTheme } from '@mui/material/styles';
import MuiButtonStyle from './component/MuiButton';
import MuiTextField from './component/MuiTextField';
import MuiTabs from './component/MuiTabs';
import typography from './typography';
import customPallet from './pallet';

const theme = createTheme({
  palette: customPallet,
  typography,
  components: {
    MuiButton: MuiButtonStyle(customPallet),
    MuiTextField: MuiTextField(customPallet),
    MuiTabs: MuiTabs(customPallet),
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          display: 'none',
        },
      },
    },
  },
});

export default theme;
