import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeMenu } from 'store/slices/menu/menuSlice';
import { selectUserAccount } from 'store/slices/currentUser/currentUser.selector';
import { HeaderComponent, HeaderButton } from '@zawarski/palmetto-ui-components';
import MenuIcon from '@mui/icons-material/Menu';
import { MainLayout } from 'components/layout';
import { DrawerMenu, Typo, TextField } from 'components/primitives';
import { GroupCard } from 'components/snippets';
import { IconButton, Box, Grid, useTheme } from '@mui/material';
import { MyAccountViewProps } from './MyAccountView.props';
import { PhoneNumberFormat } from 'common/utils';

const MyAccountView: React.FC<MyAccountViewProps> = ({ navigateTo }) => {
  const dispatch = useAppDispatch();
  const userRecord = useAppSelector(selectUserAccount);
  const userGroupPositions =
    userRecord && userRecord?.account2position && userRecord.account2position.length
      ? userRecord.account2position
      : [];
  const theme = useTheme();

  const handleMenu = () => {
    dispatch(changeMenu({ BtnAction: true }));
  };

  return (
    <MainLayout toolbar={<DrawerMenu />}>
      <div className='layout vertical full-height'>
        <HeaderComponent
          icon={
            <IconButton onClick={handleMenu}>
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          title={<Typo variant='h4'>My Account</Typo>}
          suffix={
            <HeaderButton style={{ fontSize: 16 }} onClick={navigateTo}>
              EDIT
            </HeaderButton>
          }
        />
        <div className='form-container container-overflow-y'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='First name'
                inputProps={{ readOnly: true }}
                value={userRecord?.pvPersonGivenName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Last name'
                inputProps={{ readOnly: true }}
                value={userRecord?.pvPersonSurName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                inputProps={{ readOnly: true }}
                value={userRecord?.username}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Email Address'
                inputProps={{ readOnly: true }}
                value={userRecord?.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Work Phone'
                inputProps={{ readOnly: true }}
                value={
                  userRecord?.pvOfficePhone ? PhoneNumberFormat(userRecord?.pvOfficePhone) : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Cell Phone'
                inputProps={{ readOnly: true }}
                value={
                  userRecord?.pvMobilePhone ? PhoneNumberFormat(userRecord?.pvMobilePhone) : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Organization'
                inputProps={{ readOnly: true }}
                value={userRecord?.pvOrganizationUnitName}
              />
            </Grid>
            <Grid item xs={12}>
              <Typo fontWeight={600} color={theme.palette.primary.dark}>
                GROUPS
              </Typo>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}>
            {userGroupPositions && userGroupPositions.length
              ? userGroupPositions.map((value) => <GroupCard key={value.i} value={value} />)
              : null}
          </Box>
        </div>
      </div>
    </MainLayout>
  );
};

export default MyAccountView;
