import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import useStyles from './ContactCard.styles';
import { Typo } from 'components/primitives';
import CloseIcon from '@mui/icons-material/Close';

type ContactCardProps = {
  leftIcon: React.ReactNode;
  headerTitle: string;
  subTitle?: string | null;
  readOnly?: boolean;
  onRemove?: () => void;
  onClick?: () => void;
};

const ContactCard: React.FC<ContactCardProps> = ({
  leftIcon,
  headerTitle,
  readOnly = false,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box sx={classes.container}>
      {leftIcon}
      <Box sx={classes.frame} onClick={props.onClick}>
        <Typo sx={classes.title.header}>{headerTitle}</Typo>
        <Box sx={classes.title.rowSubTitle}>
          {!!props.subTitle && <Typo style={{ fontSize: 12 }}>{props.subTitle}</Typo>}
        </Box>
      </Box>
      {!readOnly && (
        <CloseIcon
          fontSize='small'
          sx={classes.icon}
          style={{ color: theme.palette.grey[300] }}
          onClick={props.onRemove}
        />
      )}
    </Box>
  );
};

export default ContactCard;
