import { IEndpoint } from 'interfaces/IEndPoint';
import {
  IChangeActiveGroup,
  IChangeUserName,
  IUserCreatePayload,
  IUserUpdatePayload,
} from 'interfaces/userinfo.interface';
import AuthProtect from 'common/enum/AuthProtect';

export const getOneUser = (id: number): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/user/${id}`,
  };
};

export const createUser = (data: IUserCreatePayload): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/user',
    data,
  };
};

export const updateUserInfo = (data: Partial<IUserUpdatePayload>): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/user',
    data,
  };
};

export const deleteUser = (id: number): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: `/user/${id}`,
  };
};

export const updateUserName = (data: IChangeUserName): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'PUT',
    url: '/changeusername',
    data,
  };
};

export const checkMultiUser = (id: number): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `/multiuser/${id}`,
  };
};

export const changeActiveGroup = (data: IChangeActiveGroup): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/changeActiveGroup',
    data,
  };
};

export const getUsernames = (data: { email: string }): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'PUT',
    url: '/get_usernames',
    data,
  };
};

export const requestAccess = (data: IUserCreatePayload): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: '/request-access',
    data,
  };
};

export const verifyAccount = (data: { accessToken: string; userId: number }): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: 'verify-account?uid=' + data.userId + '&access_token=' + data.accessToken,
  };
};

export const getManageColumns = (data: { accessToken: string }): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: 'users/manage-columns?access_token=' + data.accessToken,
  };
};

export const verifyUserName = (username: string): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `/username/${username}`,
  };
};

export const updatePasswordEncrypt = (data: { userID: number; password: string }): IEndpoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'PUT',
  url: '/password_encrypt',
  data,
});
