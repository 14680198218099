import { Theme } from '@mui/material';

const useStyles = (theme: Theme, bgcolor?: string) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // bgcolor: theme.palette.common.white,
    gap: '8px',
    width: '100%',
    borderRadius: '4px',
    paddingBottom: '16px',
    // padding: bgcolor ? '24px' : '12px',
    bgcolor: bgcolor ? bgcolor : theme.palette.common.white,
    boxShadow: bgcolor
      ? '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)'
      : 'unset',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: '8px 0',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: bgcolor ? bgcolor : theme.palette.grey[50],
    width: '100%',
    height: 'auto',
    gap: bgcolor ? '8px' : '4px',
  },
  title: {
    color: bgcolor ? theme.palette.primary.dark : theme.palette.grey[500],
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginLeft: '16px',
  },
  editBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    gap: '8px',
    borderRadius: '4px',
    fontSize: '12px',
    bgcolor: '#E2EAFF',
    color: theme.palette.primary.dark,
    padding: '4px 8px',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      bgcolor: theme.palette.primary.dark,
      color: '#E2EAFF',
    },
  },
  toggle: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
      pr: '16px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.palette.primary.dark,
    },
  },
});

export default useStyles;
