// noinspection ExceptionCaughtLocallyJS,JSUnresolvedReference,JSUnusedGlobalSymbols

import { createAsyncThunk } from '@reduxjs/toolkit';
import * as AWS from 'aws-sdk';
import axios from 'axios';
import { AES } from 'crypto-js';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
} from 'amazon-cognito-identity-js';
import { AppDispatch, RootState } from 'store';
import { DefineUserAttributesData, getAccountFromIdToken } from 'common/utils';
import { IUserAccount, IUserPortal } from 'interfaces/userinfo.interface';
import { IidTokenSession } from 'interfaces/session.interface';
import { IAuthToken } from 'interfaces/auth.interface';
import apiFetch from 'services/apiFetch';
import {
  checkMultiUser,
  getUsernames,
  updatePasswordEncrypt,
  verifyUserName,
} from 'services/userEndpoint';
import { requestUsername, requestPasswordReset } from 'services/notificationsEndpoint';
import { validateResetToken, resetPassword } from 'services/authEndpoint';

AWS.config.region = 'us-east-1';
const USER_POOL_ID = 'us-east-1_3f1efBpoo';
const USER_POOL_CLIENT_ID = '4i0bpro8bq9cusqkigt1j4gb9f';
const USER_POOL: CognitoUserPool = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: USER_POOL_CLIENT_ID,
  AdvancedSecurityDataCollectionFlag: true,
});
let COGNITO_USER: CognitoUser | null;

function SetNewCognitoUser(payload: {
  username: string;
  password: string;
}): Promise<CognitoUser | null> {
  return new Promise((res, rej) => {
    try {
      const currentUser = new CognitoUser({
        Username: payload.username,
        Pool: USER_POOL,
      });
      // !: NEED THIS TO TRIGGER USER MIGRATION
      currentUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      currentUser.authenticateUser(
        new AuthenticationDetails({
          Username: payload.username,
          Password: payload.password,
          // ClientMetadata: {
          //   ActiveGroupID: payload.activeId ? payload.activeId.toString() : '0',
          // },
        }),
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSuccess(_session) {
            COGNITO_USER = currentUser;
            console.log('onSuccess currentUser >>>', currentUser);
            return res(currentUser);
          },
          onFailure(error) {
            //	* "code": "NotAuthorizedException"
            //	* "name": "NotAuthorizedException"
            //	* "message": "Incorrect username or password."
            //	* "code": "UserNotFoundException"
            //  * "name": "UserNotFoundException"
            console.log('onFailure error >>>', error);
            let message = 'Unauthorized Account';
            const listPropertyNames = Object.keys(error);
            // eslint-disable-next-line no-restricted-syntax
            for (const key in listPropertyNames) {
              if (key === 'NotAuthorizedException') {
                message = 'Incorrect username or password.';
              }
            }
            return rej(message);
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  });
}

function checkActiveSession(payload: {
  username: string;
  password: string;
  activeId?: string;
}): Promise<CognitoUser | null> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (res, rej) => {
    try {
      let currentUser = USER_POOL && USER_POOL.getCurrentUser();
      if (currentUser !== null) {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      } else {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

function changeGroupCognito(payload: {
  username: string;
  password: string;
  activeId: string;
}): Promise<CognitoUser | null> {
  return new Promise((res, rej) => {
    try {
      const currentUser = new CognitoUser({
        Username: payload.username,
        Pool: USER_POOL,
      });
      currentUser.setAuthenticationFlowType('USER_SRP_AUTH');
      currentUser.authenticateUser(
        new AuthenticationDetails({
          Username: payload.username,
          Password: payload.password,
          ClientMetadata: {
            ActiveGroupID: payload.activeId,
          },
        }),
        {
          onSuccess(session) {
            console.log('session >>>', session);
            console.log('onSuccess currentUser >>>', currentUser);
            return res(currentUser);
          },
          onFailure(error) {
            console.log('onFailure error >>>', error);
            return rej(error);
          },
        }
      );
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

function changePasswordCognito(payload: {
  oldPassword: string;
  newPassword: string;
}): Promise<string | undefined> {
  return new Promise((res, rej) => {
    try {
      const currentCognitoUser = USER_POOL && USER_POOL.getCurrentUser();
      if (currentCognitoUser !== null) {
        currentCognitoUser.changePassword(
          payload.oldPassword,
          payload.newPassword,
          function (err, result) {
            if (err) {
              return rej(err);
            }
            console.log('changePassword result: ' + result);
            return res(result);
          }
        );
      } else {
        throw Error('No Active Cognito User');
      }
    } catch (error) {
      console.log(error);
      return rej(error);
    }
  });
}

function PalmettoLogin(payload: {
  username: string;
  password: string;
}): Promise<{ authId: string; userId: number } | undefined> {
  return new Promise((res, rej) => {
    try {
      const encryptKey = '253D3FB468A0E24677C28A624BE0F939';
      if (encryptKey) {
        axios
          .post('https://www.palmettoeoc.com/api/accounts/login', {
            username: payload.username,
            password: AES.encrypt(payload.password, encryptKey).toString(),
          })
          .then((result) => {
            if (result && result.data.id) {
              res({
                authId: result.data.id,
                userId: result.data.userId,
              });
            }
          })
          .catch((error) => {
            rej(error);
          });
      } else {
        rej('Missing Encryption Key');
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

export function getJWT() {
  try {
    const idToken = COGNITO_USER && COGNITO_USER.getSignInUserSession()?.getIdToken();
    if (idToken && idToken.getJwtToken()) {
      return idToken.getJwtToken();
    } else {
      return null;
    }
  } catch (error) {
    console.log('Failed to get Cognito JWT: ', error);
    return null;
  }
}

export function refreshSession(): Promise<CognitoUserSession> {
  return new Promise((res, rej) => {
    try {
      const cognitoUserName = COGNITO_USER?.getUsername();
      const currentCognitoUser = USER_POOL && USER_POOL.getCurrentUser();
      const cognitoRefreshToken =
        COGNITO_USER && COGNITO_USER?.getSignInUserSession()?.getRefreshToken();
      if (currentCognitoUser !== null && cognitoRefreshToken) {
        console.log('Executing Cognito Refresh Session');
        currentCognitoUser.refreshSession(cognitoRefreshToken, (error, session) => {
          if (error) {
            rej(error);
          } else {
            res(session);
          }
        });
      } else {
        if (cognitoUserName && USER_POOL && cognitoRefreshToken) {
          const cognitoUser = new CognitoUser({
            Username: cognitoUserName,
            Pool: USER_POOL,
          });
          cognitoUser.refreshSession(cognitoRefreshToken, (error, session) => {
            if (error) {
              return rej(error);
            } else {
              COGNITO_USER = cognitoUser;
              return res(session);
            }
          });
        } else {
          rej('No Active Cognito User');
        }
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

export const refreshCognitoLogin = createAsyncThunk(
  'refresh-cognito',
  async (payload: { username: string; password: string; activeId?: string }) => {
    console.log('execute refresh cognito account');
    try {
      const userCognitoAccount: CognitoUser | null = await checkActiveSession(payload).then(
        (session) => session
      );
      if (userCognitoAccount) {
        let idToken;
        if (userCognitoAccount?.getSignInUserSession()) {
          idToken = userCognitoAccount?.getSignInUserSession()?.getIdToken();
        }
        if (!idToken) throw Error('No Id Token');
        const jwtToken = idToken.getJwtToken();
        const tokenSession: Partial<IidTokenSession> = idToken;
        return {
          jwtToken,
          cognitoUser: JSON.stringify(tokenSession),
        };
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('refresh-cognito catch:', error);
      throw Error(message);
    }
  }
);

export const cognitoLogin = createAsyncThunk(
  'cognito-login',
  async (payload: { username: string; password: string; activeId?: string }) => {
    try {
      console.log('Execute Cognito Login');
      const userCognitoAccount: CognitoUser | null = await checkActiveSession(payload)
        .then((session) => session)
        .catch((e) => {
          console.log('checkActiveSession error >>>', e);
          throw e;
        });

      if (userCognitoAccount) {
        let idToken: CognitoIdToken | undefined;
        if (userCognitoAccount?.getSignInUserSession()) {
          idToken = userCognitoAccount?.getSignInUserSession()?.getIdToken();
        }

        if (!idToken) throw Error('No Id Token');
        console.log('🚀~file:cognitoThunk.ts:316 ~ idToken: ', idToken);
        const tokenSession: Partial<IidTokenSession> = idToken;
        const jwtToken = idToken.getJwtToken();
        let accountInfo: Partial<IUserPortal> = getAccountFromIdToken(idToken);
        accountInfo = JSON.parse(accountInfo as string);
        accountInfo.attributes = DefineUserAttributesData(accountInfo?.attributes || []);
        console.log('PARSE accountInfo >>>', accountInfo);
        return {
          jwtToken,
          accountInfo,
          cognitoUser: JSON.stringify(tokenSession),
          activeGroupId: accountInfo.pvActiveGroupID,
        };
      }

      throw Error('Cognito Login Error');
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('cognitoLogin catch:', error);
      throw Error(message);
    }
  }
);

export const cognitoLogOut = createAsyncThunk('cognito-logout', async () => {
  try {
    const currentCognitoAccount = COGNITO_USER && COGNITO_USER.getSignInUserSession(); //USER_POOL && USER_POOL.getCurrentUser();
    if (currentCognitoAccount !== null) {
      const logOutResponse = await new Promise((res, rej) => {
        try {
          COGNITO_USER &&
            COGNITO_USER.signOut(() => {
              return res('Success');
            });
        } catch (error) {
          rej(error);
        }
      });
      if (logOutResponse instanceof Error) throw Error('Error Cognito Logout');
      return logOutResponse;
    } else {
      throw Error('Missing Cognito Current User');
    }
  } catch (error) {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('cognitoLogout catch:', error);
    throw Error(message);
  }
});

export const cognitoLoginGroup = createAsyncThunk('cognito-group-login', async (_, thunkAPI) => {
  try {
    console.log('New Cognito Login');
    const store = thunkAPI.getState() as RootState;
    const payload = {
      username: store.cognito.username || '',
      password: store.cognito.password || '',
      activeId: store.cognito.activeGroupId.toString(),
    };

    const userGroupCognito = await changeGroupCognito(payload)
      .then((session) => session)
      .catch((e) => {
        throw e;
      });

    if (userGroupCognito) {
      let idToken;
      if (userGroupCognito?.getSignInUserSession()) {
        idToken = userGroupCognito?.getSignInUserSession()?.getIdToken();
      }

      if (!idToken) throw Error('No Id Token');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let accountInfo: Partial<IUserPortal> = getAccountFromIdToken(idToken);
      accountInfo = JSON.parse(accountInfo as string);
      accountInfo.attributes = DefineUserAttributesData(accountInfo?.attributes || []);
      return {
        accountInfo,
        cognitoUser: userGroupCognito,
      };
    }
  } catch (error) {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('cognitoLogin catch:', error);
    throw Error(message);
  }
});

export const cognitoChangePassword = createAsyncThunk(
  'cognito-change-password',
  async (newPassword: string, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const oldPassword = store.cognito.password;

      if (oldPassword) {
        const result = await changePasswordCognito({ oldPassword, newPassword });
        console.log('cognitoChangePassword result >>>', result);
        return {
          result,
          newPassword,
        };
      } else {
        throw Error('Missing required parameters!');
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('cognito-change-password catch:', error);
      throw Error(message);
    }
  }
);

export const verifyPalmettoAccount = createAsyncThunk(
  'palmetto-login',
  async (payload: { username: string; password: string }) => {
    try {
      const result = await PalmettoLogin(payload);
      if (result) {
        return result;
      } else {
        throw Error('Invalid Palmetto User');
      }
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('palmetto-login catch:', error);
      throw Error(message);
    }
  }
);

export const isMultiUser = createAsyncThunk('multiuser', async (id: number, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch as AppDispatch;
    const endpoint = checkMultiUser(id);
    return await apiFetch<IUserPortal>(endpoint, store, dispatch).then((res) => res?.data || null);
  } catch (error) {
    console.log(error);
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('multiuser catch:', error);
    throw Error(message);
  }
});

export const fetchUsernames = createAsyncThunk(
  'fetch-usernames',
  async (data: { email: string }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = getUsernames(data);
      return await apiFetch<IUserAccount[]>(endpoint, store, dispatch).then(
        (res) => res?.data || []
      );
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('fetch-users catch:', error);
      throw Error(message);
    }
  }
);

export const forgotUsername = createAsyncThunk(
  'forgot-username',
  async (email: string, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = requestUsername({ email });
      return await apiFetch<string>(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('forgot-username catch:', error);
      throw Error(message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'forgot-password',
  async (data: { email: string; username: string }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = requestPasswordReset(data);
      return await apiFetch<string>(endpoint, store, dispatch).then((res) => res?.data || null);
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('forgot-password catch:', error);
      throw Error(message);
    }
  }
);

export const verifyResetToken = createAsyncThunk(
  'verify-reset-token',
  async (data: { accessToken: string; userId: number }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = validateResetToken(data);
      const result = await apiFetch<IAuthToken>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
      if (result) {
        return result;
      }
      throw Error('Invalid Token');
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('verify-reset-token catch:', error);
      throw Error(message);
    }
  }
);

export const resetForgotPassword = createAsyncThunk(
  'reset-forgot-password',
  async (data: { userId: number; password: string; accessToken: string }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = resetPassword(data);
      return await apiFetch<{ result: boolean }>(endpoint, store, dispatch).then(
        (res) => res.data || null
      );
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('verify-reset-token catch:', error);
      throw Error(message);
    }
  }
);

export const isUsernameExist = createAsyncThunk(
  'is-username-exist',
  async (username: string, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = verifyUserName(username);
      return await apiFetch<{ data: boolean }>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('is-username-exist catch:', error);
      throw Error(message);
    }
  }
);

export const passwordEncrypt = createAsyncThunk(
  'password-encrypt',
  async (data: { userID: number; password: string }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = updatePasswordEncrypt(data);
      return await apiFetch<{ message: string }>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      console.error(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('password-encrypt catch:', error);
      throw Error(message);
    }
  }
);
