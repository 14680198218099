import React from 'react';
import { DualTitleHeaderComponent, HeaderButton } from '@zawarski/palmetto-ui-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MainLayout } from 'components/layout';
import { BottomTabs, Button, TextField, Typo } from 'components/primitives';
import { Loading } from 'components/fragments';
import { Box, Grid, IconButton, Stack, useTheme } from '@mui/material';
import useStyles from './UserView.styles';
import { UserViewProps } from './UserView.props';
import { PhoneNumberFormat } from 'common/utils';
import { AccessPermission } from 'common/enum';
import usePageBar from 'hooks/usePageBar';

const UserViewContents: React.FC<UserViewProps> = ({
  userAccount,
  isFetching,
  navigateTo,
  userHasPosition,
  accessPermission,
  isAccountSuperAdmin,
  isAccountGroupAdmin,
  ...props
}) => {
  const pageBar = usePageBar('user', props.userId);
  const theme = useTheme();
  const classes = useStyles(userHasPosition);
  const fullName =
    userAccount &&
    `${userAccount.pvPersonGivenName} ${
      userAccount.pvPersonSurName ? userAccount.pvPersonSurName : ''
    }`;
  const userId = userAccount && userAccount?.id;
  const isPending = (userAccount && userAccount.status?.toLowerCase().includes('pending')) || false;

  const handleGoToPermission = () => {
    if (userId && !props.denyProgress) {
      pageBar.onChange('permission');
    }
  };

  return (
    <MainLayout>
      <Box sx={{ height: '100%', overflow: 'hidden' }}>
        <DualTitleHeaderComponent
          icon={
            <IconButton onClick={() => navigateTo('/user')}>
              <ArrowBackIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          titleBig={<Typo variant='h5'>View User</Typo>}
          titleSmall={isFetching ? 'Loading...' : fullName}
          suffix={
            isFetching ? null : (
              <HeaderButton
                style={{ fontSize: 16 }}
                onClick={() => navigateTo(`/user/edit/${userId}`)}>
                EDIT
              </HeaderButton>
            )
          }
        />
        <Stack sx={classes.wrapper}>
          {isFetching ? (
            <Loading message={'Please Wait . . .'} />
          ) : (
            <Box sx={{ px: 4, py: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='First name'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.pvPersonGivenName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Last name'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.pvPersonSurName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Username'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.username}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Email Address'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Work Phone'
                    inputProps={{ readOnly: true }}
                    value={
                      userAccount?.pvOfficePhone
                        ? PhoneNumberFormat(userAccount?.pvOfficePhone)
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Cell Phone'
                    inputProps={{ readOnly: true }}
                    value={
                      userAccount?.pvMobilePhone
                        ? PhoneNumberFormat(userAccount?.pvMobilePhone)
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Organization'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.pvOrganizationUnitName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typo fontWeight={600} color={theme.palette.primary.dark}>
                    SETTINGS
                  </Typo>
                </Grid>
                {isAccountSuperAdmin ||
                  (isAccountGroupAdmin && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        label='Receive new account request and site access request emails'
                        inputProps={{ readOnly: true }}
                        value={userAccount?.subscribeToEmail ? 'Yes' : 'No'}
                      />
                    </Grid>
                  ))}
                {accessPermission === AccessPermission.SUPERADMIN && (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label='Super Admin'
                        inputProps={{ readOnly: true }}
                        value={userAccount?.pvAdministrator ? 'Yes' : 'No'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label='Group Admin'
                        inputProps={{ readOnly: true }}
                        value={isAccountGroupAdmin ? 'Yes' : 'No'}
                      />
                    </Grid>
                  </>
                )}
                {accessPermission === AccessPermission.GROUPADMIN ||
                  (accessPermission === AccessPermission.SUBGROUPADMIN && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        label='Group Admin'
                        inputProps={{ readOnly: true }}
                        value={isAccountGroupAdmin ? 'Yes' : 'No'}
                      />
                    </Grid>
                  ))}
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Limited to training account'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.pvTrainingAccount ? 'Yes' : 'No'}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Multi-user account'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.pvMultipleUser ? 'Yes' : 'No'}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ mb: 12 }}>
                  <TextField
                    label='Account status'
                    inputProps={{ readOnly: true }}
                    value={userAccount?.status}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {isFetching ? null : (
            <>
              <Box sx={classes.pendingContainer} hidden={!isPending}>
                <Box sx={classes.pendingWrapper}>
                  <Typo fontWeight={400} fontSize={14} sx={{ color: '#474747' }}>
                    {userHasPosition ? '' : 'Add at least one position in the group'}
                  </Typo>
                  <Box sx={classes.btnContainer}>
                    <Button
                      disabled={props.approveProgress}
                      onClick={
                        !props.denyProgress ? () => props.handleDenyApprove('deny') : undefined
                      }
                      color='error'
                      sx={{ textTransform: 'uppercase' }}
                      progress={props.denyProgress}>
                      {props.denyProgress ? (
                        <span style={{ paddingRight: '30px' }}>Saving</span>
                      ) : (
                        'Deny'
                      )}
                    </Button>
                    <Button
                      color='primary'
                      sx={{ textTransform: 'uppercase' }}
                      progress={props.approveProgress}
                      onClick={
                        userHasPosition
                          ? props.approveProgress
                            ? undefined
                            : () => props.handleDenyApprove('approve')
                          : () => handleGoToPermission()
                      }>
                      {userHasPosition ? (
                        props.approveProgress ? (
                          <span style={{ paddingRight: '30px' }}>Saving</span>
                        ) : (
                          'Approve'
                        )
                      ) : (
                        'Go To Site Permission'
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <BottomTabs {...pageBar} />
            </>
          )}
        </Stack>
      </Box>
    </MainLayout>
  );
};

export default UserViewContents;
