import { Theme } from '@mui/material';

const useStyles = (theme: Theme, isGroupAdmin: boolean) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexDirection: 'column',
    backgroundColor: '#EBEBEB',
    overflowY: 'scroll',
  },
  content: {
    display: 'flex',
    maxWidth: '1440px',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    padding: '24px',
  },
  card: {
    display: 'flex',
    padding: '0 !important',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    bgcolor: '#EBEBEB',
    flex: '1 1 auto',
    flexWrap: 'wrap',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    bgcolor: '#fff',
    padding: '8px',
  },
  listContainer: {
    width: '100%',
    mb: 6,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  listEditPositionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    px: 2,
    py: 1,
    borderBottom: `0.5px solid ${theme.palette.grey[400]}`,
  },
  listBtnHeader: {
    borderBottom: `0.5px solid ${theme.palette.grey[400]}`,
  },
  listBtn: {
    borderBottom: `0.5px solid ${theme.palette.grey[400]}`,
    pl: 3,
    pr: 6,
  },
  tabs: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1000,
    width: '100vw',
  },
  positionBox: {
    height: 'auto',
    maxHeight: '548px',
    overflowY: 'scroll',
    my: '55px',
    padding: '12px',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  btnWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    padding: 2,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  titleBig: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  titleSmall: {
    fontSize: '14px',
  },
  btnGrpAdminActive: {
    display: 'flex',
    padding: '2px 8px',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '4px',
    bgcolor: '#0036FF',
    backgroundBlendMode: 'soft-light',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    color: isGroupAdmin ? '#fff' : 'rgba(255, 255, 255, 0.3)',
  },
  modalContainer: {
    height: '548px',
    maxHeight: '548px',
    minWidth: '325px',
    overflowY: 'scroll',
    mt: '50px',
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'transparent',
  },
  stepContainer: {
    position: 'absolute',
    bottom: 2,
    left: 0,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    py: 1,
    px: 4,
  },
});
export default useStyles;
