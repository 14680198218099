/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useFieldArray, UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { controlPanelSelector } from 'store/slices/controlPanel/controlPanel.selector';
import useDetailForm, { defaultValues } from './hook/useDetailForm';
import DetailsView from './Details.view';
import { IDetailsProps } from './Details.props';
import { ToastMessage } from 'components/primitives';
import { AccessPermission, ToastStatus } from 'common/enum';
import { IGroupContact, IGroupWithContacts } from 'interfaces/groups.interface';
import { IAccount2Group } from 'interfaces/userinfo.interface';
import { postGroupInfo, voidGroup } from 'store/slices/controlPanel/controlPanel.thunk';
import { resetState } from 'store/slices/controlPanel/controlPanel.slice';
import {
  selectAccessPermission,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';

type DetailsContainerProps = {
  newEntry: boolean;
};
const DetailsContainer: React.FC<DetailsContainerProps> = ({ newEntry }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUserAccount);
  const accessPermission = useAppSelector(selectAccessPermission);
  const userActiveGroupID = currentUser?.pvActiveGroupID ?? 0;
  const controlPanelState = useAppSelector(controlPanelSelector);
  const { loading, processing, selectedGroupWithContacts, selectedGroup } = controlPanelState;
  const form: UseFormReturn<IGroupWithContacts> = useDetailForm();
  const contactFields = useFieldArray({ control: form.control, name: 'contacts' });
  const activeGroupID = selectedGroup?.pvGroupID ?? null;

  const [readOnly, setReadOnly] = useState<boolean>(!newEntry);
  const [actionState, setActionState] = useState<'save' | 'delete'>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (newEntry) {
      const initialData = {
        ...defaultValues,
        pvParentGroupID: accessPermission === AccessPermission.GROUPADMIN ? userActiveGroupID : 0,
      };
      return form.reset(initialData);
    }
    if (selectedGroupWithContacts) {
      form.reset(selectedGroupWithContacts);
    }
  }, [selectedGroupWithContacts, newEntry]);

  const handleBack = () => {
    navigate(-1);
  };

  const toggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) form.setValue('pvIsAgency', event.target.checked ? 1 : 0);
  };

  const onAddContacts = () => {
    console.log('onAddContacts');
    setModalOpen(true);
  };

  const onSubmit: SubmitHandler<IGroupWithContacts> = async (data) => {
    if (readOnly) return;
    try {
      // *: Save group
      setActionState('save');
      const payload = {
        ...data,
        pvParentGroupID: newEntry
          ? accessPermission === AccessPermission.GROUPADMIN
            ? userActiveGroupID
            : 0
          : data.pvParentGroupID ?? 0,
      };
      await dispatch(postGroupInfo(payload)).unwrap();
      toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully saved!' />);
      setActionState(undefined);
      dispatch(resetState());
      handleBack();
    } catch (e) {
      console.error('onSubmit Group Catch: ', e);
      toast.info(<ToastMessage status={ToastStatus.ERROR} message='Failed to save!' />);
      setActionState(undefined);
    }
  };

  const onDelete = async () => {
    const formInfo = form.getValues();
    if (!formInfo.pvGroupID) return;
    try {
      setActionState('delete');
      await dispatch(voidGroup(formInfo.pvGroupID)).unwrap();
      toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully deleted!' />);
      setActionState(undefined);
      dispatch(resetState());
      handleBack();
    } catch (e) {
      console.error('onDelete Group Catch: ', e);
      toast.info(<ToastMessage status={ToastStatus.ERROR} message='Failed to delete!' />);
      setActionState(undefined);
    }
  };

  const onClose = () => {
    setModalOpen(false);
  };

  const cancelEdit = () => {
    if (newEntry) {
      return handleBack();
    }
    if (selectedGroupWithContacts) {
      form.reset(selectedGroupWithContacts);
    }
    setReadOnly(true);
  };

  const handleAddContacts = (contacts: IAccount2Group[]) => {
    contacts.forEach((contact) => {
      const infoContact: Partial<IGroupContact> = {
        pvDataID: 0,
        pvGroupID: activeGroupID,
        pvAccountID: contact.pvAccountID,
        pvEmail: contact.pvEmailAddress,
        pvFirstName: contact.pvFirstName,
        pvLastName: contact.pvLastName,
        pvVoid: 0,
      };
      contactFields.append(infoContact);
    });
  };

  const handleRemoveContact = (index: number) => {
    const contact = contactFields.fields[index];
    contact.pvVoid = 1;
    contactFields.update(index, contact);
  };

  const combineProps: IDetailsProps = {
    isNew: newEntry,
    form,
    loading,
    isSaving: processing && actionState === 'save',
    isDeleting: processing && actionState === 'delete',
    activeGroupID,
    readOnly,
    modalOpen,
    contactFields,
    handleBack,
    onAddContacts,
    toggleChange,
    onSubmit,
    onDelete,
    onClose,
    cancelEdit,
    handleAddContacts,
    handleRemoveContact,
    onEdit: () => setReadOnly(false),
  };

  return <DetailsView {...combineProps} />;
};

export default DetailsContainer;
