import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  wrapper: {
    position: 'relative',
    flex: '1 1',
    flexBasis: '1e-9px',
    maxWidth: 'unset !important',
    minWidth: '250px',
    width: '100%',
    minHeight: '56px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset !important',
      width: '100%',
    },
  },
  selectWrapper: {
    marginTop: '-3px !important',
    minWidth: '250px',
    minHeight: '33px',
    maxHeight: '250px',
    fontSize: '16px',
  },
  container: {
    display: 'flex',
    height: '150px',
    minWidth: '315px',
    maxWidth: '720px',
    padding: '32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '4px',
    background: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    cursor: 'pointer',
  },
  lastStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '400px',
  },
  btnFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});

export default useStyles;
