import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';
import { ForgotStatusStep as StatusStep } from 'common/enum';

export interface UserForgot {
  email: string;
  steps: StatusStep;
}

function useForgotForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        email: string().required('Email is required!').email('Please enter a valid email'),
        steps: number(),
      }),
    []
  );

  return useForm<UserForgot>({
    defaultValues: {
      email: '',
      steps: StatusStep.step1,
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useForgotForm;
