const useStyles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  msgContainer: {
    padding: '0 16px',
    textAlign: 'center',
  },
});

export default useStyles;
