import type { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    mt: '12px',
    maxHeight: '600px',
    minWidth: '450px',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '56px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  },
  headerTitle: {
    container: { display: 'flex', alignItems: 'center', height: '100%', width: '100%' },
    group: { display: 'flex', flexDirection: 'column' },
  },
  search: {
    wrapper: {},
  },
});

export default useStyles;
