import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => {
  return {
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    toolbar: {
      display: 'flex',
      columnGap: theme.spacing(2),
      minHeight: 'unset',
      height: '100%',
    },
    appContainer: {
      display: 'flex',
      width: '100%',
      maxWidth: '1440px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      gap: 2,
      flexWrap: 'wrap',
      mt: 1.5,
    },
    btnGroupContainer: {
      display: 'flex',
      width: '100%',
      maxWidth: '1440px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    toggleBtnGroup: {
      backgroundColor: '#FFFFFF',
    },
  };
};

export default useStyles;
