import type { Attributes } from 'interfaces/attribute.interface';
import { findKeyIndAttribute } from 'common/utils';

function VerifyAttributesForGroupID(
  attribute: Attributes[],
  groupId: number,
  appCode: string
): boolean {
  let found = false;
  attribute.forEach((obj) => {
    const arrayOfAppCode = obj[appCode];
    found = findKeyIndAttribute(arrayOfAppCode, String(groupId)) > -1;
    if (found) return;
  });
  return found;
}

export default VerifyAttributesForGroupID;
