import React from 'react';
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const theme = useTheme();
  const selectedColor = theme.palette.primary.main;

  const CustomToggleBtn = styled(MuiToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: selectedColor,
    },
  });

  return <CustomToggleBtn {...props} />;
};

export default ToggleButton;
