import { Theme } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#F7F7F7',
  },
  page: {
    // paddingBottom: theme.spacing(3),
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export default useStyles;
