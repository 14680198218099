import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { postRequestAccess } from 'store/slices/userManage/userManageThunk';
import { isUsernameExist } from 'store/slices/cognito/cognitoThunk';
import userManage from 'store/slices/userManage/userManage.selector';
import useRequestAccessForm, { IRequestAccess, IMenuOption } from './hook/useRequestAccessForm';
import RequestAccessView from './RequestAccess.view';
import { ReqAccessProps } from './RequestAccess.props';
import { IUserCreatePayload } from 'interfaces/userinfo.interface';
import { ForgotStatusStep as StatusSteps } from 'common/enum';
import useMedia, { MOBILE_SIZE } from 'hooks/useMediaQuery';

const successMessage =
  'Your request has been sent to our system for review. Allow for 1-2 business days for processing and review. After review, we will contact you via email with status of your request.';

const errorMessage = 'We experience a technical issue. Please try again later or contact support.';
const RequestAccess: React.FC = () => {
  const { isMobileDevice, windowDimensions } = useMedia();
  const isMobile =
    isMobileDevice || (windowDimensions?.width !== null && windowDimensions.width < MOBILE_SIZE);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const groupLists = useAppSelector(userManage.selectGroupLists);
  const inProcess = useAppSelector(userManage.inProcess);
  const requestFailed = useAppSelector(userManage.selectError);
  const form = useRequestAccessForm();
  const userName = form.watch('username');
  const [showPassword, setShowPassword] = useState(false);
  const [infoMsg, setInfoMsg] = useState(successMessage);
  const [fetching, setFetching] = useState(false);
  const [userNameError, setUserNameError] = useState(false);

  useEffect(() => {
    if (groupLists && groupLists.length) {
      const filteredGroup = groupLists.filter(
        (g) => g.pvParentGroupID === 0 || g.pvParentGroupID === null
      );

      const agencyOptions: IMenuOption[] = [];
      const countyOptions: IMenuOption[] = [];
      filteredGroup.map((g) => {
        if (g.pvIsAgency) {
          agencyOptions.push({ pvGroupID: g.pvGroupID, pvGroupName: g.pvGroupName });
        } else {
          countyOptions.push({ pvGroupID: g.pvGroupID, pvGroupName: g.pvGroupName });
        }
      });

      form.setValue('selectOption', countyOptions);
      form.setValue('agencyOption', agencyOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupLists]);

  useEffect(() => {
    if (!inProcess && requestFailed) {
      setInfoMsg(() => errorMessage);
    }
  }, [inProcess, requestFailed]);

  useEffect(() => {
    const delayKeyPressFn = setTimeout(() => {
      if (!inProcess && userName !== '' && !fetching) {
        // *: Send API Request to check if username is available
        setFetching(true);
        dispatch(isUsernameExist(userName))
          .unwrap()
          .then(({ data }) => {
            if (data) {
              setUserNameError(true);
              form.setError('username', { type: 'manual', message: 'Username is not available' });
            } else {
              setUserNameError(false);
              form.clearErrors('username');
            }
          })
          .finally(() => {
            setFetching(false);
          });
      }
    }, 2000);

    return () => clearTimeout(delayKeyPressFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  const onSubmit: SubmitHandler<IRequestAccess> = async (data) => {
    if (userNameError) return null;
    const payload: IUserCreatePayload = {
      pvPersonGivenName: data.pvPersonGivenName,
      pvPersonSurName: data.pvPersonSurName,
      username: data.username,
      email: data.email,
      pvOfficePhone: data.pvOfficePhone,
      pvMobilePhone: data.pvMobilePhone,
      pvOrganizationUnitName: data.pvOrganizationUnitName,
      password: data.password,
      subscribeToEmail: data.subscribeToEmail,
      status: data.status,
      groupId: data.groupId,
      isGroupAdmin: data.isGroupAdmin,
      pvAdministrator: data.pvAdministrator,
      pvMultipleUser: data.pvMultipleUser,
      pvPositionID: [],
      pvTrainingAccount: data.pvTrainingAccount,
    };
    await dispatch(postRequestAccess(payload))
      .unwrap()
      .then(() => {
        form.setValue('steps', StatusSteps.step3);
      })
      .catch((error) => {
        if (error instanceof Error) setInfoMsg(() => error.message);
        else setInfoMsg(() => errorMessage);
        form.setValue('steps', StatusSteps.step3);
      });
  };

  const handleBack = () => {
    navigate('/login');
  };

  const combineProps: ReqAccessProps = {
    isMobile,
    infoMsg,
    requestFailed,
    inProcess,
    showPassword,
    form,
    onSubmit,
    handleBack,
    toggleShowPassword: () => setShowPassword((prevState) => !prevState),
  };
  return <RequestAccessView {...combineProps} />;
};

export default RequestAccess;
