import React from 'react';
import { PalmettoReactTable } from '@zawarski/palmetto-direflow-react-table';
import { DrawerMenu } from 'components/primitives';
import { MainLayout } from 'components/layout';
import Box from '@mui/material/Box';
import { GridProps } from './Grid.props';
import { GroupsTableColumns } from 'common/tables';
import { Fab, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const GridView: React.FC<GridProps> = ({ accessToken, userId, ...props }) => {
  const nodeEnv = process.env.REACT_APP_NODE_ENV;
  const apiProd = 'https://klqxulghgd.execute-api.us-east-1.amazonaws.com/prod/api';
  const API_BASE_URL = nodeEnv === undefined ? apiProd : process.env.REACT_APP_SLS_LOOPBACK_API;
  let dataURL = `${API_BASE_URL}/manage/groups`;
  if (props.parentID && props.parentID !== 0) dataURL += `?parentID=${props.parentID}`;

  return (
    <MainLayout toolbar={<DrawerMenu />}>
      <div className='layout vertical full-height'>
        <Box sx={{ display: 'block', width: '100%', height: '98vh' }}>
          <PalmettoReactTable
            auth={{
              accessToken: accessToken,
              userId: userId,
            }}
            title={<span style={{ fontWeight: 500 }}>{props.titleHeader}</span>}
            headerIcon={
              <IconButton onClick={props.handleMenu}>
                <MenuIcon sx={{ color: 'white' }} />
              </IconButton>
            }
            columns={GroupsTableColumns}
            dataUrl={dataURL}
            hideFilter={true}
            hideSearch={true}
            loading={true}
            idField='pvGroupID'
            limit={35}
            extraHeaders={<></>}
            rowHeight={48}
            noHelp={true}
            onRowClick={props.onRowSelect}
          />
          <Fab
            color='primary'
            variant='extended'
            size='medium'
            hidden={!props.isAdmin}
            onClick={props.handleNewGroup}
            sx={{ bottom: '80px', right: '24px', position: 'absolute' }}>
            Add {props.parentID && props.parentID !== 0 ? 'SubGroup' : 'Group'}
            <AddCircleIcon className='icon' style={{ marginLeft: '6px' }} />
          </Fab>
        </Box>
      </div>
    </MainLayout>
  );
};

export default GridView;
