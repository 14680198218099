import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, boolean, number, ref, array } from 'yup';
import { AssignGroupFormStep, UserStatus } from 'common/enum';
import { ISelectedPositions } from 'interfaces/ISelectOptions';

export interface IUserNewFormData {
  step: AssignGroupFormStep;
  pvPersonGivenName: string;
  pvPersonSurName: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  pvOfficePhone: string;
  pvMobilePhone: string;
  pvOrganizationUnitName: string;
  subscribeToEmail: boolean;
  pvAdministrator: number;
  pvTrainingAccount: number;
  pvMultipleUser: number;
  groupId: number;
  isGroupAdmin: boolean;
  isSubGroup: number;
  status: UserStatus;
  positions: ISelectedPositions[];
}

export const defaultValues: IUserNewFormData = {
  step: AssignGroupFormStep.GROUP,
  pvPersonGivenName: '',
  pvPersonSurName: '',
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  pvOfficePhone: '',
  pvMobilePhone: '',
  pvOrganizationUnitName: '',
  subscribeToEmail: false,
  pvAdministrator: 0,
  pvTrainingAccount: 0,
  pvMultipleUser: 0,
  groupId: 0,
  isGroupAdmin: false,
  isSubGroup: 0,
  status: UserStatus.APPROVED,
  positions: [],
};

function useUserNewForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        pvPersonGivenName: string().required('This field is required!').max(255),
        pvPersonSurName: string().required('This field is required!').max(255),
        username: string()
          .required('This field is required!')
          .min(3, 'Must contain at least 3 characters!')
          .max(255)
          .test((value, ctx) => {
            const re = /^\S+$/;
            if (!re.test(value)) {
              return ctx.createError({ message: 'Must not contain blank space!' });
            }
            return true;
          }),
        email: string().email('Not a valid email!').required('This field is required!').max(255),
        password: string()
          .required('This field is required!')
          .matches(
            RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
            'Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number!'
          )
          .min(8, 'Must Container 8 Characters!')
          .max(50),
        confirmPassword: string().oneOf([ref('password')], 'Password must match!'),
        pvOfficePhone: string().nullable().max(255),
        pvMobilePhone: string().nullable().max(255),
        pvOrganizationUnitName: string().required('This field is required!').max(255),
        subscribeToEmail: boolean().required('This field is required!'),
        pvAdministrator: number().required('This field is required!'),
        pvTrainingAccount: number().required('This field is required!'),
        pvMultipleUser: number().required('This field is required!'),
        status: string().required('This field is required!'),
        isGroupAdmin: boolean(),
        isSubGroup: number(),
        groupId: number().when(['step'], {
          is: (step: string) => step === AssignGroupFormStep.GROUP,
          then: (schema) =>
            schema.test({
              name: 'groupRequired',
              message: 'Must Add a Group',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              test: (value: any) => {
                return Number(value) > 0;
              },
            }),
        }),
        positions: array().when(['step'], {
          is: (step: string) => step === AssignGroupFormStep.POSITION,
          then: (schema) =>
            schema.test({
              name: 'atLeastOne',
              message: 'Required at least one position!',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              test: (value: any) => {
                return value.some((v: ISelectedPositions) => v.checked);
              },
            }),
        }),
      }),
    []
  );

  return useForm<IUserNewFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useUserNewForm;
