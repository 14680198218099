// noinspection JSUnusedGlobalSymbols
// noinspection JSUnusedGlobalSymbols

import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import currentUserReducer from './slices/currentUser/currentUserSlice';
import tokenReducer from './slices/auth/authSlice';
import menuReducer from './slices/menu/menuSlice';
import cognitoReducer from './slices/cognito/cognitoSlice';
import userManageReducer from './slices/userManage/userManageSlice';
import positionsReducer from './slices/positions/positionsSlice';
import controlPanelReducer from './slices/controlPanel/controlPanel.slice';

const persistConfig = {
  key: 'portal-root',
  version: 1,
  storage,
  whitelist: ['user'],
};

const rootReducer = combineReducers({
  token: tokenReducer,
  menu: menuReducer,
  userInfo: currentUserReducer,
  cognito: cognitoReducer,
  user: userManageReducer,
  positions: positionsReducer,
  controlPanel: controlPanelReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
