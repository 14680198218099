import React, { useEffect, useState } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import { FormControl, FormGroup, FormControlLabel, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Typo } from 'components/primitives';
import useStyles from './SelectPosition.styles';
import { ISelectedPositions } from 'interfaces/ISelectOptions';

const SelectPosition: React.FC<{ rootName: string; maxWidth?: string } & BoxProps> = ({
  rootName,
  maxWidth,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { control, formState } = useFormContext();
  const positionsField = useFieldArray({ control, name: rootName });
  const [hasError, setHasError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errMsg: any = formState.errors.positions?.message;

  useEffect(() => {
    if (formState.errors) {
      setHasError(true);
    }
  }, [formState.errors]);

  const handleSelectChange =
    (
      onChange: (value: string | undefined | null | ISelectedPositions) => void,
      value: ISelectedPositions
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target?.checked;
      const payload = value;
      payload.checked = newValue;
      onChange(payload);
    };

  return (
    <Box {...props}>
      <FormControl
        variant='standard'
        size='small'
        sx={classes.wrapper}
        style={{ maxWidth: maxWidth ? maxWidth : '250px !important' }}
        error={true}>
        <Typo color={theme.palette.grey[700]} fontSize={16}>
          Select Positions
        </Typo>
        {hasError ? (
          <Typo color={theme.palette.error.main} fontSize={12}>
            {errMsg || ''}
          </Typo>
        ) : null}
        <FormGroup>
          {positionsField &&
            positionsField.fields.length &&
            positionsField.fields.map((_, idx) => {
              return (
                <Controller
                  key={idx}
                  name={`${rootName}.${idx}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ fontSize: '1.5rem' }}
                      control={
                        <Checkbox
                          checked={value.checked}
                          onChange={handleSelectChange(onChange, value)}
                        />
                      }
                      label={value?.label}
                    />
                  )}
                />
              );
            })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default SelectPosition;
