import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import GridView from './Grid.view';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAccessToken } from 'store/slices/auth/auth.selector';
import { GridProps, ITableRow } from './Grid.props';
import {
  selectAccessPermission,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';
import { changeMenu } from 'store/slices/menu/menuSlice';
import { fetchSelectedGroup } from 'store/slices/controlPanel/controlPanel.thunk';
import { IGroupList } from 'interfaces/groups.interface';
import { updateSelectedGroup } from 'store/slices/controlPanel/controlPanel.slice';
import { AccessPermission } from 'common/enum';

const Grid: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const palmettoToken = useAppSelector(selectAccessToken);
  const currentUser = useAppSelector(selectUserAccount);
  const accessPermission = useAppSelector(selectAccessPermission);
  const activeGroupID = currentUser?.pvActiveGroupID ?? 0;
  const currentUserID = currentUser?.id ?? 0;

  const titleHeader: string = useMemo(() => {
    const title = 'Control Panel';
    if (accessPermission === AccessPermission.SUPERADMIN) {
      return 'Control Panel \u2022 GROUP';
    }
    if (accessPermission === AccessPermission.GROUPADMIN) {
      return 'Control Panel \u2022 SUBGROUP';
    }
    return title;
  }, [accessPermission]);

  const isAdmin: boolean = useMemo(() => {
    if (accessPermission === AccessPermission.SUPERADMIN) {
      return true;
    }
    return accessPermission === AccessPermission.GROUPADMIN;
  }, [accessPermission]);

  const parentID: number | null = useMemo(() => {
    if (accessPermission === AccessPermission.SUPERADMIN) {
      return null;
    }
    if (accessPermission === AccessPermission.GROUPADMIN) {
      return activeGroupID;
    }
    return null;
  }, [accessPermission, activeGroupID]);

  const handleMenu = () => {
    dispatch(changeMenu({ BtnAction: true }));
  };

  const onRowSelect = (rows: ITableRow) => {
    const { row } = rows;
    if (row && row.pvGroupID) {
      const selectedGroup: IGroupList = row;
      dispatch(updateSelectedGroup(selectedGroup));
      dispatch(fetchSelectedGroup(Number(row.pvGroupID)));
      navigate(`/control-panel/details/${row.pvGroupID}`);
    }
  };

  const handleNewGroup = () => {
    navigate('/control-panel/details/new');
  };

  const combineProps: GridProps = {
    accessToken: palmettoToken || '',
    userId: currentUserID,
    isAdmin,
    titleHeader,
    parentID,
    handleMenu,
    handleNewGroup,
    onRowSelect,
  };

  return <GridView {...combineProps} />;
};

export default Grid;
