import { Nullable } from 'utils/Nullable';
import { IUserPortal } from 'interfaces/userinfo.interface';
import { AccessPermission, ProgramCodes } from 'common/enum';

export interface ICurrentUserState {
  userAccount: Partial<IUserPortal> | null;
  appCode: ProgramCodes | null | string;
  loading: boolean;
  success: boolean;
  isSaving: boolean;
  error: Nullable<string>;
  LEMPG_Auth: boolean;
  SUPP_Auth: boolean;
  accessPermission: AccessPermission;
}

export const CurrentUserEmptyState: ICurrentUserState = {
  userAccount: null,
  appCode: null,
  loading: false,
  success: false,
  isSaving: false,
  error: null,
  LEMPG_Auth: false,
  SUPP_Auth: false,
  accessPermission: AccessPermission.UNDEFINED,
};
