import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectAccessPermission,
  selectIsSaving,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';
import { selectMenu } from 'store/slices/menu/menu.selector';
import { changeMenu } from 'store/slices/menu/menuSlice';
import { cognitoLogOut } from 'store/slices/cognito/cognitoThunk';
import {
  fetchCurrentUserInfo,
  updateUserActiveGroup,
} from 'store/slices/currentUser/currentUserThunk';
import { updateActiveGroupId } from 'store/slices/cognito/cognitoSlice';
import {
  updateAccessPermission,
  updateCurrentUser,
} from 'store/slices/currentUser/currentUserSlice';
import userManageSelect from 'store/slices/userManage/userManage.selector';
import { IconButton, Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { MenuButton, Typo } from 'components/primitives';
import { ICurrentGroups } from 'interfaces/userinfo.interface';
import { AccessPermission } from 'common/enum';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import Groups3SharpIcon from '@mui/icons-material/Groups3Sharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LogoutIcon from '@mui/icons-material/Logout';
import GuideIcon from '@mui/icons-material/MenuBook';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TuneIcon from '@mui/icons-material/Tune';

import useStyles, { Img } from './DrawerMenu.styles';
import Logo from 'assets/img.png';
import { DefineUserAttributesData } from 'common/utils';

const DrawerMenuView: React.FC<DrawerProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMenuOpen = useAppSelector(selectMenu);
  const currentUser = useAppSelector(selectUserAccount);
  const isSaving = useAppSelector(selectIsSaving);
  const groupLists = useAppSelector(userManageSelect.selectGroupLists);
  const accessPermission = useAppSelector(selectAccessPermission);
  const userActiveID = currentUser && currentUser?.pvActiveGroupID; // useAppSelector(selectActiveGroupId);
  const theme = useTheme();
  const classes = useStyles(theme);

  const [selectGroup, setSelectGroup] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>('');
  const [menuLists, setMenuLists] = useState<ICurrentGroups[]>([]);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const UserFullName =
    currentUser !== null ? `${currentUser?.pvPersonGivenName} ${currentUser?.pvPersonSurName}` : '';

  useEffect(() => {
    if (currentUser && currentUser?.currentGroups && currentUser?.currentGroups.length) {
      const UserGroup = currentUser.currentGroups;
      const filterGroup = UserGroup.find((g) => g.i === userActiveID);
      if (currentUser && currentUser?.pvAdministrator === 1) {
        // ?: If Super Admin
        dispatch(updateAccessPermission(AccessPermission.SUPERADMIN));
        setIsUserAdmin(() => true);
      } else {
        if (filterGroup && filterGroup.a === 1) {
          const groupInfo = groupLists.find((g) => g.pvGroupID === filterGroup.i);
          if (groupInfo && groupInfo?.pvParentGroupID > 0) {
            // ?: Find group is Agency
            dispatch(updateAccessPermission(AccessPermission.SUBGROUPADMIN));
          } else {
            // ?: Is Group Admin
            dispatch(updateAccessPermission(AccessPermission.GROUPADMIN));
          }
          setIsUserAdmin(() => true);
        }
      }

      if (filterGroup && filterGroup.n) setGroupName(filterGroup.n);
      const tempUserGroups = [...UserGroup].sort((a, b) => (a.n < b.n ? -1 : 1));
      setMenuLists(tempUserGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActiveID, currentUser]);

  const toggleSlideMenu = () => {
    dispatch(changeMenu({ BtnAction: !isMenuOpen }));
  };

  const toggleGroupMenu = () => {
    setSelectGroup((prevState) => !prevState);
  };

  const navigateTo = (path: string) => {
    if (path) navigate([path].filter(Boolean).join('/'), { state: null });
    toggleSlideMenu();
  };

  const handleLogout = async () => {
    try {
      await dispatch(cognitoLogOut()).unwrap();
      localStorage.clear();
      window.location.href = '/login';
    } catch (error) {
      localStorage.clear();
      window.location.href = '/login';
    }
  };

  const handleChangeGroup = async (id: number) => {
    if (currentUser && currentUser.id) {
      dispatch(updateActiveGroupId({ id }));
      await dispatch(updateUserActiveGroup({ userId: currentUser.id, groupId: id })).unwrap();
      await dispatch(fetchCurrentUserInfo(currentUser.id))
        .unwrap()
        .then((userRecord) => {
          if (userRecord && userRecord.id && userRecord.attributes) {
            const updatedUser = {
              ...userRecord,
              attributes: DefineUserAttributesData(userRecord.attributes),
            };
            dispatch(updateCurrentUser(updatedUser));
          }
        });
      setSelectGroup(!selectGroup);
      dispatch(changeMenu({ BtnAction: !isMenuOpen }));
      navigate('/dashboard');
    }
  };

  const handleViewGuide = () => {
    const a = document.createElement('a');
    a.href = 'pdf/portal-user-guide.pdf';
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    toggleSlideMenu();
  };

  return (
    <MuiDrawer elevation={0} open={isMenuOpen} onClose={toggleSlideMenu}>
      <Box sx={classes.menuWrapper}>
        <Box sx={classes.content}>
          <Box sx={classes.upperContainer}>
            <Img src={Logo} alt='logo' />
            <Typo variant='h4' fontWeight={600}>
              Palmetto Portal
            </Typo>
            <Box sx={classes.menuContainer}>
              <Box sx={classes.stackContainer} hidden={selectGroup}>
                <Stack direction='column' spacing={1} sx={{ paddingLeft: 0 }}>
                  <Typo variant='body1' color={theme.palette.grey[700]} fontWeight={500}>
                    Menu
                  </Typo>
                  <MenuButton
                    style={{ padding: '16px' }}
                    onClick={() => navigateTo('/dashboard')}
                    startIcon={<HomeSharpIcon />}>
                    Home
                  </MenuButton>
                  {isUserAdmin ? (
                    <MenuButton
                      style={{ padding: '16px' }}
                      onClick={() => navigateTo('/user')}
                      startIcon={<SupervisedUserCircleIcon />}>
                      Users
                    </MenuButton>
                  ) : null}
                  <MenuButton
                    style={{ padding: '12px 8px 12px 16px' }}
                    onClick={toggleGroupMenu}
                    startIcon={<Groups3SharpIcon />}
                    endIcon={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {groupName ? (
                          <Typo variant={'caption'} fontSize={'0.75rem'} sx={classes.groupTitle}>
                            Groups
                          </Typo>
                        ) : (
                          ''
                        )}
                        <KeyboardArrowRightSharpIcon sx={{ color: theme.palette.primary.main }} />
                      </Box>
                    }>
                    <Typo
                      fontSize={14}
                      fontWeight={600}
                      sx={{ textAlign: 'left', maxWidth: '120px' }}>
                      {groupName}
                    </Typo>
                  </MenuButton>
                </Stack>
              </Box>
              <Box sx={classes.stackContainer} hidden={!selectGroup}>
                <Stack direction='column' spacing={1} sx={{ paddingLeft: 0 }}>
                  <Box
                    onClick={toggleGroupMenu}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <IconButton sx={{ padding: 0, mr: 1 }}>
                      <KeyboardArrowLeftSharpIcon />
                    </IconButton>
                    <Typo variant='body1' color={theme.palette.grey[700]} fontWeight={500}>
                      Groups
                    </Typo>
                  </Box>
                  <Box sx={classes.groupContainer}>
                    {menuLists &&
                      menuLists.length &&
                      menuLists.map((user, idx) => (
                        <MenuButton
                          style={{ padding: '16px' }}
                          key={user.i || idx}
                          startIcon={
                            <Groups3SharpIcon
                              sx={
                                user.i === userActiveID ? classes.activeIcon : classes.inActiveIcon
                              }
                            />
                          }
                          endIcon={
                            user.i === userActiveID && isSaving ? (
                              <CircularProgress color='inherit' size={16} />
                            ) : null
                          }
                          onClick={() => handleChangeGroup(user.i)}>
                          <Typo
                            fontSize={'0.80rem'}
                            sx={classes.menuBtn}
                            style={
                              user.i === userActiveID ? { fontWeight: 600 } : { fontWeight: 400 }
                            }>
                            {user.n || ''}
                          </Typo>
                        </MenuButton>
                      ))}
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Stack direction='column' spacing={1} sx={{ paddingLeft: 0 }}>
            <MenuButton
              style={{ padding: '12px 16px' }}
              onClick={() => navigateTo('/user-account')}
              startIcon={<PersonSharpIcon />}
              endIcon={<SettingsIcon sx={{ color: theme.palette.primary.main }} />}>
              {UserFullName}
            </MenuButton>
            {accessPermission === AccessPermission.SUPERADMIN ||
            accessPermission === AccessPermission.GROUPADMIN ? (
              <MenuButton
                style={{ padding: '12px 16px' }}
                onClick={() => navigateTo('/control-panel')}
                endIcon={<TuneIcon sx={{ color: theme.palette.primary.main }} />}>
                Control Panel
              </MenuButton>
            ) : null}
            <MenuButton
              style={{ padding: '12px 16px' }}
              onClick={() => navigateTo('/contact-support')}
              endIcon={<LiveHelpIcon sx={{ color: theme.palette.primary.main }} />}>
              Contact Support
            </MenuButton>
            <MenuButton
              style={{ padding: '12px 16px' }}
              onClick={handleViewGuide}
              endIcon={<GuideIcon sx={{ color: theme.palette.primary.main }} />}>
              View user guide
            </MenuButton>
            <MenuButton
              style={{ padding: '12px 16px' }}
              onClick={handleLogout}
              endIcon={<LogoutIcon sx={{ color: theme.palette.primary.main }} />}>
              Log out
            </MenuButton>
            <Box sx={{ textAlign: 'center' }}>
              <Typo fontWeight={500} fontSize={14} color='#9999'>
                Version 1.0
              </Typo>
            </Box>
          </Stack>
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default DrawerMenuView;
