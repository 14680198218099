import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PreviewIcon from '@mui/icons-material/Preview';
import { TabName } from '../store/slices/menu/menuState';

const usePageBar = (InitialSelectedItem: TabName, userId: number) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(InitialSelectedItem);
  const [index, setIndex] = useState(0);

  const onChange = (item: TabName) => {
    if (item === selectedItem) return;
    if (item === 'user' && selectedItem !== 'user') {
      setIndex(0);
      navigate(`/user/view/${userId}`);
    }
    if (item === 'permission' && selectedItem !== 'permission') {
      setIndex(1);
      navigate(`/user/permission/${userId}`, { replace: true });
    }
  };

  const options: { id: TabName; title: string; icon: string | React.ReactElement }[] = [
    {
      id: 'user',
      title: 'User',
      icon: <SupervisedUserCircleIcon />,
    },
    {
      id: 'permission',
      title: 'Permission',
      icon: <PreviewIcon className='icon-small' />,
    },
  ];

  return { options, selectedItem, setSelectedItem, index, setIndex, onChange };
};

export default usePageBar;
