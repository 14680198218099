import React from 'react';
import { Controller } from 'react-hook-form';
import { DualTitleHeaderComponent, HeaderButton } from '@zawarski/palmetto-ui-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MainLayout } from 'components/layout';
import { Typo, TextField, FormGroup, Button } from 'components/primitives';
import {
  CircularProgress,
  IconButton,
  Box,
  Stack,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  useTheme,
  InputAdornment,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useStyles from './UserEdit.styles';
import { UserEditProps } from './UserEdit.props';
import { PhoneNumberFormat } from 'common/utils';
import { AccessPermission, UserStatus } from 'common/enum';

const UserEditView: React.FC<UserEditProps> = ({
  userFullName,
  form,
  onSubmit,
  navigateBack,
  isProcessing,
  showPassword,
  toggleShowPassword,
  accessPermission,
  showDeleteBtn,
  processingDelete,
  handleDeleteUser,
  isAccountSuperAdmin,
  isAccountGroupAdmin,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { control } = form;

  const handlePhoneChange =
    (onChange: (value: string | undefined | null) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(PhoneNumberFormat(event.target.value));
    };

  return (
    <MainLayout>
      <Box sx={{ height: '100%', overflow: 'hidden' }}>
        <DualTitleHeaderComponent
          icon={
            <IconButton onClick={navigateBack}>
              <ArrowBackIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          titleBig={<Typo variant='h5'>Edit User</Typo>}
          titleSmall={userFullName}
          suffix={
            isProcessing ? (
              <HeaderButton style={{ fontSize: 12 }}>
                <CircularProgress sx={{ color: 'white' }} size={24} />
              </HeaderButton>
            ) : (
              <HeaderButton style={{ fontSize: 16 }} onClick={form.handleSubmit(onSubmit)}>
                SAVE
              </HeaderButton>
            )
          }
        />
        <Stack sx={classes.wrapper}>
          <Box sx={{ px: 4, py: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvPersonGivenName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='First name'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvPersonSurName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Last name'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='username'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Username'
                      value={value}
                      onChange={onChange}
                      inputProps={{ readOnly: accessPermission === AccessPermission.UNDEFINED }}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='email'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Email address'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvOfficePhone'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Work Phone'
                      value={PhoneNumberFormat(value)}
                      onChange={handlePhoneChange(onChange)}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvMobilePhone'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Cell Phone'
                      value={PhoneNumberFormat(value)}
                      onChange={handlePhoneChange(onChange)}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvOrganizationUnitName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Organization'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typo fontWeight={600} color={theme.palette.primary.dark}>
                  CREDENTIALS
                </Typo>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='password'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Password'
                      type={showPassword ? 'text' : 'password'}
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                      autoComplete='new-password'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ cursor: 'pointer', pr: 1 }}
                            position='end'
                            onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </InputAdornment>
                        ),
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='confirmPassword'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Confirm Password'
                      type={showPassword ? 'text' : 'password'}
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                      autoComplete='new-password'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ cursor: 'pointer', pr: 1 }}
                            position='end'
                            onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </InputAdornment>
                        ),
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typo fontWeight={600} color={theme.palette.primary.dark}>
                  SETTINGS
                </Typo>
              </Grid>
              {isAccountSuperAdmin ||
                (isAccountGroupAdmin && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name='subscribeToEmail'
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormGroup
                          label='Receive new account request and site access request emails'
                          error={error}>
                          <RadioGroup
                            aria-labelledby='demo-controlled-radio-buttons-group'
                            name='controlled-radio-buttons-group'
                            value={value}
                            onChange={onChange}
                            sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControlLabel
                              style={{ marginLeft: '0px' }}
                              value
                              control={<Radio />}
                              label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                            />
                            <FormControlLabel
                              style={{ marginLeft: '0px' }}
                              value={false}
                              control={<Radio />}
                              label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                            />
                          </RadioGroup>
                        </FormGroup>
                      )}
                    />
                  </Grid>
                ))}

              {accessPermission === AccessPermission.SUPERADMIN ? (
                <Grid item xs={12} md={6}>
                  <Controller
                    name='pvAdministrator'
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <FormGroup label='Super Admin' error={error}>
                        <RadioGroup
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name='controlled-radio-buttons-group'
                          value={value}
                          onChange={onChange}
                          sx={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            style={{ marginLeft: '0px' }}
                            value={1}
                            control={<Radio />}
                            label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                          />
                          <FormControlLabel
                            style={{ marginLeft: '0px' }}
                            value={0}
                            control={<Radio />}
                            label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                          />
                        </RadioGroup>
                      </FormGroup>
                    )}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} md={6}>
                <Controller
                  name='pvTrainingAccount'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='Limited to training account' error={error}>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value}
                        onChange={onChange}
                        sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={1}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                        />
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={0}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvMultipleUser'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='Multi-user account' error={error}>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value}
                        onChange={onChange}
                        sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={1}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>Yes</Typo>}
                        />
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={0}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>No</Typo>}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 12 }}>
                <Controller
                  name='status'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='Account Status' error={error}>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value}
                        onChange={onChange}
                        sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={UserStatus.APPROVED}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>Active</Typo>}
                        />
                        <FormControlLabel
                          style={{ marginLeft: '0px' }}
                          value={UserStatus.SUSPENDED}
                          control={<Radio />}
                          label={<Typo style={{ fontSize: 12 }}>In-Active</Typo>}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={classes.footerContainer} hidden={!showDeleteBtn}>
            <Box sx={classes.btnWrapper}>
              <Box sx={classes.btnContainer}>
                <Button
                  color='error'
                  sx={{ textTransform: 'uppercase', pr: processingDelete ? '50px' : '16px' }}
                  progress={processingDelete}
                  onClick={!processingDelete ? () => handleDeleteUser() : undefined}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </MainLayout>
  );
};

export default UserEditView;
