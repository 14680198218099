import React, { useEffect, useState } from 'react';
import { findIndex } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetPositions } from 'store/slices/positions/positionsSlice';
import { isLoading, positionOptions } from 'store/slices/positions/positions.selector';
import { selectAccessPermission } from 'store/slices/currentUser/currentUser.selector';
import { selectActiveGroupId } from 'store/slices/cognito/cognito.selector';
import userManageSelect from 'store/slices/userManage/userManage.selector';
import { createAccount } from 'store/slices/userManage/userManageThunk';
import { fetchPositionsByGroupID } from 'store/slices/positions/positionsThunk';
import { isUsernameExist } from 'store/slices/cognito/cognitoThunk';
import UserNewView from './UserNew.view';
import { UserNewProps } from './UserNew.props';
import useUserNewForm, { IUserNewFormData } from './hook/useUserNewForm';
import { AccessPermission, AssignGroupFormStep, ToastStatus } from 'common/enum';
import { pageSteps, StackableStep } from 'common/static';
import { ISelectedPositions } from 'interfaces/ISelectOptions';
import { IAccountPositions, IUserCreatePayload } from 'interfaces/userinfo.interface';
import { IGroupList } from 'interfaces/groups.interface';
import { ToastMessage } from 'components/primitives';

const UserNew: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = useUserNewForm();
  const positionsData = useAppSelector(positionOptions);
  const groupLists = useAppSelector(userManageSelect.selectGroupLists);
  const accessPermission = useAppSelector(selectAccessPermission);
  const activeGroupId = useAppSelector(selectActiveGroupId);
  const fetchingPosition = useAppSelector(isLoading);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openAssignGroup, setOpenAssignGroup] = useState<boolean>(false);
  const [hasNoPositions, setHasNoPositions] = useState<boolean>(true);
  const [disableGroupSelect, setDisableGroupSelect] = useState<boolean>(false);
  const [step, setStep] = useState<AssignGroupFormStep>(AssignGroupFormStep.GROUP);
  const [stepHistory, setStepHistory] = useState<StackableStep[]>(pageSteps);
  const [selectedPosition, setSelectedPosition] = useState<IAccountPositions[]>([]);
  const [filteredGroupLists, setFilteredGroupLists] = useState<IGroupList[]>(groupLists);
  const [fetching, setFetching] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const selectedGroupID = form.watch('groupId');
  const isSubgroupUser = Number(form.watch('isSubGroup')) === 1;
  const isGroupAdmin = form.watch('isGroupAdmin');
  const userName = form.watch('username');
  useEffect(() => {
    if (accessPermission && activeGroupId) {
      if (isSubgroupUser) {
        const filteredGroup = groupLists.filter((g) => g.pvParentGroupID === activeGroupId);
        setFilteredGroupLists(() => filteredGroup);
      } else {
        if (accessPermission === AccessPermission.SUPERADMIN) {
          const filteredGroup = groupLists.filter(
            (g) => g.pvParentGroupID === 0 || g.pvParentGroupID === null
          );
          setFilteredGroupLists(() => filteredGroup);
        } else if (accessPermission === AccessPermission.GROUPADMIN) {
          const filteredGroup = groupLists.filter(
            (g) => g.pvGroupID === activeGroupId || g.pvParentGroupID === activeGroupId
          );
          setFilteredGroupLists(() => filteredGroup);
        } else {
          const filteredGroup = groupLists.filter((g) => g.pvGroupID === activeGroupId);
          setFilteredGroupLists(() => filteredGroup);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessPermission, activeGroupId, isSubgroupUser]);

  useEffect(() => {
    // form.reset(defaultValues);
    return () => {
      dispatch(resetPositions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedGroupID) {
      dispatch(fetchPositionsByGroupID(selectedGroupID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupID]);

  useEffect(() => {
    if (positionsData && positionsData.length) {
      const tempOptions: ISelectedPositions[] = [];
      positionsData.map((p) => {
        const option: ISelectedPositions = {
          checked: false,
          value: p.pvPositionID,
          label: p.pvPositionName.trim(),
        };
        tempOptions.push(option);
      });

      if (tempOptions.length > 0) setHasNoPositions(() => false);
      form.setValue('positions', tempOptions);
    } else {
      setHasNoPositions(() => true);
      form.setValue('positions', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsData]);

  useEffect(() => {
    const delayKeyPressFn = setTimeout(() => {
      if (!isProcessing && userName !== '' && !fetching) {
        // *: Send API Request to check if username is available
        setFetching(true);
        dispatch(isUsernameExist(userName))
          .unwrap()
          .then((resp) => {
            if (resp.data) {
              setUserNameError(true);
              form.setError('username', { type: 'manual', message: 'Username is not available' });
            } else {
              setUserNameError(false);
              form.clearErrors('username');
            }
          })
          .finally(() => {
            setFetching(false);
          });
      }
    }, 2000);

    return () => clearTimeout(delayKeyPressFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  const onSubmit: SubmitHandler<IUserNewFormData> = async (data) => {
    if (userNameError) return null;
    setIsProcessing(true);
    const payload: IUserCreatePayload = {
      pvPersonGivenName: data.pvPersonGivenName,
      pvPersonSurName: data.pvPersonSurName,
      username: data.username,
      email: data.email,
      pvOfficePhone: data.pvOfficePhone,
      pvMobilePhone: data.pvMobilePhone,
      pvOrganizationUnitName: data.pvOrganizationUnitName,
      password: data.password,
      subscribeToEmail: data.subscribeToEmail ? 1 : 0,
      pvTrainingAccount: Number(data.pvTrainingAccount),
      pvMultipleUser: Number(data.pvMultipleUser),
      pvAdministrator: Number(data.pvAdministrator),
      groupId: data.groupId,
      isGroupAdmin: data.isGroupAdmin ? 1 : 0,
      pvPositionID: data.positions.filter((val) => val.checked).map((val) => val.value),
      status: data.status,
    };
    await dispatch(createAccount(payload))
      .unwrap()
      .then(() => {
        setIsProcessing(() => false);
        toast.info(
          <ToastMessage status={ToastStatus.SUCCESS} message={'Successfully created user'} />
        );
        setTimeout(() => navigate('/user'), 5000);
      })
      .catch((err) => {
        setIsProcessing(() => false);
        console.log('🚀~file:UserNew.container.tsx:136 ~ err: ', err);
        toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
      });
  };

  const handleModalGroup = (confirm: boolean) => {
    if (confirm) {
      if (accessPermission === AccessPermission.SUPERADMIN) {
        setOpenAssignGroup((prevState) => !prevState);
        setStep(AssignGroupFormStep.GROUP);
      } else if (
        (accessPermission === AccessPermission.GROUPADMIN ||
          accessPermission === AccessPermission.SUBGROUPADMIN) &&
        activeGroupId
      ) {
        if (isSubgroupUser) {
          setDisableGroupSelect(() => false);
          setOpenAssignGroup(() => true);
        } else {
          form.setValue('groupId', activeGroupId);
          setDisableGroupSelect(() => true);
          setOpenAssignGroup(() => true);
        }
      }
    } else {
      // ?: Make sure to revert group ID to 0 if modal is close
      form.setValue('groupId', 0);
      form.setValue('step', AssignGroupFormStep.GROUP);
      form.setValue('positions', []);
      setStep(AssignGroupFormStep.GROUP);
      setOpenAssignGroup(() => false);
      setDisableGroupSelect(() => false);
    }
  };

  const handleChangeStep = (next: AssignGroupFormStep) => {
    const nextIndex = findIndex(stepHistory, { step: next });
    const prevIndex = findIndex(stepHistory, { step });
    const prevStep = stepHistory[prevIndex];
    const steps = [...stepHistory];
    if (nextIndex > prevIndex && !prevStep.complete) {
      steps[prevIndex] = { ...prevStep, complete: true };
    }
    steps[nextIndex] = { ...steps[nextIndex], active: true };
    setStepHistory(steps);
    setStep(next);
    form.setValue('step', next);
  };

  const handleLastStep = () => {
    // ?: Filter the group
    const data = form.getValues('positions');
    const selectedGroup = groupLists.find((g) => g.pvGroupID === selectedGroupID);
    const filterPositions = data.filter((p) => p.checked);
    const tempGroupPosition: IAccountPositions[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempPosition: { i: number | any; n: string }[] = [];
    if (selectedGroup && filterPositions) {
      filterPositions.map((v) => {
        tempPosition.push({ i: v.value, n: v.label });
      });
      const value: IAccountPositions = {
        i: selectedGroup.pvGroupID,
        n: selectedGroup.pvGroupName,
        a: isGroupAdmin ? 1 : 0,
        positions: tempPosition,
      };
      tempGroupPosition.push(value);
      setSelectedPosition(tempGroupPosition);
    }
    setOpenAssignGroup(() => false);
  };

  const handleSelectGroupCard = () => {
    if (isSubgroupUser) {
      form.setValue('step', AssignGroupFormStep.GROUP);
      setStep(AssignGroupFormStep.GROUP);
      setDisableGroupSelect(() => false);
      setOpenAssignGroup(() => true);
    } else {
      setDisableGroupSelect(() => true);
      setOpenAssignGroup(() => true);
    }
  };

  const combineProps: UserNewProps = {
    groupLists: filteredGroupLists,
    disableGroupSelect,
    hasNoPositions,
    isProcessing,
    fetchingPosition,
    showPassword,
    openAssignGroup,
    form,
    step,
    stepHistory,
    selectedPosition,
    accessPermission,
    onSubmit,
    handleChangeStep,
    handleModal: handleModalGroup,
    toggleShowPassword: () => setShowPassword((prevState) => !prevState),
    navigateBack: () => navigate(-1),
    lastStepAction: handleLastStep,
    handleSelectGroupCard,
  };

  return <UserNewView {...combineProps} />;
};

export default UserNew;
