import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { verifyRequestAccess } from 'store/slices/userManage/userManageThunk';
import userManageSelector from 'store/slices/userManage/userManage.selector';
import VerifyNewAccountView from './VerifyNewAccount.view';
import { VerifyAccountProps } from './VerifyNewAccount.props';

const successMessage =
  'Your request has been sent to our system for review. Allow for 1-2 business days for processing and review. After review, we will contact you via email with status of your request.';
const errorMessage = 'Failed to verify your account. Please try again later or contact support.';

const VerifyNewAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(userManageSelector.isLoading);
  const hasError = useAppSelector(userManageSelector.selectError) !== null;
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('token');
  const userId = searchParams.get('uid');
  const [infoMsg, setInfoMsg] = useState(successMessage);

  useEffect(() => {
    if (accessToken && userId) {
      dispatch(verifyRequestAccess({ accessToken, userId: Number(userId) }))
        .unwrap()
        .catch(() => {
          setInfoMsg(() => errorMessage);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, userId]);

  const handleBack = () => {
    window.location.href = '/login';
  };

  const combineProps: VerifyAccountProps = {
    infoMsg,
    isLoading,
    hasError,
    handleBack,
  };
  return <VerifyNewAccountView {...combineProps} />;
};

export default VerifyNewAccount;
