import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number, array } from 'yup';
import { ForgotStatusStep as StatusStep } from 'common/enum';

export interface UserForgot {
  email: string;
  username: string;
  steps: StatusStep;
  usernamesList: { label: string; value: string }[];
}

function useForgotForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        email: string().required('Email is required!').email('Please enter a valid email'),
        username: string(),
        steps: number(),
        usernamesList: array(
          object().shape({
            label: string(),
            value: string(),
          })
        ),
      }),
    []
  );

  return useForm<UserForgot>({
    defaultValues: {
      email: '',
      username: '',
      steps: StatusStep.step1,
      usernamesList: [],
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useForgotForm;
