/* eslint-disable */
import DefaultPaletteOptions from 'theme/defaultPallete';

export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {
        backgroundColor: '#2961FD',
        fontSize: 'unset !important',
        fontWeight: 'unset !important',
        '& .MuiTab-root': {
          color: 'unset !important',
          backgroundColor: 'unset !important',
        },
        '& .Mui-selected': {
          color: 'unset !important',
          backgroundColor: 'unset !important',
          fontSize: 'unset !important',
          fontWeight: 'unset !important',
          borderRadius: '0 !important',
          minHeight: 'unset !important',
          maxHeight: 'unset !important',
          margin: 'unset !important',
          height: 'unset !important',
        },
      },
    },
  };
};
