import { Nullable } from 'utils/Nullable';
import { IUserPortal } from 'interfaces/userinfo.interface';

export interface ICognitoSate {
  cognitoUser: Nullable<string>;
  loginAccount: Nullable<Partial<IUserPortal>>;
  idToken: Nullable<string>;
  activeGroupId: number;
  username: Nullable<string>;
  password: Nullable<string>;
  loading: boolean;
  inProcess: boolean;
  success: boolean;
  error: Nullable<string> | undefined;
}

export const CognitoEmptyState: ICognitoSate = {
  cognitoUser: null,
  loginAccount: null,
  idToken: null,
  activeGroupId: 0,
  username: null,
  password: null,
  loading: false,
  inProcess: false,
  success: false,
  error: null,
};
