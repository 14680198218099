import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeTab } from 'store/slices/menu/menuSlice';
import userManageSelect from 'store/slices/userManage/userManage.selector';
import { selectUserAccount } from 'store/slices/currentUser/currentUser.selector';
import { selectActiveGroupId } from 'store/slices/cognito/cognito.selector';
import { selectAccessToken } from 'store/slices/auth/auth.selector';
import {
  fetchSelectedUser,
  fetchTableManageColumns,
} from 'store/slices/userManage/userManageThunk';

import { ITableRow, UserListsProps } from './UserLists.props';
import UserListsView from './UserLists.view';
import { IUserTableList } from 'interfaces/usertablelist.interface';
import { IManageColumns } from '../../interfaces/manage-columns.interface';

const UserLists = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUserAccount);
  const userActiveGroupID = useAppSelector(selectActiveGroupId);
  const palmettoToken = useAppSelector(selectAccessToken);
  const groupLists = useAppSelector(userManageSelect.selectGroupLists);
  const navigate = useNavigate();

  const isSuperAdmin = useMemo(() => currentUser?.pvAdministrator === 1, [currentUser]);

  const fetchColumnsData = async (): Promise<IManageColumns> => {
    return await dispatch(fetchTableManageColumns()).unwrap();
  };

  const onRowSelect = (rows: ITableRow) => {
    const { row } = rows;
    if (row && row.id) {
      dispatch(fetchSelectedUser(Number(row.id)));
      navigate(`/user/view/${row.id}`);
    }
    dispatch(changeTab({ tabName: 'user' }));
  };

  const onMobileRowSelect = (rows: IUserTableList) => {
    const { row } = rows;
    if (row && row.id) {
      dispatch(fetchSelectedUser(Number(row.id)));
      navigate(`/user/view/${row.id}`);
      dispatch(changeTab({ tabName: 'user' }));
    }
  };

  const handleNewUser = () => {
    navigate('/user/new');
  };

  const combineProps: UserListsProps = {
    accessToken: palmettoToken || '',
    handleNewUser,
    onRowSelect,
    onMobileRowSelect,
    isSuperAdmin,
    userActiveGroupID,
    groupLists,
    fetchColumnsData,
  };

  return <UserListsView {...combineProps} />;
};

export default UserLists;
