/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { MainLayoutProps } from './MainLayout.props';
import useStyles from './MainLayout.styles';

const MainLayout: React.FC<MainLayoutProps> = (props: MainLayoutProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box sx={classes.wrapper}>
      {props.toolbar}
      <Box sx={classes.page}>{props.children}</Box>
      {props.footer}
    </Box>
  );
};

export default MainLayout;
