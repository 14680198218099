import React from 'react';
import { Controller } from 'react-hook-form';
import { MainLayout } from 'components/layout';
import { Button, Typo, TextField } from 'components/primitives';
import { Box, Divider, Paper, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useStyles from './ForgotUsername.styles';
import { ForgotUsernameProps } from './ForgotUsername.props';
import { ForgotStatusStep as StatusStep } from 'common/enum';

const ForgotUsernameView: React.FC<ForgotUsernameProps> = ({
  handleBack,
  forgotForm,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { control, watch, handleSubmit } = forgotForm;
  const steps = watch('steps');

  return (
    <MainLayout>
      <Box sx={classes.root}>
        <Paper variant='outlined' sx={classes.container} style={{ maxHeight: '270px' }}>
          <Box
            sx={classes.iconContainer}
            hidden={steps !== StatusStep.step1}
            onClick={() => handleBack()}>
            <ArrowBackIcon />
          </Box>
          <Box sx={classes.form} hidden={steps !== StatusStep.step1}>
            <Typo fontSize={20} fontWeight={500}>
              Forgot username
            </Typo>
            <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {`Enter the email address associated with your account to search for your username.`}
            </Typo>
            <Controller
              name='email'
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  error={Boolean(error)}
                  id='email'
                  label='Email address'
                  placeholder={'Enter email address'}
                  style={{ width: '100%' }}
                  value={value}
                  onChange={onChange}
                  errMsg={error}
                />
              )}
            />
            <Button fullWidth onClick={handleSubmit(props.onSubmit)} progress={props.isRequesting}>
              SUBMIT
            </Button>
          </Box>
          <Box sx={classes.lastStepContainer} hidden={steps !== StatusStep.step3}>
            <Box sx={classes.iconContainer}>
              <CheckCircleIcon sx={{ color: 'green', height: '56px', width: '56px' }} />
            </Box>
            <Typo fontSize={20} fontWeight={500}>
              Username sent
            </Typo>
            <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {`We sent you your account username associated with your email. Check your inbox for further details.`}
            </Typo>
            <Divider variant='middle' sx={{ width: '100%' }} />
            <Typo
              fontSize={16}
              sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={() => handleBack()}>
              Return to login
            </Typo>
          </Box>
        </Paper>
        <Typo fontSize={12} sx={{ color: 'rgba(0, 0, 0, 0.54)', mt: '14px', px: '24px' }}>
          All data in palmetto is to be considered FOUO and not for public dissemination
        </Typo>
      </Box>
    </MainLayout>
  );
};

export default ForgotUsernameView;
