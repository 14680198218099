/* eslint-disable func-names */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import merge from 'deepmerge';
import apiConfig from './apiConfig';
import { AuthProtect } from 'common/enum';
import { getTokenExpiration, refreshAccessToken } from 'common/utils/getTokenExpiration';
import { getJWT } from 'store/slices/cognito/cognitoThunk';
import { IEndpoint } from 'interfaces/IEndPoint';
import { RootState, AppDispatch } from 'store';
import { updateJWTToken } from 'store/slices/auth/authSlice';

export const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

async function apiFetch<T>(
  api: IEndpoint,
  store: RootState,
  dispatch: AppDispatch
): Promise<{ status: number; data: T }> {
  // const headers: AxiosRequestConfig = {};
  const params: any = api.params || {}; // eslint-disable-line
  const { baseURL, headers } = apiConfig;

  const apiConfigMerge: AxiosRequestConfig = merge.all([
    apiConfig,
    api,
    {
      baseURL,
      headers,
      params,
    },
  ]);

  const axiosCreate = axios.create(apiConfigMerge);
  axiosCreate.interceptors.request.use(
    async function (config) {
      const currentTimestamp = Math.floor(Date.now() / 1000); // ?: Current timestamp in seconds
      if (api.authorization === AuthProtect.REQUIRED) {
        const jwt = getJWT();
        const cognitoUser = store.cognito.cognitoUser;
        const tokenSession = cognitoUser && JSON.parse(cognitoUser);
        const tokenExpiration = getTokenExpiration(tokenSession) || 0; // *: Cognito Default exp 60min.
        const difference = tokenExpiration - currentTimestamp;
        console.log('~ Expiration Time in seconds: ', difference);
        // *: Check for 30 minutes
        if (difference <= 1800) {
          const session = await refreshAccessToken();
          if (session && session?.getIdToken().getJwtToken()) {
            if (dispatch) dispatch(updateJWTToken(session?.getIdToken().getJwtToken()));
            config.headers.Authorization = session?.getIdToken().getJwtToken();
          }
        } else {
          if (jwt) {
            config.headers.Authorization = jwt;
          }
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axiosCreate.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  let response: AxiosResponse;
  try {
    response = await axiosCreate({
      ...apiConfigMerge,
      validateStatus: (status: number) => {
        if (status === 401 || status === 403) {
          // localStorage.clear();
          // window.location.href = '/login';
        }
        return status < 500;
      },
    });

    if (response.status === 400) {
      return Promise.reject(response.data);
    }
    if (response.status === 401 || response.status === 403) {
      // localStorage.clear();
      // window.location.href = '/login';
    }
  } catch (e) {
    console.log('apiFetch catch:', e);
    return Promise.reject({ message: genericErrorMessage });
  }
  return {
    status: response.status,
    data: response.data,
  };
}

export default apiFetch;
