import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';
import { SupportSubject } from 'common/enum/SupportSubject';

export interface ContactFormData {
  name: string;
  email: string;
  group: string;
  positions: string[];
  message: string;
  subject: string | SupportSubject;
}

function useContactForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required('Name is required!'),
        email: string().email('Not a valid email!').required('Email is required!'),
        message: string().required('This field is required!'),
        subject: string().required('This field is required!'),
        group: string().required('Missing selected active group account'),
        positions: array(),
      }),
    []
  );

  return useForm<ContactFormData>({
    defaultValues: {
      name: '',
      email: '',
      group: '',
      message: '',
      subject: '',
      positions: [],
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useContactForm;
