import { Nullable } from 'utils/Nullable';
import { IPositionOptions } from 'interfaces/positionoptions.interface';

export interface IPositionsState {
  positions: IPositionOptions[];
  loading: boolean;
  error: Nullable<string> | undefined;
}

export const PositionsEmptyState: IPositionsState = {
  positions: [],
  loading: false,
  error: null,
};
