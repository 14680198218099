const useStyles = () => ({
  mobileContainer: {
    color: '#000',
    textDecoration: 'none',
  },
  wrapper: {
    width: '100vw',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    flexDirection: 'column',
    lineHeight: '1.0',
    padding: '0 16px',
    minHeight: '51px',
    maxHeight: '91px',
    paddingBottom: '8px',
  },
  titleRow: {
    paddingTop: '8px',
    flexDirection: 'row',
    display: 'flex',
  },
  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'left',
    opacity: '0.87',
    flex: '1',
  },
  subRow: {
    marginTop: '5px',
    marginBottom: '2px',
    opacity: '1.0',
    display: 'flex',
    fontSize: '14px',
  },
  subTitle: {
    color: 'rgba(68, 68, 68, 0.54)',
    py: '4px',
    paddingLeft: 0,
    paddingRight: '8px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
});

export default useStyles;
