const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    minWidth: '280px',
    justifyContent: 'left',
    color: '#000',
    // textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& .MuiButton-endIcon': {
      right: '16px',
    },
  },
});

export default useStyles;
