/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, FormControlLabel, useTheme } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import useStyles from './CheckButton.styles';
import { Typo } from 'components/primitives';

type CheckButtonProps = {
  value: boolean;
  label: string;
  subTitle?: string;
  isActive?: boolean;
};

const CheckButton: React.FC<CheckButtonProps & CheckboxProps> = ({
  onChange,
  value,
  label,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, value, props.disabled);

  return (
    <FormControlLabel
      sx={classes.container}
      control={
        <Checkbox
          color='primary'
          checked={value}
          onChange={!props.disabled ? onChange : undefined}
        />
      }
      label={
        props.subTitle ? (
          <Box sx={classes.label.container}>
            <Typo style={{ whiteSpace: 'nowrap', fontSize: 14, fontWeight: 500 }}>{label}</Typo>
            <Typo style={{ whiteSpace: 'nowrap', fontSize: 12, fontWeight: 300 }}>
              {props.subTitle}
            </Typo>
          </Box>
        ) : (
          <Typo style={{ whiteSpace: 'nowrap', fontSize: 14, fontWeight: 500 }}>{label}</Typo>
        )
      }
    />
  );
};

export default CheckButton;
