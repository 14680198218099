import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typo } from 'components/primitives';

const StepProgress: React.FC<{ stepIndex: number }> = ({ stepIndex }) => {
  const value = stepIndex === 1 ? 100 : 50;
  return (
    <Box sx={{ width: '100%', mb: 1, display: 'flex', flexDirection: 'column', px: 1 }}>
      <Typo variant='body1' fontWeight={500}>
        Step {stepIndex + 1} of 2
      </Typo>
      <LinearProgress variant='determinate' value={value} sx={{ height: '5px' }} />
    </Box>
  );
};

export default StepProgress;
