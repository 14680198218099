import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { findIndex } from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import useGroupForm, { UserGroupFormData } from 'hooks/useGroupForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTabName } from 'store/slices/menu/menu.selector';
import userManageSelector from 'store/slices/userManage/userManage.selector';
import { isLoading, positionOptions } from 'store/slices/positions/positions.selector';
import {
  selectAccessPermission,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';
import { selectActiveGroupId } from 'store/slices/cognito/cognito.selector';
import { updateUserPermission } from 'store/slices/userManage/userManageSlice';
import { changeTab } from 'store/slices/menu/menuSlice';
import { TabName } from 'store/slices/menu/menuState';
import { fetchPositionsByGroupID } from 'store/slices/positions/positionsThunk';
import { addUserToGroup, fetchSelectedUser } from 'store/slices/userManage/userManageThunk';
import { ITableRow, UserPermissionListsProps } from './UserPermissionLists.props';
import { AccessPermission, AssignGroupFormStep } from 'common/enum';
import { generateDataPermissions } from 'common/utils';
import { pageSteps, StackableStep } from 'common/static';
import { ISelectedPositions } from 'interfaces/ISelectOptions';
import { IAssignGroupPayload, IGroupList } from 'interfaces/groups.interface';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import UserPermissionListsView from './UserPermissionLists.view';

const UserPermissionLists = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const tabName = useAppSelector(selectTabName);
  const permissionsList = useAppSelector(userManageSelector.selectPermissions);
  const fetchingPositions = useAppSelector(isLoading);
  const positionsData = useAppSelector(positionOptions);
  const isProcessing = useAppSelector(userManageSelector.inProcess);
  const fetchingUsers = useAppSelector(userManageSelector.isLoading);
  const groupLists = useAppSelector(userManageSelector.selectGroupLists);
  const userAccount = useAppSelector(userManageSelector.selectedUser);
  const accessPermission = useAppSelector(selectAccessPermission);
  const currentUserAccount = useAppSelector(selectUserAccount);
  const loggedInUserGroupInfo = (currentUserAccount && currentUserAccount?.currentGroups) || [];
  const currentUserGroupInfo = userAccount?.currentGroups || [];
  const userActiveGroupID = useAppSelector(selectActiveGroupId);
  const userId = Number(params?.id) || 0;
  const groupForm: UseFormReturn<UserGroupFormData> = useGroupForm();

  const [step, setStep] = useState<AssignGroupFormStep>(AssignGroupFormStep.GROUP);
  const [stepHistory, setStepHistory] = useState<StackableStep[]>(pageSteps);
  const [openAssignGroup, setOpenAssignGroup] = useState<boolean>(false);
  const [hasNoPositions, setHasNoPositions] = useState<boolean>(true);
  const [filteredGroupLists, setFilteredGroupLists] = useState<IGroupList[]>(groupLists);
  const [showView, setShowView] = useState<boolean>(false);
  const selectedGroupID = groupForm.watch('groupId');

  useEffect(() => {
    if (accessPermission && userActiveGroupID) {
      if (accessPermission === AccessPermission.SUPERADMIN) {
        const filteredGroup = groupLists.filter(
          (g) => g.pvParentGroupID === 0 || g.pvParentGroupID === null
        );
        return setFilteredGroupLists(() => filteredGroup);
      }

      if (accessPermission === AccessPermission.GROUPADMIN) {
        const filteredGroup = groupLists.filter(
          (g) => g.pvGroupID === userActiveGroupID || g.pvParentGroupID === userActiveGroupID
        );
        return setFilteredGroupLists(() => filteredGroup);
      }

      if (accessPermission === AccessPermission.SUBGROUPADMIN) {
        const filteredGroup = groupLists.filter((g) => g.pvGroupID === userActiveGroupID);
        setFilteredGroupLists(() => filteredGroup);
      }
    }
  }, [accessPermission, userActiveGroupID, groupLists]);

  useEffect(() => {
    if (selectedGroupID) {
      dispatch(fetchPositionsByGroupID(selectedGroupID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupID]);

  useEffect(() => {
    if (positionsData && positionsData.length) {
      const tempOptions: ISelectedPositions[] = [];
      positionsData.map((p) => {
        const option: ISelectedPositions = {
          checked: false,
          value: p.pvPositionID,
          label: p.pvPositionName.trim(),
        };
        tempOptions.push(option);
      });
      if (tempOptions.length > 0) setHasNoPositions((prevState) => !prevState);
      groupForm.setValue('positions', tempOptions);
    } else {
      setHasNoPositions(() => true);
      groupForm.setValue('positions', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsData]);

  useEffect(() => {
    if (permissionsList && permissionsList.length === 1) {
      const permission = permissionsList[0];
      if (permission && permission.id && permission.isEditable) {
        dispatch(fetchPositionsByGroupID(permission.id));
        const propsState = { userId, isSingleGroup: true };
        return navigate(`/user/permission/${userId}/view/${permission.id}`, {
          state: { propsState },
        });
      }
    } else {
      setShowView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsList]);

  const handleChangeTab = (_event: React.SyntheticEvent, tab: TabName) => {
    if (tab) {
      dispatch(changeTab({ tabName: tab }));
      if (tab === 'user' && tabName !== 'user') navigate(`/user/view/${userId}`);
    }
  };

  const handleChangeStep = (next: AssignGroupFormStep) => {
    const nextIndex = findIndex(stepHistory, { step: next });
    const prevIndex = findIndex(stepHistory, { step });
    const prevStep = stepHistory[prevIndex];
    const steps = [...stepHistory];
    if (nextIndex > prevIndex && !prevStep.complete) {
      steps[prevIndex] = { ...prevStep, complete: true };
    }
    steps[nextIndex] = { ...steps[nextIndex], active: true };
    setStepHistory(steps);
    setStep(next);
    groupForm.setValue('step', next);
  };

  const onRowSelect = (rows: ITableRow) => {
    const { row } = rows;
    if (row && row.id && row.isEditable) {
      dispatch(fetchPositionsByGroupID(row.id));
      return navigate(`/user/permission/${userId}/view/${row.id}`);
    }
  };

  const onMobileRowSelect = (rows: IPermissionList) => {
    const { id } = rows;
    if (rows && id) {
      dispatch(fetchPositionsByGroupID(id));
      navigate(`/user/permission/${userId}/view/${id}`);
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const handleModalGroup = () => {
    setOpenAssignGroup(!openAssignGroup);
    setStep(AssignGroupFormStep.GROUP);
    const formData = {
      groupId: 0,
      positions: [],
      isGroupAdmin: false,
      userId: userId || 0,
    };
    groupForm.reset(formData);
  };

  const onSubmit = async () => {
    try {
      const data = groupForm.getValues();
      const filterPositions = data.positions.filter((p) => p.checked).map((p) => p.value);
      const payload: IAssignGroupPayload = {
        userId: data.userId,
        groupId: data.groupId,
        positions: filterPositions,
        isGroupAdmin: data.isGroupAdmin ? 1 : 0,
      };
      const resultAdd = await dispatch(addUserToGroup(payload))
        .unwrap()
        .then((resp) => resp);

      if (resultAdd) {
        await dispatch(fetchSelectedUser(data.userId))
          .unwrap()
          .then((resp) => {
            if (resp && resp?.account2position) {
              const dataPermissions: IPermissionList[] = generateDataPermissions(
                resp,
                accessPermission,
                userActiveGroupID,
                groupLists,
                currentUserGroupInfo,
                loggedInUserGroupInfo
              );
              if (dataPermissions.length) {
                dispatch(updateUserPermission(dataPermissions));
              }
            }
          });
        handleModalGroup();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const combineProps: UserPermissionListsProps = {
    userId,
    userAccount,
    groupForm,
    groupLists: filteredGroupLists,
    permissionsList,
    step,
    stepHistory,
    tabName,
    openAssignGroup,
    fetchingPositions,
    isProcessing,
    fetchingUsers,
    hasNoPositions,
    onSubmit,
    handleChangeTab,
    handleChangeStep,
    navigateBack,
    handleModal: handleModalGroup,
    onRowSelect,
    onMobileRowSelect,
  };

  if (!showView) return <></>;

  return <UserPermissionListsView {...combineProps} />;
};

export default UserPermissionLists;
