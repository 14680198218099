import React from 'react';
import { HeaderComponent, HeaderButton } from '@zawarski/palmetto-ui-components';
import { Controller } from 'react-hook-form';
import { MainLayout, PageMaxLayout } from 'components/layout';
import { Box, Grid, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import { FormContent, TextField, Typo } from 'components/primitives';
import { IDetailsProps } from './Details.props';
import useStyles from './Details.styles';
import { ActionButtons, Loading } from 'components/fragments';
import { ContactCard, ModalContacts } from '../components';

const DetailsView: React.FC<IDetailsProps> = ({
  isNew,
  form,
  loading,
  readOnly,
  modalOpen,
  onEdit,
  onClose,
  handleBack,
  contactFields,
  handleAddContacts,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const pvGroupTitle = form.watch('pvGroupTitle');
  const isAgencyValue = form.watch('pvIsAgency') === 1;
  const contactLists = form.watch('contacts');

  return (
    <MainLayout>
      <>
        <HeaderComponent
          icon={
            readOnly ? (
              <IconButton onClick={handleBack}>
                <BackIcon sx={{ color: 'white' }} />
              </IconButton>
            ) : (
              <IconButton onClick={props.cancelEdit}>
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
            )
          }
          title={<Typo variant='h4'>{isNew ? 'New Entry' : pvGroupTitle}</Typo>}
          suffix={
            <div className='form-margin-16'>
              {readOnly ? (
                <HeaderButton onClick={onEdit}>
                  <Typo fontWeight={600}>EDIT</Typo>
                </HeaderButton>
              ) : null}
            </div>
          }
        />
        <PageMaxLayout>
          {loading ? (
            <Loading />
          ) : (
            <>
              <FormContent
                title='Details'
                bgcolor={'#FFF'}
                withToggle
                toggleTitle='IS AGENCY'
                toggleValue={isAgencyValue}
                toggleChange={props.toggleChange}>
                <Grid container spacing={2} sx={classes.form.container}>
                  {!isNew && (
                    <Grid item xs={12} md={3}>
                      <Controller
                        control={form.control}
                        name='pvGroupID'
                        render={({ field: { value }, fieldState: { error } }) => (
                          <TextField
                            error={Boolean(error)}
                            label='Group ID'
                            value={value}
                            errMsg={error}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={isNew ? 6 : 4}>
                    <Controller
                      control={form.control}
                      name='pvGroupName'
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          required
                          error={Boolean(error)}
                          label='Name'
                          value={value}
                          onChange={onChange}
                          errMsg={error}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={isNew ? 6 : 5}>
                    <Controller
                      control={form.control}
                      name='pvGroupTitle'
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          required
                          error={Boolean(error)}
                          label='Title'
                          value={value}
                          onChange={onChange}
                          errMsg={error}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={form.control}
                      name='pvGroupComment'
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          error={Boolean(error)}
                          label='Description'
                          value={value}
                          onChange={onChange}
                          errMsg={error}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormContent>
              {!isNew && (
                <FormContent
                  title={'Contacts'}
                  btnTitle={'Add contacts'}
                  bgcolor={'#FFF'}
                  withEdit
                  isAdd
                  isEdit={!readOnly}
                  onClick={props.onAddContacts}>
                  <Box sx={classes.contacts.container}>
                    <Box sx={classes.contacts.wrapper}>
                      {contactFields && contactFields.fields.length > 0 ? (
                        contactFields.fields.map((field, idx) => {
                          const fullName = `${field.pvFirstName} ${field.pvLastName}`;
                          if (field.pvVoid === 1) return null;
                          return (
                            <ContactCard
                              key={idx}
                              readOnly={readOnly}
                              leftIcon={
                                <PersonIcon
                                  fontSize={'medium'}
                                  sx={{ color: theme.palette.grey[300] }}
                                />
                              }
                              headerTitle={fullName.length > 3 ? fullName : 'Person Name'}
                              subTitle={field.pvEmail}
                              onRemove={() => props.handleRemoveContact(idx)}
                            />
                          );
                        })
                      ) : (
                        <Box sx={classes.emptyContainer.wrapper}>
                          <Typo sx={classes.emptyContainer.text}>No Assigned Contacts</Typo>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </FormContent>
              )}
            </>
          )}
        </PageMaxLayout>
        <ModalContacts
          existingContacts={contactLists}
          activeGroupID={props.activeGroupID}
          isOpen={modalOpen}
          onClose={onClose}
          title='Contacts'
          callBack={handleAddContacts}
        />
        <ActionButtons
          readOnly={readOnly}
          isSaving={props.isSaving}
          isDeleting={props.isDeleting}
          withDelete={!isNew}
          withSave
          withCancel
          cancel={props.cancelEdit}
          save={!props.isSaving ? form.handleSubmit(props.onSubmit) : undefined}
          delete={!props.isDeleting ? props.onDelete : undefined}
        />
      </>
    </MainLayout>
  );
};

export default DetailsView;
