import React from 'react';
import useStyles from './SectionContainer.styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Typo } from 'components/primitives';

interface SectionContainerProps {
  title: string;
  hideButton?: boolean;
  button?: React.ReactNode;
  btnTitle?: string;
  btnIcon?: React.ReactNode;
  onClick?: () => void;
  noChildren?: boolean;
}
const SectionContainer: React.FC<SectionContainerProps & BoxProps> = ({
  title,
  hideButton = true,
  noChildren = false,
  ...props
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.titleWrapper}>
        <Typo sx={classes.title} variant={'body1'}>
          {title}
        </Typo>
        <Box sx={classes.btn} hidden={hideButton} onClick={handleClick}>
          {props.btnTitle} {props.btnIcon}
        </Box>
      </Box>
      <Box sx={classes.wrapper} hidden={noChildren}>
        {props.children}
      </Box>
    </Box>
  );
};

export default SectionContainer;
