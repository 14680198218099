export type TabName = 'user' | 'permission';

export interface IMenuState {
  openDrawer: boolean;
  tabName: TabName;
}

export const DefaultMenuState: IMenuState = {
  openDrawer: false,
  tabName: 'user',
};
