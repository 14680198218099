import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { fetchUsernames, forgotPassword } from 'store/slices/cognito/cognitoThunk';
import useForgotForm, { UserForgot } from './hook/useForgotForm';
import ForgotPasswordView from './ForgotPassword.view';
import { ForgotPasswordProps } from './ForgotPassword.props';
import { ForgotStatusStep as StatusStep } from 'common/enum';

const successMessage =
  'We sent you a link to reset your account password. Check your inbox for further details.';
const errorMessage = 'We experience a technical issue. Please try again later or contact support.';
const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const forgotForm = useForgotForm();
  const [isProcessing, setIsProcessing] = useState(false);
  const [infoMsg, setInfoMsg] = useState(successMessage);
  const [hasFailed, setHasFailed] = useState(false);

  const handleBack = () => {
    const steps = forgotForm.getValues().steps;
    if (steps === StatusStep.step1 || steps === StatusStep.step3) {
      return navigate('/login');
    }
    if (steps === StatusStep.step2) {
      forgotForm.setValue('usernamesList', []);
      return forgotForm.setValue('steps', StatusStep.step1);
    }
  };

  const sendRequest = async (payload: { email: string; username: string }) => {
    await dispatch(forgotPassword(payload))
      .unwrap()
      .then((res) => {
        setIsProcessing(() => false);
        if (res && typeof res === 'string') {
          forgotForm.setValue('steps', StatusStep.step3);
        } else {
          setInfoMsg(() => errorMessage);
          setHasFailed(() => true);
          forgotForm.setValue('steps', StatusStep.step3);
        }
      })
      .catch((error) => {
        console.log('🚀~file:ForgotPassword.container.tsx:47 ~ error: ', error);
        setInfoMsg(() => errorMessage);
        setHasFailed(() => true);
        forgotForm.setValue('steps', StatusStep.step3);
      });
  };
  const onSubmit: SubmitHandler<UserForgot> = async (data) => {
    setIsProcessing(() => true);
    const users = await dispatch(fetchUsernames({ email: data.email })).unwrap();
    if (Array.isArray(users) && users.length) {
      if (users.length > 1) {
        setIsProcessing(() => false);
        const usersLists: { label: string; value: string }[] = [];
        users.map((u) => {
          usersLists.push({ label: u.username, value: u.username });
        });
        forgotForm.setValue('usernamesList', usersLists);
        forgotForm.setValue('steps', StatusStep.step2);
      } else {
        const user = users[0];
        const payload = {
          email: data.email,
          username: user.username,
        };
        await sendRequest(payload);
      }
    } else {
      setIsProcessing(() => false);
      forgotForm.setError('email', {
        type: 'manual',
        message: 'No user account found!',
      });
    }
  };

  const handleResetLink = async () => {
    const { email, username } = forgotForm.getValues();
    if (email.length > 1 && username.length > 1) {
      const payload = { email, username };
      setIsProcessing(() => true);
      await sendRequest(payload);
    }
  };

  const combineProps: ForgotPasswordProps = {
    forgotForm,
    isProcessing,
    hasFailed,
    infoMsg,
    handleBack,
    onSubmit,
    handleResetLink,
  };

  return <ForgotPasswordView {...combineProps} />;
};

export default ForgotPassword;
