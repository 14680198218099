import { RootState } from 'store';

const isLoading = (state: RootState) => state.user.loading;
const selectedUser = (state: RootState) => state.user.user;
const selectPermissions = (state: RootState) => state.user.userPermission;
const selectGroupLists = (state: RootState) => state.user.groupLists;
const inProcess = (state: RootState) => state.user.inProcess;
const selectAppCode = (state: RootState) => state.user.appCode;
const selectPositionLists = (state: RootState) => state.user.positionLists;
const selectError = (state: RootState) => state.user.error;

const userManageSelect = {
  isLoading,
  selectedUser,
  selectPermissions,
  selectGroupLists,
  selectPositionLists,
  selectAppCode,
  inProcess,
  selectError,
};

export default userManageSelect;
