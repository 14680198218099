import React from 'react';
import { Controller } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { changeMenu } from 'store/slices/menu/menuSlice';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { IconButton, Paper, Box, Stack, FormHelperText, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { MainLayout } from 'components/layout';
import { DrawerMenu, Typo, TextField, Button } from 'components/primitives';
import { SupportSubject } from 'common/enum/SupportSubject';
import { ContactSupportProps } from './ContactSupport.props';

const ContactSupportView: React.FC<ContactSupportProps> = ({ form, inProcess, ...props }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { control, handleSubmit, formState } = form;
  const formErrorGroup = formState.errors?.group?.message;

  const handleMenu = () => {
    dispatch(changeMenu({ BtnAction: true }));
  };

  return (
    <MainLayout toolbar={<DrawerMenu />}>
      <>
        <HeaderComponent
          icon={
            <IconButton onClick={handleMenu}>
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          title={<Typo variant='h4'>Contact Support</Typo>}
        />
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw' }}>
          <Paper
            elevation={3}
            sx={{
              mt: { xs: 3, md: 6 },
              mx: { xs: 3, lg: 8 },
              p: { xs: 2, md: 3 },
              maxWidth: '688px',
              minWidth: { lg: '600px' },
            }}>
            <Stack direction='column' spacing={2}>
              {formErrorGroup && formErrorGroup.length ? (
                <Box
                  sx={{
                    borderRadius: 2,
                    border: `1px solid ${theme.palette.error.main}`,
                    px: 4,
                    py: 1,
                    textAlign: 'center',
                  }}>
                  <Typo color={theme.palette.error.main} fontSize={16}>
                    {formErrorGroup}
                  </Typo>
                </Box>
              ) : null}
              <Typo variant='h5' fontWeight={500} color={theme.palette.primary.dark}>
                Feel free to contact us
              </Typo>
              <Typo variant='body1' color='common.black'>
                A response will be provide within 2 working days.
              </Typo>
              <Controller
                control={control}
                name={'subject'}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControl
                    variant='standard'
                    sx={{ m: 1, minWidth: 120 }}
                    error={Boolean(error)}>
                    <InputLabel id='demo-simple-select-standard-label'>Subject</InputLabel>
                    <Select
                      labelId='demo-simple-select-standard-label'
                      id='demo-simple-select-standard'
                      value={value}
                      onChange={onChange}
                      label='Subject'>
                      <MenuItem value={SupportSubject.BUG}>{SupportSubject.BUG}</MenuItem>
                      <MenuItem value={SupportSubject.SUGGESTION}>
                        {SupportSubject.SUGGESTION}
                      </MenuItem>
                      <MenuItem value={SupportSubject.TECHNICAL}>
                        {SupportSubject.TECHNICAL}
                      </MenuItem>
                    </Select>
                    {error ? (
                      <FormHelperText id='component-error-text' error={Boolean(error)}>
                        {error?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name={'name'}
                render={({ field: { value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Name'
                    value={value}
                    inputProps={{ readOnly: true }}
                    errMsg={error}
                  />
                )}
              />
              <Controller
                control={control}
                name={'email'}
                render={({ field: { value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Email address'
                    value={value}
                    inputProps={{ readOnly: true }}
                    errMsg={error}
                  />
                )}
              />
              <Controller
                control={control}
                name={'message'}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Details'
                    multiline
                    rows={3}
                    maxRows={4}
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                  />
                )}
              />
              <Button progress={inProcess} onClick={handleSubmit(props.onSubmit)}>
                {!inProcess ? 'Submit' : 'Please wait ...'}
              </Button>
            </Stack>
          </Paper>
        </Box>
      </>
    </MainLayout>
  );
};

export default ContactSupportView;
