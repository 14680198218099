import { createSlice } from '@reduxjs/toolkit';
import { IPositionsState, PositionsEmptyState } from './positionsState';
import { fetchPositionsByGroupID } from './positionsThunk';

const initialState: IPositionsState = PositionsEmptyState;

export const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    resetPositions: (state) => {
      state.positions = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPositionsByGroupID.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.positions = [];
      })
      .addCase(fetchPositionsByGroupID.fulfilled, (state, action) => {
        state.loading = false;
        state.positions = action.payload;
      })
      .addCase(fetchPositionsByGroupID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetPositions } = positionsSlice.actions;

export default positionsSlice.reducer;
