import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPositionOptions } from 'interfaces/positionoptions.interface';
import { AppDispatch, RootState } from 'store';
import apiFetch from 'services/apiFetch';
import { getPositionsByID } from 'services/permissionsEndpoint';

export const fetchPositionsByGroupID = createAsyncThunk(
  'fetch-positions',
  async (id: number, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = getPositionsByID(id);
      return await apiFetch<IPositionOptions[]>(endpoint, store, dispatch).then(
        (res) => res?.data || []
      );
    } catch (error) {
      console.log(error);
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('fetchPositionsByGroupID catch:', error);
      throw Error(message);
    }
  }
);
