import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, ref, string } from 'yup';
import { ForgotStatusStep as StatusStep } from 'common/enum';

export interface IResetPassword {
  password: string;
  confirmPassword: string;
  steps: StatusStep;
}

function useResetPasswordForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        password: string()
          .required('This field is required!')
          .matches(
            RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
            'Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number!'
          )
          .min(8, 'Must Container 8 Characters!')
          .max(50),
        confirmPassword: string().oneOf([ref('password')], 'Password must match'),
        steps: number(),
      }),
    []
  );

  return useForm<IResetPassword>({
    defaultValues: {
      password: '',
      confirmPassword: '',
      steps: StatusStep.step1,
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useResetPasswordForm;
