import { RootState } from 'store';

export const selectUserAccount = (state: RootState) => state.userInfo.userAccount;
export const selectFetching = (state: RootState) => state.userInfo.loading;
export const selectError = (state: RootState) => state.userInfo.error;
export const selectAppCode = (state: RootState) => state.userInfo.appCode;
export const selectIsSaving = (state: RootState) => state.userInfo.isSaving;
export const selectLEMPGAuth = (state: RootState) => state.userInfo.LEMPG_Auth;
export const selectSUPPAuth = (state: RootState) => state.userInfo.SUPP_Auth;
export const selectAccessPermission = (state: RootState) => state.userInfo.accessPermission;
