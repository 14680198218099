import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ISelectedPositions } from '../interfaces/ISelectOptions';
import { AssignGroupFormStep } from '../common/enum';

export interface UserGroupFormData {
  step: AssignGroupFormStep;
  groupId: number;
  userId: number;
  isGroupAdmin: boolean;
  positions: ISelectedPositions[];
}

export const defaultValues = {
  step: AssignGroupFormStep.GROUP,
  groupId: 0,
  userId: 0,
  isGroupAdmin: false,
  positions: [],
};

function useUserGroupForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        groupId: yup.number().when(['step'], {
          is: (step: string) => step === AssignGroupFormStep.GROUP,
          then: (schema) => schema.required('This field is required'),
        }),
        positions: yup.array().when(['step'], {
          is: (step: string) => step === AssignGroupFormStep.POSITION,
          then: (schema) =>
            schema.test({
              name: 'atLeastOne',
              message: 'Required at least one position!',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              test: (value: any) => {
                return value.some((v: ISelectedPositions) => v.checked);
              },
            }),
        }),
        userId: yup.number().required('This field is required'),
        isGroupAdmin: yup.boolean(),
      }),
    []
  );

  return useForm<UserGroupFormData>({ defaultValues, resolver: yupResolver(validationSchema) });
}

export default useUserGroupForm;
