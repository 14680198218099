/* eslint-disable react/destructuring-assignment */
import React from 'react';
import MuiButton from '@mui/material/Button';
import { MenuButtonProps } from './MenuButton.props';
import useStyles from './MenuButton.styles';

const MenuButton: React.FC<MenuButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiButton
      {...props}
      sx={classes.container}
      startIcon={props && props?.startIcon}
      endIcon={props && props?.endIcon}
      disableElevation>
      {props.children}
    </MuiButton>
  );
};

export default MenuButton;
