import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export interface UserLogin {
  username: string;
  password: string;
  changeUserName: string;
  hasSpace: boolean;
}

function useLoginForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        username: yup.string().required('Username is required'),
        password: yup.string().required('Password is required'),
        changeUserName: yup.string().when(['hasSpace'], {
          is: (hasSpaces: boolean) => hasSpaces,
          then: (schema) =>
            schema.test({
              name: 'noSpaces',
              message: 'This field is required with no spaces!',
              test: (value: string | undefined) => {
                if (value && value.length) {
                  const hasSpace = /\s/.test(value);
                  return !hasSpace;
                } else {
                  return false;
                }
              },
            }),
        }),
      }),
    []
  );

  return useForm<UserLogin>({
    defaultValues: {
      username: '',
      password: '',
      changeUserName: '',
      hasSpace: false,
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useLoginForm;
