/* eslint-disable react/destructuring-assignment */
import React from 'react';
import MuiModal, { ModalProps } from '@mui/material/Modal';
import { Backdrop, Fade, Paper, useTheme } from '@mui/material';
import useStyles from './Modal.styles';

const ModalView: React.FC<ModalProps> = (props: ModalProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <MuiModal
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      {...props}>
      <Fade in={props.open}>
        <Paper sx={classes.modalContent} elevation={1}>
          {props.children}
        </Paper>
      </Fade>
    </MuiModal>
  );
};

export default ModalView;
