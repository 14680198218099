import { Theme } from '@mui/material';

const useStyles = (theme: Theme, width: string) => ({
  content: {
    width,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      width: '100%',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    columnGap: '10px',
    '& button': {
      maxWidth: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
      '& button': {
        maxWidth: 'unset',
      },
    },
  },
});

export default useStyles;
