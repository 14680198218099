import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object, ref, string } from 'yup';
import { Nullable } from 'utils/Nullable';
import { UserStatus, ForgotStatusStep as StatusStep } from 'common/enum';

export interface IMenuOption {
  pvGroupID: number;
  pvGroupName: string;
}

export interface IRequestAccess {
  pvPersonGivenName: string;
  pvPersonSurName: string;
  middleName: string;
  username: string;
  email: string;
  pvOfficePhone: Nullable<string>;
  pvMobilePhone: Nullable<string>;
  pvOrganizationUnitName: string;
  password: string;
  confirmPassword: string;
  groupId: number;
  selectOption: IMenuOption[];
  agencyOption: IMenuOption[];
  subscribeToEmail: number;
  pvAdministrator: number;
  pvTrainingAccount: number;
  pvMultipleUser: number;
  isGroupAdmin: number;
  status: UserStatus;
  steps: StatusStep;
}

export const defaultValues: IRequestAccess = {
  pvPersonGivenName: '',
  pvPersonSurName: '',
  middleName: '',
  username: '',
  email: '',
  pvOfficePhone: '',
  pvMobilePhone: '',
  pvOrganizationUnitName: '',
  password: '',
  confirmPassword: '',
  groupId: 0,
  agencyOption: [],
  selectOption: [],
  subscribeToEmail: 0,
  pvAdministrator: 0,
  pvTrainingAccount: 0,
  pvMultipleUser: 0,
  isGroupAdmin: 0,
  status: UserStatus.PENDING,
  steps: StatusStep.step1,
};

function useRequestAccessForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        pvPersonGivenName: string().required('This field is required!').max(255),
        pvPersonSurName: string().required('This field is required!').max(255),
        middleName: string().max(25),
        username: string()
          .required('This field is required!')
          .min(3, 'Must contain at least 3 characters!')
          .max(255)
          .test((value, ctx) => {
            const re = /^\S+$/;
            if (!re.test(value)) {
              return ctx.createError({ message: 'Must not contain blank space!' });
            }
            return true;
          }),
        email: string().email('Not a valid email!').required('This field is required!').max(255),
        password: string()
          .required('This field is required!')
          .matches(
            RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
            'Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number!'
          )
          .min(8, 'Must Container 8 Characters!')
          .max(50),
        confirmPassword: string().oneOf([ref('password')], 'Password must match!'),
        pvOfficePhone: string().nullable().max(255),
        pvMobilePhone: string().nullable().max(255),
        pvOrganizationUnitName: string().required('This field is required!').max(255),
        groupId: number().required('This field is required!').min(1, 'This field is required!'),
        selectOption: array().of(
          object().shape({
            value: number(),
            label: string(),
          })
        ),
      }),
    []
  );

  return useForm<IRequestAccess>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useRequestAccessForm;
