import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { forgotUsername } from 'store/slices/cognito/cognitoThunk';
import useForm, { UserForgot } from './hook/useForgotForm';
import ForgotUsernameView from './ForgotUsername.view';
import { ForgotUsernameProps } from './ForgotUsername.props';
import { ForgotStatusStep as StatusStep } from 'common/enum';

const ForgotUsername = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const forgotForm = useForm();
  const [isRequesting, setIsRequesting] = useState(false);

  const handleBack = () => {
    navigate('/login');
  };
  const onSubmit: SubmitHandler<UserForgot> = async (data) => {
    setIsRequesting(() => true);
    await dispatch(forgotUsername(data.email))
      .unwrap()
      .then((res) => {
        setIsRequesting(() => false);
        if (res && typeof res === 'string') {
          forgotForm.setValue('steps', StatusStep.step3);
        } else {
          forgotForm.setError('email', {
            type: 'manual',
            message: 'No username found!',
          });
        }
      })
      .catch((er) => {
        setIsRequesting(() => false);
        console.log(er);
      });
  };

  const combineProps: ForgotUsernameProps = {
    forgotForm,
    isRequesting,
    handleBack,
    onSubmit,
  };

  return <ForgotUsernameView {...combineProps} />;
};

export default ForgotUsername;
