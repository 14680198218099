import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typo from '@mui/material/Typography';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import useStyles from './StatusComponents.styles';

export const Info: React.FC<{ message: string } & BoxProps> = ({ message, ...props }) => {
  const classes = useStyles();
  return (
    <Box {...props}>
      <Box sx={classes.mainContainer}>
        <Box
          sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InfoIcon fontSize='large' sx={{ color: '#474747' }} />
        </Box>
        <Box sx={classes.msgContainer}>
          <Typo variant='h5' sx={{ color: '#474747' }}>
            {message}
          </Typo>
        </Box>
      </Box>
    </Box>
  );
};
