import { Attributes } from 'interfaces/attribute.interface';
import { defaultAttribute } from '../static';
import { findKeyIndAttribute } from './FindKeyIndexAttribute';
import { SiteStatusCodes } from '../enum';

export function DefineUserAttributesData(modifiedAttributes: Attributes[]): Attributes[] {
  const newAttributes: Attributes[] = [];
  for (const originalObj of defaultAttribute) {
    const appCode = Object.keys(originalObj)[0];
    const index = findKeyIndAttribute(modifiedAttributes, appCode);
    if (index === -1) {
      newAttributes.push(originalObj);
    } else {
      const defaultAttr = originalObj[appCode][0];
      const currAttr = modifiedAttributes[index][appCode][0];
      if (defaultAttr.S === SiteStatusCodes.ACTIVE && defaultAttr.S !== currAttr.S) {
        const copyOfAttr = modifiedAttributes[index][appCode];
        // *: Use the defined default attribute for the app for default ACTIVE status
        copyOfAttr[0] = defaultAttr;
        const newUserAttr = { [appCode]: [...copyOfAttr] };
        newAttributes.push(newUserAttr);
      } else {
        newAttributes.push(modifiedAttributes[index]);
      }
    }
  }
  return newAttributes;
}
