const useStyles = () => ({
  container: {
    height: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    height: 'calc(100vh - 80px)',
    width: '100%',
    overflowY: 'auto',
  },
  page: {
    flex: '1 1 1px',
  },
  modalContainer: {
    height: '548px',
    maxHeight: '548px',
    minWidth: '350px',
    overflowY: 'scroll',
    mt: '50px',
  },
  stepContainer: {
    position: 'absolute',
    bottom: 2,
    left: 0,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    py: 1,
    px: 4,
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'transparent',
  },
});

export default useStyles;
