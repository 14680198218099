import React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ToastStatus } from 'common/enum/ToastStatus';
import Typo from '../Typo';

import useStyles from './ToastMessage.styles';

interface IToastMsg {
  status: ToastStatus;
  message: string;
}

const ToastMessageView: React.FC<IToastMsg> = ({ status, message }) => {
  const classes = useStyles();

  if (status === ToastStatus.LOADING) {
    return (
      <Box>
        <Stack sx={classes.root} direction='row' spacing={2}>
          <CircularProgress size={25} />
          <Typo variant='body1' fontSize={'0.75rem'} fontWeight={600} color='textSecondary'>
            {message}
          </Typo>
        </Stack>
      </Box>
    );
  }

  if (status === ToastStatus.ERROR) {
    return (
      <Box>
        <Stack sx={classes.root} direction='row' spacing={2}>
          <HighlightOffIcon color='error' />
          <Typo variant='body1' fontWeight={600}>
            {message}
          </Typo>
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack sx={classes.root} direction='row' spacing={2}>
        <CheckCircleIcon color='primary' />
        <Typo variant='body1' fontWeight={600}>
          {message}
        </Typo>
      </Stack>
    </Box>
  );
};

export default ToastMessageView;
