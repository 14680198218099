import React from 'react';
import { Controller } from 'react-hook-form';
import { MainLayout } from 'components/layout';
import { Button, Typo, TextField } from 'components/primitives';
import { PreLoaderSnippet } from 'components/snippets';
import { Box, Divider, InputAdornment, Paper, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useStyles from './ResetPassword.styles';
import { ResetPasswordProps } from './ResetPassword.props';
import { ForgotStatusStep as StatusStep } from 'common/enum';

const ResetPasswordView: React.FC<ResetPasswordProps> = ({
  form,
  toggleShowPassword,
  showPassword,
  handleBack,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { control, watch, handleSubmit } = form;
  const steps = watch('steps');

  return (
    <MainLayout>
      {props.isLoading ? (
        <Box sx={classes.root}>
          <PreLoaderSnippet visible={props.isLoading} />
        </Box>
      ) : props.hasError ? (
        <Box sx={classes.root}>
          <Paper variant='outlined' sx={classes.container} style={{ maxHeight: '300px' }}>
            <Box sx={classes.lastStepContainer}>
              <Box sx={classes.iconContainer}>
                <ErrorIcon
                  sx={{ color: theme.palette.error.main, height: '56px', width: '56px' }}
                />
              </Box>
              <Typo fontSize={20} fontWeight={500}>
                Password reset failed
              </Typo>
              <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                Failed to verify the password reset link. Please create a new request or contact
                support.
              </Typo>
              <Divider variant='middle' sx={{ width: '100%' }} />
              <Typo
                fontSize={16}
                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => handleBack()}>
                Return to login
              </Typo>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Box sx={classes.root}>
          <Paper variant='outlined' sx={classes.container} style={{ maxHeight: '300px' }}>
            <Box sx={classes.form} hidden={steps !== StatusStep.step1}>
              <Box sx={classes.title}>
                <Typo fontSize={20} fontWeight={500}>
                  Reset Password
                </Typo>
                <InfoIcon sx={{ color: theme.palette.primary.main }} />
              </Box>
              <Controller
                name='password'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                    placeholder='Enter password'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ cursor: 'pointer', pr: 1 }}
                          position='end'
                          onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name='confirmPassword'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Confirm Password'
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                    placeholder='Re-enter password'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ cursor: 'pointer', pr: 1 }}
                          position='end'
                          onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                fullWidth
                onClick={handleSubmit(props.onSubmit)}
                progress={props.isProcessing}>
                RESET PASSWORD
              </Button>
            </Box>
            <Box sx={classes.lastStepContainer} hidden={steps !== StatusStep.step3}>
              <Box sx={classes.iconContainer}>
                {props.hasFailed ? (
                  <ErrorIcon
                    sx={{ color: theme.palette.error.main, height: '56px', width: '56px' }}
                  />
                ) : (
                  <CheckCircleIcon sx={{ color: 'green', height: '56px', width: '56px' }} />
                )}
              </Box>
              <Typo fontSize={20} fontWeight={500}>
                Password reset {props.hasFailed ? 'failed' : 'sent'}
              </Typo>
              <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                {props.infoMsg}
              </Typo>
              <Divider variant='middle' sx={{ width: '100%' }} />
              <Typo
                fontSize={16}
                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => handleBack()}>
                Return to login
              </Typo>
            </Box>
          </Paper>
          <Typo fontSize={12} sx={{ color: 'rgba(0, 0, 0, 0.54)', mt: '14px' }}>
            All data in palmetto is to be considered FOUO and not for public dissemination
          </Typo>
        </Box>
      )}
    </MainLayout>
  );
};

export default ResetPasswordView;
