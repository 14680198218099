// import { Suspense, lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

// import { PreLoaderSnippet } from 'components/snippets';
import AuthPage from 'auth';
import DashboardPage from 'pages/Dashboard';
import LoginPage from 'pages/Login';
import ContactSupportPage from 'pages/ContactSupport';
import MyAccountPage from 'pages/MyAccount';
import MyAccountEditPage from 'pages/MyAccountEdit';
import UsersListsPage from 'pages/UserLists';
import UserViewPage from 'pages/UserView';
import UserEditPage from 'pages/UserEdit';
import UserPermissionListsPage from 'pages/UserPermissionLists';
import UserPermissionViewPage from 'pages/UserPermissionView';
import UserNewPage from 'pages/UserNew';
import ForgotPasswordPage from 'pages/ForgotPassword';
import ForgotUsernamePage from 'pages/ForgotUsername';
import ResetPasswordPage from 'pages/ResetPassword';
import RequestAccessPage from 'pages/RequestAccess';
import VerifyNewAccountPage from 'pages/VerifyNewAccount';
import ControlPanelListPage from 'pages/ControlPanel/Grid';
import ControlPanelDetailsPage from 'pages/ControlPanel/Details';

const JSXRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<AuthPage />}>
      <Route path='dashboard' element={<DashboardPage />} />
      <Route path='contact-support' element={<ContactSupportPage />} />
      <Route path='user-account'>
        <Route index element={<MyAccountPage />} />
        <Route path=':id' element={<MyAccountEditPage />} />
      </Route>
      <Route path='user'>
        <Route index element={<UsersListsPage />} />
        <Route path='view/:id' element={<UserViewPage />} />
        <Route path='edit/:id' element={<UserEditPage />} />
        <Route path='new' element={<UserNewPage />} />
        <Route path='permission/:id' element={<UserPermissionListsPage />} />
        <Route path='permission/:id/view/:groupId' element={<UserPermissionViewPage />} />
      </Route>
      <Route path='control-panel'>
        <Route index element={<ControlPanelListPage />} />
        <Route path='details/new' element={<ControlPanelDetailsPage newEntry={true} />} />
        <Route path='details/:id' element={<ControlPanelDetailsPage newEntry={false} />} />
      </Route>
      <Route path='login' element={<LoginPage />} />
      <Route path='forgotpassword' element={<ForgotPasswordPage />} />
      <Route path='forgotusername' element={<ForgotUsernamePage />} />
      <Route path='resetpassword' element={<ResetPasswordPage />} />
      <Route path='requestaccess' element={<RequestAccessPage />} />
      <Route path='verifyAccount' element={<VerifyNewAccountPage />} />
      <Route path='*' element={<LoginPage />} />
    </Route>
  )
);

export default JSXRoutes;
