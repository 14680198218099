/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { useTheme, CircularProgress } from '@mui/material';
import { ModalProps } from '@mui/material/Modal';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, Typo, Modal } from 'components/primitives';
import useStyles from './ConfirmationModal.styles';

interface IConfirmationProps {
  noCancel?: boolean;
  loading?: boolean;
  title?: string;
  titleBtnLeft?: string;
  titleBtnRight?: string;
  width?: string;
  onConfirm?: (confirm: boolean) => void;
}

type ConfirmationModalViewProps = IConfirmationProps & ModalProps;

const ConfirmationModal: React.FC<ConfirmationModalViewProps & BoxProps> = ({
  noCancel = false,
  loading = false,
  title = 'Please confirm',
  titleBtnLeft = 'Cancel',
  titleBtnRight = 'Confirm',
  width = '320px !important',
  onConfirm,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, width);

  const onTouchButton = (confirm: boolean) => {
    if (!loading) {
      onConfirm && onConfirm(confirm);
    }
  };

  return (
    <Modal {...props}>
      <>
        <Box sx={classes.content}>
          <Typo variant='h4' fontSize={20} fontWeight={500} mb={2} color='common.black'>
            {title}
          </Typo>
          {props.children}
        </Box>
        <Box sx={classes.footer}>
          {noCancel ? null : !loading ? (
            <Button onClick={() => onTouchButton(false)} alternate fullWidth>
              {titleBtnLeft}
            </Button>
          ) : null}
          <Button
            onClick={() => onTouchButton(true)}
            alternate
            endIcon={loading && <CircularProgress color='inherit' size={16} sx={{ mr: '4px' }} />}
            fullWidth>
            {loading ? 'Please wait' : titleBtnRight}
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
