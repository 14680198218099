import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    minWidth: '320px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      minWidth: '90%',
    },
  },
});

export default useStyles;
