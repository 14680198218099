import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

const useStyles = (theme: Theme) => ({
  container: {
    maxWidth: '480px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
  footer: {
    display: 'flex',
    marginTop: theme.spacing(7),
    columnGap: '10px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '& button': {
        maxWidth: 'unset',
      },
    },
  },
  cancelBtn: {
    color: theme.palette.common.black,
    backgroundColor: grey[300],
    '&:hover': {
      backgroundColor: grey[400],
    },
  },
});

export default useStyles;
