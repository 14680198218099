import { AxiosRequestConfig } from 'axios';
// 'https://klqxulghgd.execute-api.us-east-1.amazonaws.com/prod/public/api'
const nodeEnv = process.env.REACT_APP_NODE_ENV;
const apiProd = 'https://klqxulghgd.execute-api.us-east-1.amazonaws.com/prod/api';
const config: AxiosRequestConfig = {
  baseURL: nodeEnv === undefined ? apiProd : process.env.REACT_APP_SLS_LOOPBACK_API,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json',
};

export default config;
