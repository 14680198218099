import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store/index';
import { deleteGroup, getGroupById, saveGroupInfo } from 'services/controlPanelEnpoint';
import apiFetch from 'services/apiFetch';
import { IGroupContact, IGroupList, IGroupWithContacts } from 'interfaces/groups.interface';

export const fetchSelectedGroup = createAsyncThunk(
  'FETCH_SELECTED_GROUP',
  async (id: number, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch as AppDispatch;
      const endpoint = getGroupById(id);
      return await apiFetch<{ dataset: IGroupContact[] }>(endpoint, store, dispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('FETCH_SELECTED_GROUP catch:', error);
      throw Error(message);
    }
  }
);

export const postGroupInfo = createAsyncThunk(
  'POST_GROUP_INFO',
  async (data: Partial<IGroupWithContacts>, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const endpoint = saveGroupInfo(data);
      return await apiFetch<IGroupList>(endpoint, store, thunkAPI.dispatch as AppDispatch).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('POST_GROUP_INFO catch:', error);
      throw Error(message);
    }
  }
);

export const voidGroup = createAsyncThunk('VOID_GROUP', async (id: number, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const endpoint = deleteGroup(id);
    return await apiFetch<{ message: string }>(
      endpoint,
      store,
      thunkAPI.dispatch as AppDispatch
    ).then((res) => res?.data || null);
  } catch (error) {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('VOID_GROUP catch:', error);
    throw Error(message);
  }
});
