import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUserEmptyState, ICurrentUserState } from './currentUserState';
import { fetchCurrentUserInfo, updateUserActiveGroup } from './currentUserThunk';
import { IUserPortal } from 'interfaces/userinfo.interface';
import { ProgramCodes, AccessPermission } from 'common/enum';
import { DefineUserAttributesData } from 'common/utils';

const initialState: ICurrentUserState = CurrentUserEmptyState;

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    updateCurrentUser: (state, action: PayloadAction<Partial<IUserPortal>>) => {
      state.userAccount = action.payload;
    },
    updateAppCode: (state, action: PayloadAction<ProgramCodes | string>) => {
      state.appCode = action.payload;
    },
    updateLEMPGAuth: (state, action: PayloadAction<boolean>) => {
      state.LEMPG_Auth = action.payload;
    },
    updateSUPPAuth: (state, action: PayloadAction<boolean>) => {
      state.SUPP_Auth = action.payload;
    },
    updateAccessPermission: (state, action: PayloadAction<AccessPermission>) => {
      state.accessPermission = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchCurrentUserInfo.fulfilled, (state, action) => {
        const SelectedUser = action.payload;
        SelectedUser.attributes = DefineUserAttributesData(SelectedUser?.attributes || []);
        state.loading = false;
        state.userAccount = SelectedUser;
      })
      .addCase(fetchCurrentUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(updateUserActiveGroup.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateUserActiveGroup.fulfilled, (state) => {
        state.isSaving = false;
      });
  },
});

export const {
  updateCurrentUser,
  updateAppCode,
  updateLEMPGAuth,
  updateSUPPAuth,
  updateAccessPermission,
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
