import { Nullable } from 'utils/Nullable';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { UserLogin } from './hooks/useLoginForm';

export enum urlPathForgot {
  forgotPassword = '/forgotpassword',
  forgotUsername = '/forgotusername',
  requestAccess = '/requestaccess',
}

interface ILoginProps {
  isResetPassword: boolean;
  inProcess: boolean;
  isChanging: boolean;
  noSpaceModal: boolean;
  successChangeModal: boolean;
  errMessage: Nullable<string> | undefined;
  errorStatusMsg: Nullable<string> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginForm: UseFormReturn<UserLogin, any>;
  onSubmit: SubmitHandler<UserLogin>;
  onConfirm: (confirm: boolean) => void;
  multiUserModal: boolean;
  onMultiUserConfirm: () => void;
  onSuccessConfirm: () => void;
  navigateTo: (path: urlPathForgot) => void;
}

export type LoginProps = ILoginProps;
