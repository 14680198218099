/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import useStyles from './BottomTabs.styles';
import { TabName } from 'store/slices/menu/menuState';

interface BottomTabsProps {
  // add your custom props here
  options: {
    id: TabName;
    title: string;
    icon: string | React.ReactElement;
  }[];
  selectedItem: TabName;
  setSelectedItem: React.Dispatch<React.SetStateAction<any>>;
  onChange: (value: TabName) => void;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<any>>;
}

const BottomTabs: React.FC<BottomTabsProps> = ({ ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const tempOpts = props.options;
    for (let i = 0; i < tempOpts.length; i++) {
      if (tempOpts[i].id === props.selectedItem) {
        if (selectedIndex !== i) {
          setSelectedIndex(i);
        }
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedItem, props.options]);

  const handleChange = (idx: number) => {
    setSelectedIndex(() => idx);
    props.setIndex(idx);
    props.onChange(props.options[idx].id);
  };
  return (
    <Box sx={classes.root}>
      <Tabs sx={classes.tabs} value={selectedIndex} variant='fullWidth' indicatorColor='primary'>
        {props.options.map((item, idx) => {
          return (
            <Tab
              key={item.id}
              iconPosition='start'
              label={item.title}
              icon={item.icon}
              onClick={() => handleChange(idx)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default BottomTabs;
