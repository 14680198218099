import { ProgramCodes } from '../enum';

function findKeyIndAttribute(arr: unknown[], appCode: ProgramCodes | string) {
  if (Array.isArray(arr) && arr.length) {
    let indexValue = -1;
    for (let i = 0; i < arr.length; i++) {
      const value = arr[i];
      if (typeof value === 'object') {
        const objKeys = Object.keys(value);
        const checkCode = objKeys.includes(appCode);
        if (checkCode) {
          indexValue = i;
        }
      }
    }
    return indexValue;
  } else {
    return -1;
  }
}

export { findKeyIndAttribute };
