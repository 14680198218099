import { Theme } from '@mui/material';
const useStyles = (theme: Theme) => ({
  wrapper: {
    position: 'relative',
    flex: '1 1',
    flexBasis: '1e-9px',
    minWidth: '250px',
    marginLeft: '6px',
    marginRight: '6px',
    marginBottom: '6px',
    minHeight: '56px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset !important',
      width: '100%',
    },
  },
  selectWrapper: {
    minWidth: '250px',
    minHeight: '33px',
    maxHeight: '250px',
    fontSize: '16px',
    width: '100%',
  },
});

export default useStyles;
