import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material';

const Typo: React.FC<
  { variant?: TypographyProps['variant'] & SxProps<Theme> } & TypographyProps
> = ({ variant, ...props }) => {
  return (
    <Typography {...props} variant={variant}>
      {props.children}
    </Typography>
  );
};

export default Typo;
