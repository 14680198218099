import React from 'react';
import { Controller } from 'react-hook-form';
import { MainLayout } from 'components/layout';
import { Button, Typo, FormGroup, TextField } from 'components/primitives';
import { RadioGroup, Radio, FormControlLabel, Box, Divider, Paper, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import useStyles from './ForgotPassword.styles';
import { ForgotPasswordProps } from './ForgotPassword.props';
import { ForgotStatusStep as StatusStep } from 'common/enum';

const ForgotPasswordView: React.FC<ForgotPasswordProps> = ({
  handleBack,
  forgotForm,
  ...props
}) => {
  const { control, handleSubmit, watch } = forgotForm;
  const steps = watch('steps');
  const usernamesList = watch('usernamesList') || [];
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MainLayout>
      <Box sx={classes.root}>
        <Paper
          variant='outlined'
          sx={classes.container}
          style={{
            maxHeight: `${
              steps === StatusStep.step1 ? '320px' : steps === StatusStep.step2 ? '462px' : '300px'
            }`,
          }}>
          <Box
            sx={classes.iconContainer}
            onClick={() => handleBack()}
            hidden={steps === StatusStep.step3}>
            <ArrowBackIcon />
          </Box>
          <Box sx={classes.form} hidden={steps !== StatusStep.step1}>
            <Typo fontSize={20} fontWeight={500}>
              Reset password
            </Typo>
            <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {`Enter the email address associated with your account. We'll send you a link to reset
                your password.`}
            </Typo>
            <Controller
              name='email'
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  error={Boolean(error)}
                  id='email'
                  label='Email address'
                  placeholder={'Enter email address'}
                  style={{ width: '100%' }}
                  value={value}
                  onChange={onChange}
                  errMsg={error}
                />
              )}
            />
            <Button fullWidth onClick={handleSubmit(props.onSubmit)} progress={props.isProcessing}>
              SUBMIT
            </Button>
          </Box>
          <Box sx={classes.form} hidden={steps !== StatusStep.step2}>
            <Typo fontSize={20} fontWeight={500}>
              Select Account
            </Typo>
            <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {`There are multiple accounts associated with this email address. Select username to reset that account's password.`}
            </Typo>
            <Box sx={classes.radioGrpContainer}>
              <Controller
                control={control}
                name='username'
                render={({ field: { onChange, value } }) => (
                  <FormGroup label='Select usernames' sx={{ mx: '6px' }}>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value}
                      onChange={onChange}>
                      {usernamesList.length
                        ? usernamesList.map((u) => (
                            <FormControlLabel
                              key={u.value}
                              value={u.value}
                              control={<Radio />}
                              label={<Typo style={{ fontSize: 14 }}>{u.label}</Typo>}
                            />
                          ))
                        : null}
                    </RadioGroup>
                  </FormGroup>
                )}
              />
            </Box>
            <Button fullWidth onClick={() => props.handleResetLink()} progress={props.isProcessing}>
              SEND RESET LINK
            </Button>
          </Box>
          <Box sx={classes.lastStepContainer} hidden={steps !== StatusStep.step3}>
            <Box sx={classes.iconContainer}>
              {props.hasFailed ? (
                <ErrorIcon
                  sx={{ color: theme.palette.error.main, height: '56px', width: '56px' }}
                />
              ) : (
                <CheckCircleIcon sx={{ color: 'green', height: '56px', width: '56px' }} />
              )}
            </Box>
            <Typo fontSize={20} fontWeight={500}>
              Password reset {props.hasFailed ? 'failed' : 'sent'}
            </Typo>
            <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {props.infoMsg}
            </Typo>
            <Divider variant='middle' sx={{ width: '100%' }} />
            <Typo
              fontSize={16}
              sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={() => handleBack()}>
              Return to login
            </Typo>
          </Box>
        </Paper>
        <Typo fontSize={12} sx={{ color: 'rgba(0, 0, 0, 0.54)', mt: '14px' }}>
          All data in palmetto is to be considered FOUO and not for public dissemination
        </Typo>
      </Box>
    </MainLayout>
  );
};

export default ForgotPasswordView;
