import { Theme } from '@mui/material';

const useStyles = (theme: Theme, isActive: boolean, disabled?: boolean) => ({
  container: {
    display: 'flex',
    marginLeft: '0px',
    pr: '12px',
    borderRadius: '4px',
    border: `1px solid ${
      isActive ? theme.palette.primary.main : disabled ? theme.palette.secondary.main : '#C2C2C2'
    }`,
    background: isActive
      ? theme.palette.common.white
      : disabled
      ? theme.palette.secondary.main
      : theme.palette.common.white,
    opacity: 1,
    flex: 1,
    flexWrap: 'nowrap',
    gap: '8px',
    width: '100%',
  },
  label: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '2px',
    },
  },
});

export default useStyles;
