import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  content: {
    minWidth: '320px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
  txtInput: {
    margin: '24px 0',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    columnGap: '10px',
    '& button': {
      maxWidth: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '& button': {
        maxWidth: 'unset',
      },
    },
  },
});

export default useStyles;
