import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { verifyResetToken, resetForgotPassword } from 'store/slices/cognito/cognitoThunk';
import { selectLoading, selectError } from 'store/slices/cognito/cognito.selector';
import useResetPasswordForm, { IResetPassword } from './hooks/useResetPasswordForm';
import ResetPasswordView from './ResetPassword.view';
import { ResetPasswordProps } from './ResetPassword.props';
import { ForgotStatusStep as StatusStep } from 'common/enum';

const successMessage =
  'We sent you a link to reset your account password. Check your inbox for further details.';
const errorMessage = 'We experience a technical issue. Please try again later or contact support.';

const ResetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const form = useResetPasswordForm();
  const isLoading = useAppSelector(selectLoading);
  const hasError = useAppSelector(selectError) !== null;
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [infoMsg, setInfoMsg] = useState(successMessage);
  const [hasFailed, setHasFailed] = useState(false);
  const accessToken = searchParams.get('access_token');
  const userId = searchParams.get('userId');

  useEffect(() => {
    if (accessToken && userId) {
      dispatch(verifyResetToken({ accessToken, userId: Number(userId) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, userId]);

  const handleBack = () => {
    window.location.href = '/login';
  };

  const onSubmit: SubmitHandler<IResetPassword> = async (data) => {
    try {
      setIsProcessing(() => true);
      if (accessToken && userId) {
        await dispatch(
          resetForgotPassword({ password: data.password, userId: Number(userId), accessToken })
        )
          .unwrap()
          .then((res) => {
            setIsProcessing(() => false);
            if (res) {
              navigate('/login?resetPassword=true');
            } else {
              setInfoMsg(errorMessage);
              setHasFailed(true);
              form.setValue('steps', StatusStep.step3);
            }
          });
      }
    } catch (error) {
      console.log(error);
      setIsProcessing(() => false);
      setInfoMsg(errorMessage);
      setHasFailed(true);
      form.setValue('steps', StatusStep.step3);
    }
  };

  const combineProps: ResetPasswordProps = {
    infoMsg,
    hasError,
    hasFailed,
    isProcessing,
    isLoading,
    showPassword,
    form,
    handleBack,
    onSubmit,
    toggleShowPassword: () => setShowPassword(!showPassword),
  };
  return <ResetPasswordView {...combineProps} />;
};

export default ResetPassword;
