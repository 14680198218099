import { IPalmettoColumns } from './interfaces';
import EditIcon from '@mui/icons-material/Edit';

const RenderPermission = (isEditable: unknown) => {
  if (isEditable) return <EditIcon />;
  return '';
};

const UserPermissionTableColumns: IPalmettoColumns[] = [
  {
    flex: 1,
    field: 'group',
    headerName: 'Group',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'positions',
    headerName: 'Positions',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'access',
    headerName: 'Site Access',
    numeric: false,
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'isEditable',
    headerName: ' ',
    numeric: false,
    enabled: true,
    minWidth: 50,
    maxWidth: 50,
    renderCell: (row) => RenderPermission(row.value),
  },
];

export default UserPermissionTableColumns;
