import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserAccount } from 'store/slices/currentUser/currentUser.selector';
import { fetchCurrentUserInfo, updateUser } from 'store/slices/currentUser/currentUserThunk';
import { cognitoChangePassword } from 'store/slices/cognito/cognitoThunk';
import { updateCurrentUser } from 'store/slices/currentUser/currentUserSlice';
import useMyAccountForm, { IMyAccountFormData } from './hook/useMyAccountForm';
import MyAccountEditView from './MyAccountEdit.view';
import { MyAccountEditProps } from './MyAccountEdit.props';
import { DefineUserAttributesData } from 'common/utils';

const MyAccountEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRecord = useAppSelector(selectUserAccount);
  const form = useMyAccountForm();
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [inProcess, setInProcess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (userRecord) {
      const userForm: IMyAccountFormData = {
        id: userRecord.id,
        pvPersonGivenName: userRecord.pvPersonGivenName ? userRecord.pvPersonGivenName : '',
        pvPersonSurName: userRecord.pvPersonSurName ? userRecord.pvPersonSurName : '',
        username: userRecord.username ? userRecord.username : '',
        email: userRecord.email ? userRecord.email : '',
        pvOrganizationUnitName: userRecord.pvOrganizationUnitName
          ? userRecord.pvOrganizationUnitName
          : '',
        pvOfficePhone: userRecord.pvOfficePhone ? userRecord.pvOfficePhone : '',
        pvMobilePhone: userRecord.pvMobilePhone ? userRecord.pvMobilePhone : '',
        password: '',
        confirmPassword: '',
      };

      form.reset(userForm);
    }
  }, [form, userRecord]);

  const handleBack = () => {
    // navigate(-1);
    setOpenConfirmation(true);
  };

  const handleConfirmation = (confirm: boolean) => {
    if (confirm) {
      console.log('handle onSubmit');
      const data = form.getValues();
      form.trigger().then((valid) => {
        if (valid) {
          onSubmit(data);
        } else {
          setOpenConfirmation(false);
        }
      });
      // setOpenConfirmation(false);
    } else {
      navigate(-1);
    }
  };

  const handleUpdateUser = async (data: Partial<IMyAccountFormData>) => {
    try {
      return await dispatch(updateUser(data)).unwrap();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleChangePassword = async (newPassword: string) => {
    try {
      return await dispatch(cognitoChangePassword(newPassword)).unwrap();
    } catch (error) {
      console.log('error >>>', error);
      // localStorage.clear();
      throw error;
    }
  };

  const onSubmit: SubmitHandler<IMyAccountFormData> = (data) => {
    const payload: Partial<IMyAccountFormData> = data;
    delete payload.confirmPassword;
    if (payload.id) {
      if (payload.password && payload.password.length) {
        const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
        if (!pattern.test(payload.password)) {
          form.setError('password', {
            message: 'Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number!',
          });
          return;
        }
        setInProcess(true);
        // ?: Call Change Password Cognito with Change User Record API
        const newPassword = payload.password;
        handleUpdateUser(data)
          .then(() => {
            if (payload.id)
              dispatch(fetchCurrentUserInfo(payload.id))
                .unwrap()
                .then((userRecord) => {
                  if (userRecord && userRecord.id && userRecord.attributes) {
                    const copyUserRecord = {
                      ...userRecord,
                      attributes: DefineUserAttributesData(userRecord.attributes),
                    };
                    dispatch(updateCurrentUser(copyUserRecord));
                  }
                });
            handleChangePassword(newPassword).then(() => {
              navigate('/user-account');
            });
          })
          .catch((e) => {
            console.error('change user error >>>', e);
          })
          .finally(() => {
            setInProcess(false);
          });
      } else {
        setInProcess(true);
        // ?: Change User Record API
        handleUpdateUser(data)
          .then(async () => {
            if (payload.id) {
              await dispatch(fetchCurrentUserInfo(payload.id))
                .unwrap()
                .then((userRecord) => {
                  if (userRecord && userRecord.id && userRecord.attributes) {
                    const copyUserRecord = {
                      ...userRecord,
                      attributes: DefineUserAttributesData(userRecord.attributes),
                    };
                    dispatch(updateCurrentUser(copyUserRecord));
                  }
                })
                .finally(() => {
                  navigate('/user-account');
                });
            }
            setInProcess(false);
          })
          .catch((e) => {
            console.error('change user error >>>', e);
            setInProcess(false);
          });
      }
    }
  };

  const combineProps: MyAccountEditProps = {
    form,
    openConfirmation,
    inProcess,
    showPassword,
    onSubmit,
    handleConfirmation,
    handleBack,
    toggleShowPassword: () => setShowPassword(!showPassword),
  };

  return <MyAccountEditView {...combineProps} />;
};

export default MyAccountEdit;
