import React from 'react';
import { Controller } from 'react-hook-form';
import { HeaderComponent, HeaderButton } from '@zawarski/palmetto-ui-components';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { MainLayout } from 'components/layout';
import { Typo, TextField } from 'components/primitives';
import { ConfirmationModal } from 'components/modal';
import { MyAccountEditProps } from './MyAccountEdit.props';
import { PhoneNumberFormat } from 'common/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const MyAccountEditView: React.FC<MyAccountEditProps> = ({
  handleBack,
  form,
  showPassword,
  toggleShowPassword,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { control } = form;

  // const triggerSubmit = () => form.handleSubmit(props.onSubmit);

  const onConfirmModal = (confirm: boolean) => {
    props.handleConfirmation(confirm);
  };

  const handlePhoneChange =
    (onChange: (value: string | undefined | null) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(PhoneNumberFormat(event.target.value));
    };

  return (
    <MainLayout>
      <>
        <HeaderComponent
          icon={
            <IconButton onClick={handleBack}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          }
          title={<Typo variant='h4'>Edit Account</Typo>}
          suffix={
            props.inProcess ? (
              <HeaderButton style={{ fontSize: 12 }}>
                <CircularProgress sx={{ color: 'white' }} size={24} />
              </HeaderButton>
            ) : (
              <HeaderButton style={{ fontSize: 16 }} onClick={form.handleSubmit(props.onSubmit)}>
                SAVE
              </HeaderButton>
            )
          }
        />
        <Box sx={{ px: 4, py: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name='pvPersonGivenName'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='First name'
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='pvPersonSurName'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Last name'
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='username'
                control={control}
                render={({ field: { value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Username'
                    value={value}
                    inputProps={{ readOnly: true }}
                    errMsg={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='email'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Email address'
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='pvOfficePhone'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Work Phone'
                    value={PhoneNumberFormat(value)}
                    onChange={handlePhoneChange(onChange)}
                    errMsg={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='pvMobilePhone'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Cell Phone'
                    value={PhoneNumberFormat(value)}
                    onChange={handlePhoneChange(onChange)}
                    errMsg={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='pvOrganizationUnitName'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Organization'
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typo fontWeight={600} color={theme.palette.primary.dark}>
                CREDENTIALS
              </Typo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='password'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Password'
                    type={!showPassword ? 'password' : 'text'}
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ cursor: 'pointer', pr: 1 }}
                          position='end'
                          onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='confirmPassword'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    label='Confirm password'
                    type={!showPassword ? 'password' : 'text'}
                    value={value}
                    onChange={onChange}
                    errMsg={error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ cursor: 'pointer', pr: 1 }}
                          position='end'
                          onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <ConfirmationModal
          width={isMobile ? 'unset' : '400px !important'}
          open={props.openConfirmation}
          title='Save Changes'
          loading={props.inProcess}
          titleBtnLeft='DONT SAVE'
          titleBtnRight='SAVE'
          onConfirm={onConfirmModal}>
          <Typo color='common.black'>
            If you leave now, any unsaved changes will be lost. Do you wish to save your changes
            before leaving?
          </Typo>
        </ConfirmationModal>
      </>
    </MainLayout>
  );
};

export default MyAccountEditView;
