import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUserAccount, selectAppCode } from 'store/slices/currentUser/currentUser.selector';
import { useTheme, CircularProgress } from '@mui/material';
import { ModalProps } from '@mui/material/Modal';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, Typo, Modal, TextField } from 'components/primitives';
import { ProgramCodes } from 'common/enum';
import useStyles from './RequestModal.styles';

interface IRequestModalProps {
  loading?: boolean;
  title: string;
  withDetails?: boolean;
  detailsValue?: string;
  onConfirm?: (confirm: boolean, id: number, appCode: ProgramCodes | string) => void;
  onChangeDetails?: (value: string) => void;
}

type RequestModalProps = IRequestModalProps & ModalProps;

const RequestModal: React.FC<RequestModalProps & BoxProps> = ({
  loading = false,
  withDetails = false,
  detailsValue = '',
  title = 'Please confirm',
  onConfirm,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const currentUser = useAppSelector(selectUserAccount);
  const userId = (currentUser && currentUser.id) || 0;
  const selectedAppCode = useAppSelector(selectAppCode);

  const onTouchButton = (confirm: boolean) => {
    if (!loading) {
      onConfirm && selectedAppCode && onConfirm(confirm, userId, selectedAppCode);
    }
  };

  const handleChangeDetails = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    if (value) props.onChangeDetails && props.onChangeDetails(value);
  };

  return (
    <Modal {...props}>
      <>
        <Box sx={classes.content}>
          <Typo variant='body1' fontSize={20} fontWeight={500} mb={2} color='common.black'>
            {title}
          </Typo>
          {props.children}
          {withDetails ? (
            <TextField
              sx={classes.txtInput}
              multiline
              rows={3}
              maxRows={4}
              value={detailsValue}
              onChange={handleChangeDetails}
              label='Reason for request'
              placeholder='Please add a new reason here'
            />
          ) : null}
        </Box>
        <Box sx={classes.footer}>
          {!loading && (
            <Button onClick={() => onTouchButton(false)} alternate>
              CANCEL
            </Button>
          )}
          <Button
            sx={{ pr: loading ? 5 : 2 }}
            onClick={() => onTouchButton(true)}
            endIcon={loading && <CircularProgress color='inherit' size={16} sx={{ mr: '4px' }} />}>
            {loading ? 'Please wait' : 'SUBMIT'}
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default RequestModal;
