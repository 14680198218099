import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enum/AuthProtect';
import {
  AssignGroupAdminPayload,
  IAssignGroupPayload,
  IRevokeGroupPayload,
} from 'interfaces/groups.interface';
import { IPositionsPayload } from 'interfaces/positions.interface';
import { PayloadAttribute } from 'interfaces/attribute.interface';

export const getPositionsByID = (id: number): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/group/positions/${id}`,
  };
};

export const getGroups = (): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: '/groups',
  };
};

export const addToGroup = (data: IAssignGroupPayload): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/user/group',
    data,
  };
};

export const updateUserPositions = (data: IPositionsPayload): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/user/positions',
    data,
  };
};

export const removeUserToGroup = (data: IRevokeGroupPayload): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: '/user/group',
    data,
  };
};

export const updateSiteAccess = (data: PayloadAttribute): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/user/attribute',
    data,
  };
};

export const updateGroupAdmin = (data: AssignGroupAdminPayload): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/user/group/admin',
    data,
  };
};
