import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import { selectJWTToken } from 'store/slices/auth/auth.selector';
import { findIndex } from 'lodash';
import { MainLayout } from 'components/layout';
import { Box, IconButton, Paper, styled, useTheme, useMediaQuery } from '@mui/material';
import { PalmettoReactTable } from '@zawarski/palmetto-direflow-react-table';
import { HeaderComponent, PaperFabButton } from '@zawarski/palmetto-ui-components';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UsersPermissionTableColumns } from 'common/tables';
import { BottomTabs, Button, Typo, InfoComponent } from 'components/primitives';
import { AssignGroupModal } from 'components/modal';
import { SelectGroup, SelectPosition } from 'components/fragments';
import { StepProgress } from 'components/snippets';
import useStyles from './UserPermissionLists.styles';
import { UserPermissionListsProps } from './UserPermissionLists.props';
import { AssignGroupFormStep } from 'common/enum';
import { UserGroupFormData } from 'hooks/useGroupForm';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import usePageBar from 'hooks/usePageBar';

const CustomFab = styled(PaperFabButton)({
  bottom: '80px !important',
});

const UserPermissionListsView: React.FC<UserPermissionListsProps> = ({
  groupForm,
  step,
  stepHistory,
  onRowSelect,
  navigateBack,
  permissionsList,
  fetchingPositions,
  groupLists,
  userAccount,
  ...props
}) => {
  const pageBar = usePageBar('permission', props.userId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const accessToken = useAppSelector(selectJWTToken);
  const classes = useStyles();
  const stepIndex = findIndex(stepHistory, { step });
  const stepInfo = stepHistory[stepIndex];
  const lastStep = step === AssignGroupFormStep.POSITION;
  const hasGroupID = groupForm.watch('groupId') > 0;
  const fullName =
    (userAccount &&
      `${userAccount.pvPersonGivenName} ${
        userAccount.pvPersonSurName ? userAccount.pvPersonSurName : ''
      }`) ||
    'View Groups';

  const handleSubmit: SubmitHandler<UserGroupFormData> = () => {
    props.onSubmit();
  };
  const onNextStep: SubmitHandler<UserGroupFormData> = () => {
    if (lastStep) props.onSubmit();
    else {
      const index = findIndex(stepHistory, { step: stepInfo?.step });
      const nextStep = stepHistory[index + 1];
      if (nextStep) {
        props.handleChangeStep(nextStep.step as AssignGroupFormStep);
      }
    }
  };

  const onBackStep = () => {
    const index = findIndex(stepHistory, { step: stepInfo?.step });
    const backStep = stepHistory[index - 1];
    props.handleChangeStep(backStep.step as AssignGroupFormStep);
  };

  const mobileCardContent = (data: IPermissionList) => {
    return data && data.id ? (
      <Box sx={classes.mobileContainer} key={data.id} onClick={() => props.onMobileRowSelect(data)}>
        <Box sx={classes.cardContainer}>
          <Box sx={classes.cardTitle}>
            <Typo fontWeight={700} fontSize='1rem'>
              {data?.group}
            </Typo>
          </Box>
          <Box sx={classes.cardTitle} style={{ opacity: 0.54 }}>
            <Typo fontWeight={600} fontSize='14px'>
              {data?.positions}
            </Typo>
          </Box>
          <Box sx={classes.cardTitle} style={{ opacity: 0.54 }}>
            <Typo fontWeight={500} fontSize='14px'>
              {data?.access}
            </Typo>
          </Box>
        </Box>
      </Box>
    ) : null;
  };

  return (
    <MainLayout>
      <div className='layout vertical full-height'>
        <Box sx={{ display: 'block', width: '100%', height: '100vh' }}>
          {isMobile && permissionsList.length > 0 ? (
            <>
              <HeaderComponent
                icon={
                  <IconButton onClick={navigateBack}>
                    <ArrowBackIcon sx={{ color: 'white' }} />
                  </IconButton>
                }
                title={
                  <Typo variant='h5' fontWeight={500}>
                    {fullName}
                  </Typo>
                }
              />
              <Box sx={classes.listContainer}>
                {!!permissionsList.length && permissionsList.map((data) => mobileCardContent(data))}
              </Box>
            </>
          ) : permissionsList.length ? (
            <PalmettoReactTable
              auth={{
                accessToken,
              }}
              title={
                <Typo variant='h5' fontWeight={500}>
                  {fullName}
                </Typo>
              }
              headerIcon={
                <IconButton onClick={navigateBack}>
                  <ArrowBackIcon sx={{ color: 'white' }} />
                </IconButton>
              }
              hideFilter
              columns={UsersPermissionTableColumns}
              dataset={permissionsList}
              idField='id'
              limit={35}
              onRowClick={onRowSelect}
              extraHeaders={<></>}
              rowHeight={48}
              noHelp={true}
            />
          ) : (
            <>
              <HeaderComponent
                icon={
                  <IconButton onClick={navigateBack}>
                    <ArrowBackIcon sx={{ color: 'white' }} />
                  </IconButton>
                }
                title={
                  <Typo variant='h5' fontWeight={500}>
                    {fullName}
                  </Typo>
                }
              />
              <div className='flex layout vertical full-height vertical-center'>
                <InfoComponent
                  message={'User has no assigned Group.'}
                  className='layout vertical-center'
                />
              </div>
            </>
          )}
          <CustomFab hidden={false} onClick={props.handleModal}>
            <AddIcon className='icon' />
          </CustomFab>
          <BottomTabs {...pageBar} />
        </Box>
        <AssignGroupModal open={props.openAssignGroup} handleModal={props.handleModal}>
          <Box sx={classes.modalContainer}>
            <FormProvider {...groupForm}>
              <SelectGroup hidden={step !== AssignGroupFormStep.GROUP} groupLists={groupLists} />
              <SelectPosition
                sx={{ mb: '75px' }}
                hidden={step !== AssignGroupFormStep.POSITION}
                rootName={'positions'}
              />
            </FormProvider>
            <Paper elevation={0} variant='outlined' sx={classes.stepContainer}>
              {props.hasNoPositions ? null : <StepProgress stepIndex={stepIndex} />}
              <Box sx={classes.btnGroup}>
                {props.hasNoPositions ? (
                  <Button
                    sx={{ minWidth: '150px' }}
                    progress={props.isProcessing || props.fetchingUsers}
                    disabled={fetchingPositions || !hasGroupID}
                    onClick={groupForm.handleSubmit(handleSubmit)}>
                    Submit
                  </Button>
                ) : (
                  <>
                    <Button
                      alternate
                      sx={{ minWidth: '150px' }}
                      disabled={!lastStep}
                      onClick={() => onBackStep()}>
                      Back
                    </Button>
                    <Button
                      sx={{ minWidth: '150px' }}
                      progress={props.isProcessing || props.fetchingUsers}
                      disabled={fetchingPositions || !hasGroupID}
                      onClick={groupForm.handleSubmit(onNextStep)}>
                      {lastStep ? 'Submit' : 'Next'}
                    </Button>
                  </>
                )}
              </Box>
            </Paper>
          </Box>
        </AssignGroupModal>
      </div>
    </MainLayout>
  );
};

export default UserPermissionListsView;
