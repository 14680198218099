import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateJWTToken } from 'store/slices/auth/authSlice';
import { getTokenExpiration, refreshAccessToken } from 'common/utils/getTokenExpiration';

const RefreshTokenTimer = () => {
  const dispatch = useAppDispatch();
  const cognitoUser = useAppSelector((state) => state.cognito.cognitoUser);

  useEffect(() => {
    const refreshToken = async () => {
      const currentTimestamp = Math.floor(Date.now() / 1000); // ?:Current timestamp in seconds
      if (cognitoUser) {
        const tokenSession = cognitoUser && JSON.parse(cognitoUser);
        const tokenExpiration = getTokenExpiration(tokenSession) || 0; // *: Cognito Default exp 60min.
        const difference = tokenExpiration - currentTimestamp;
        console.log('~ REFRESHER HOOK Expiration Time in seconds: ', difference);
        if (difference <= 1800) {
          // ?: If token is about to expire in 30 minutes, refresh it
          const session = await refreshAccessToken();
          if (session && session?.getIdToken().getJwtToken()) {
            dispatch(updateJWTToken(session?.getIdToken().getJwtToken()));
          }
        }
      }
      return null;
    };
    // Set the interval for 20 minutes
    const intervalDuration = 20 * 60 * 1000; // 20 minutes in milliseconds

    const interval = setInterval(() => {
      refreshToken().finally();
    }, intervalDuration);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser]);
  return null;
};

export default RefreshTokenTimer;
