import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectUserAccount } from 'store/slices/currentUser/currentUser.selector';
import { fetchCurrentUserInfo } from 'store/slices/currentUser/currentUserThunk';
import MyAccountView from './MyAccount.view';
import { MyAccountViewProps } from './MyAccountView.props';

const MyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUserAccount);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.id &&
      !Object.prototype.hasOwnProperty.call(currentUser, 'account2position')
    ) {
      dispatch(fetchCurrentUserInfo(currentUser.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const navigateTo = () => {
    if (currentUser && currentUser.id) {
      navigate('/user-account/' + currentUser.id);
    }
  };

  const combineProps: MyAccountViewProps = {
    navigateTo,
  };

  return <MyAccountView {...combineProps} />;
};

export default MyAccount;
