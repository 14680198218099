import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  content: {
    width: '288px',
    height: '500px',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '56px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
});

export default useStyles;
