import React, { useRef, KeyboardEvent, useState } from 'react';
import { Controller } from 'react-hook-form';
import useMedia, { MOBILE_SIZE } from 'hooks/useMediaQuery';
import { MainLayout } from 'components/layout';
import { Button, TextField, Typo } from 'components/primitives';
import { ConfirmationModal, NoSpaceModal } from 'components/modal';
import { Box, FormHelperText, useTheme } from '@mui/material';
import { Info } from '@mui/icons-material';
import { Img, useStyles } from './Login.styles';
import Logo from 'assets/img.png';
import 'theme/styles/common.css';
import '@zawarski/palmetto-ui-components/dist/styles/common.login.css';
import { NormalTextField } from '@zawarski/palmetto-ui-components';
import { LoginProps, urlPathForgot } from './Login.props';

const LoginView: React.FC<LoginProps> = ({
  onSubmit,
  errMessage,
  inProcess,
  noSpaceModal,
  successChangeModal,
  ...props
}) => {
  const { isMobileDevice, windowDimensions } = useMedia();
  const isMobile =
    isMobileDevice || (windowDimensions?.width !== null && windowDimensions.width < MOBILE_SIZE);
  const { control, handleSubmit, getValues } = props.loginForm;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isPasswordActive, setIsPasswordActive] = useState(false);
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const passwordInfo = getValues('password');
    if (event.code === 'Enter') {
      // Prevent the default behavior of the Enter key (e.g., form submission)
      event.preventDefault();
      // *: If the password field is not empty, submit the form
      if (passwordInfo.length) {
        return handleSubmit(onSubmit)();
      }
      // Focus on the next input element
      if (!isPasswordActive) {
        if (inputRef2.current) {
          inputRef2.current.focus();
          setIsPasswordActive(true);
        }
      } else {
        handleSubmit(onSubmit)();
      }
    }
  };

  return (
    <MainLayout
      footer={
        <Box sx={classes.footer}>
          <Typo variant='body1' textAlign='center' color='black'>
            All data in this app is to be considered FOUO and not for public dissemination
          </Typo>
        </Box>
      }>
      <div className='layout vertical center vertical-center full-height'>
        <NoSpaceModal open={noSpaceModal} onConfirm={props.onConfirm} loading={props.isChanging}>
          <Box sx={classes.modalTitle}>
            <Info sx={{ color: '#C2C2C2' }} fontSize={'large'} />
            <Typo variant='body1' fontSize={24} fontWeight={600} color='common.black'>
              Username changed required!
            </Typo>
            <Typo>
              Due to some changes in the software security your username needs to be changed /
              updated.
            </Typo>
            <Box sx={classes.inputWrapper}>
              <Controller
                name={'changeUserName'}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    placeholder='Username'
                    helperTxt='Please use only alphanumeric characters with no spaces.'
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Box>
          </Box>
        </NoSpaceModal>
        <div className='layout vertical logincontainer'>
          <div className='layout horizontal center center-justified app-logo-container'>
            <Img src={Logo} alt='logo' />
          </div>

          <Box sx={classes.titleContainer}>
            <Typo fontWeight={500} fontSize={24}>
              Palmetto Portal
            </Typo>
            <Box sx={classes.resetInfoContainer} hidden={!props.isResetPassword}>
              You have successfully set your password. Login below
            </Box>
            <Box sx={classes.errorStatusContainer} hidden={!props.errorStatusMsg}>
              {props.errorStatusMsg}
            </Box>
          </Box>

          <div className='flex login-input-container'>
            <div className='layout horizontal'>
              <Controller
                name='username'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <NormalTextField
                    error={Boolean(error)}
                    id='username-box'
                    label='Username'
                    name='username'
                    placeholder='Enter username'
                    className='flex'
                    value={value}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={inputRef1}
                  />
                )}
              />
            </div>

            <div className='layout horizontal'>
              <Controller
                name='password'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <NormalTextField
                    error={Boolean(error)}
                    id='password-box'
                    label='Password'
                    name='password'
                    type='password'
                    placeholder='Enter password'
                    className='flex'
                    value={value}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={inputRef2}
                  />
                )}
              />
            </div>
            <div className='layout horizontal' style={{ margin: '10px 0' }}>
              <Box
                sx={classes.linkWrapper}
                onClick={() => props.navigateTo(urlPathForgot.forgotPassword)}>
                <Typo sx={classes.linkText}>Forgot Password?</Typo>
              </Box>
              <Box
                sx={classes.linkWrapper}
                onClick={() => props.navigateTo(urlPathForgot.forgotUsername)}>
                <Typo sx={classes.linkText}>Forgot Username?</Typo>
              </Box>
            </div>

            {errMessage && (
              <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
                {errMessage}
              </FormHelperText>
            )}

            <Button
              id='login'
              sx={{ mt: errMessage ? 2 : 3 }}
              progress={inProcess}
              onClick={handleSubmit(onSubmit)}
              fullWidth>
              LOGIN
            </Button>
            <div className='layout horizontal center center-justified request-account-section'>
              <span>Don’t have an account? </span> &nbsp;{' '}
              <p
                className='bottom-link'
                style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => props.navigateTo(urlPathForgot.requestAccess)}>
                Request access
              </p>
            </div>
          </div>
        </div>
        <ConfirmationModal
          open={props.multiUserModal}
          noCancel={true}
          title='Information'
          titleBtnRight='OK'
          width={isMobile ? 'unset' : '450px !important'}
          onConfirm={props.onMultiUserConfirm}>
          <Typo color='common.black' fontSize={14}>
            {`Usernames with spaces are no longer valid due to some changes in software security.
              Please contact your organization's Palmetto administrator to receive an updated
              username/password`}
          </Typo>
        </ConfirmationModal>
        <ConfirmationModal
          open={successChangeModal}
          noCancel={true}
          title='Information'
          titleBtnRight='OK'
          width={isMobile ? 'unset' : '450px !important'}
          onConfirm={props.onSuccessConfirm}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
            <Typo color='common.black' fontSize={14}>
              {`Success! Your username has been changed.`}
            </Typo>
            <Typo color='common.black' fontSize={14}>
              {`Please proceed to the login page and enter your new username to access your account.`}
            </Typo>
            <Typo color='common.black' fontSize={14}>
              {`Thank you for updating your username!`}
            </Typo>
          </Box>
        </ConfirmationModal>
      </div>
    </MainLayout>
  );
};

export default LoginView;
