import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enum/AuthProtect';

export const validateResetToken = (data: { accessToken: string; userId: number }): IEndpoint => {
  const { accessToken, userId } = data;
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `/resetpassword/?access_token=${accessToken}&userId=${userId}`,
  };
};

export const resetPassword = (data: {
  userId: number;
  password: string;
  accessToken: string;
}): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: '/resetpassword?access_token=' + data.accessToken,
    data,
  };
};
