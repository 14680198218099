import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { IidTokenSession } from 'interfaces/session.interface';
import { Nullable } from 'utils/Nullable';
import { refreshSession } from 'store/slices/cognito/cognitoThunk';

export function getTokenExpiration(tokenSession: Nullable<Partial<IidTokenSession>>) {
  const expValue = tokenSession && tokenSession.payload?.exp;
  if (expValue) return expValue;
}

export async function refreshAccessToken(): Promise<CognitoUserSession> {
  return await refreshSession();
}
