const useStyles = (hasPermission: boolean) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    background: '#FFF',
    padding: '12px',
    position: 'relative',
    width: '100%',
    cursor: hasPermission ? 'pointer' : 'unset',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    gap: '8px',
    justifyContent: 'space-between',
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  title: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#252525',
    textTransform: 'uppercase',
  },
});

export default useStyles;
