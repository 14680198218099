/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// import { IGroupContact } from 'interfaces/groups.interface';
import apiFetch from 'services/apiFetch';
import { getContacts } from 'services/controlPanelEnpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IAccount2Group } from 'interfaces/userinfo.interface';
import { IGroupContact } from '../../../interfaces/groups.interface';

const useFetchContact = (
  isOpen: boolean,
  activeGroupID: number | null,
  existingContacts: Partial<IGroupContact>[]
) => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state);

  const [contacts, setContacts] = useState<IAccount2Group[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>();
  const [selectedCount, setSelectedCount] = useState<number>(0);

  const loadContacts = async (page: number) => {
    if (!isOpen) return;
    //   *: Function to fetch contacts
    setLoading(true);
    try {
      const endpoint = getContacts({
        page,
        activeGroup: activeGroupID,
        search: search?.length ? search : undefined,
      });
      //   *: Fetch contacts
      const result = await apiFetch<{
        count?: number;
        totalPages?: number;
        dataset: IAccount2Group[];
      } | null>(endpoint, store, dispatch).then((res) => res?.data || null);
      if (result && result.totalPages) setTotalPages(result.totalPages);
      if (result && result.dataset.length) {
        //   *: Set contacts
        const tempData = result.dataset.map((item) => ({ ...item, isChecked: false }));
        if (search?.length && search.length > 1) {
          if (existingContacts.length) {
            const updatedContacts = tempData.filter(
              (contact) =>
                !existingContacts.some((exist) => exist.pvAccountID === contact.pvAccountID)
            );
            setContacts(updatedContacts);
          } else {
            setContacts(tempData);
          }
        } else {
          setContacts((prevContacts) => {
            const updatedContacts = tempData.filter(
              (contact) =>
                !existingContacts.some((exist) => exist.pvAccountID === contact.pvAccountID)
            );
            const combinedContacts = [...prevContacts, ...updatedContacts];
            combinedContacts.filter(
              (contact) =>
                !existingContacts.some((exist) => exist.pvAccountID === contact.pvAccountID)
            );
            const uniqueContacts = new Map(
              combinedContacts.map((contact) => [contact.pvAccountID, contact])
            );
            return Array.from(uniqueContacts.values());
          });
        }
      } else {
        setContacts([]);
      }
      setLoading(false);
    } catch (error) {
      console.log('loadContacts catch:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page > totalPages) return;
    // noinspection JSIgnoredPromiseFromCall
    loadContacts(page);
  }, [page, isOpen]);

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (!loading) {
        if (!search || search.length === 0) setContacts([]);
        // noinspection JSIgnoredPromiseFromCall
        loadContacts(0);
        setPage(0);
      }
    }, 2000);

    return () => clearTimeout(delayFn);
  }, [search]);

  const onSelectedContact = (checked: boolean, id: number) => {
    const updatedContacts = contacts.map((contact) => {
      if (contact.pvAccountID === id) {
        return { ...contact, isChecked: checked };
      }
      return contact;
    });
    const selectedContacts = updatedContacts.filter((contact) => contact.isChecked);
    setSelectedCount(selectedContacts.length);
    setContacts(updatedContacts);
  };

  return {
    contacts,
    setContacts,
    loading,
    page,
    setPage,
    search,
    setSearch,
    onSelectedContact,
    selectedCount,
    setSelectedCount,
  };
};

export default useFetchContact;
