import React from 'react';
import { useTheme } from '@mui/material';
import useStyles from './ActionButtons.styles';
import Box, { BoxProps as MuiBoxProps } from '@mui/material/Box';
import { Button } from '../../primitives';

interface ActionButtonsProps {
  readOnly: boolean;
  isSaving: boolean;
  isDeleting?: boolean;
  withSave?: boolean;
  withCancel?: boolean;
  withDelete?: boolean;
  isSaveDisable?: boolean;
  isModal?: boolean;
  saveLabel?: string;
  save?: React.MouseEventHandler<HTMLButtonElement>;
  cancel?: React.MouseEventHandler<HTMLButtonElement>;
  delete?: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionButtons: React.FC<ActionButtonsProps & MuiBoxProps> = ({
  readOnly = true,
  isSaving = false,
  isDeleting = false,
  withSave = true,
  withCancel = false,
  withDelete = false,
  isSaveDisable = false,
  isModal = false,
  saveLabel = 'SAVE',
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, withDelete, isModal);

  return (
    <Box sx={classes.footerContainer} hidden={readOnly} {...props}>
      <Box sx={classes.footerWrapper}>
        {withDelete && (
          <Button
            sx={{ fontWeight: 600, width: '150px' }}
            onClick={props.delete}
            color={'error'}
            progress={isDeleting}
            disabled={isSaving}>
            DELETE
          </Button>
        )}
        <Box sx={classes.endContainer}>
          {withCancel && (
            <Button
              neutral
              sx={{ fontWeight: 600, width: '150px', color: '#767676' }}
              onClick={props.cancel}
              disabled={isSaving || isDeleting}>
              CANCEL
            </Button>
          )}
          {withSave && (
            <Button
              sx={{ fontWeight: 600, width: '150px' }}
              progress={isSaving}
              disabled={isDeleting || isSaveDisable}
              onClick={props.save}>
              {saveLabel}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ActionButtons;
