const useStyles = (userHasPosition: boolean) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  page: {
    flex: '1 1 1px',
  },
  pendingContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: '48px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    boxShadow:
      '0px 4.13211px 10.01724px 0px rgba(0, 0, 0, 0.13), 0px 33px 80px 0px rgba(0, 0, 0, 0.26)',
  },
  pendingWrapper: {
    display: 'flex',
    maxWidth: '1440px',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '8px 24px 24px 24px',
    justifyContent: 'center',
    gap: userHasPosition ? '16px' : '8px',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
});

export default useStyles;
