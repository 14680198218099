import React from 'react';
import { useTheme } from '@mui/material';
import useStyles from './FormContent.styles';
import Box, { BoxProps } from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { Typo } from 'components/primitives';
import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/EditNote';

type FormContentProps = {
  title: string;
  bgcolor?: string;
  withEdit?: boolean;
  isEdit?: boolean;
  isAdd?: boolean;
  withToggle?: boolean;
  toggleValue?: boolean;
  btnTitle?: string;
  titleStyle?: object;
  childContainerStyle?: object;
  editBtnContainerStyle?: object;
  toggleTitle?: string;
  toggleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FormContent: React.FC<FormContentProps & BoxProps> = ({ title, bgcolor, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme, bgcolor);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.titleWrapper}>
        <Typo sx={props.titleStyle ?? classes.title}>{title}</Typo>
        {!!props.withEdit && props.isEdit && (
          <Box
            sx={props.editBtnContainerStyle ?? classes.editBtnContainer}
            style={{ marginRight: '16px' }}
            onClick={props.onClick}>
            {props.btnTitle}
            {props.isAdd ? <AddIcon fontSize='small' /> : <EditIcon fontSize='small' />}
          </Box>
        )}
        {!!props.withToggle && props.toggleValue !== undefined && (
          <Box sx={classes.toggle.container}>
            <Typo sx={classes.toggle.label} variant='caption'>
              {props.toggleTitle}
            </Typo>
            <Switch
              checked={props.toggleValue}
              onChange={props.toggleChange}
              color='primary'
              name='toggle'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              size='small'
            />
          </Box>
        )}
      </Box>
      <Box sx={props.childContainerStyle ?? classes.wrapper}>{props.children}</Box>
    </Box>
  );
};

export default FormContent;
