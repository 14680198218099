const useStyles = () => ({
  tabs: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1000,
    width: '100vw',
    color: 'white !important',
  },
  stepContainer: {
    position: 'absolute',
    bottom: 2,
    left: 0,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    py: 1,
    px: 4,
  },
  modalContainer: {
    height: '548px',
    maxHeight: '548px',
    minWidth: '325px',
    overflowY: 'scroll',
    mt: '50px',
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'transparent',
  },
  mobileContainer: {
    display: 'flex',
    width: 'calc(100vw - 20px)',
    cursor: 'pointer',
  },
  listContainer: {
    display: 'flex',
    height: 'calc(100% - 120px)',
    width: '100vw',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #E0E0E0',
    height: '90px',
    width: '100%',
    py: '12px',
    px: '16px',
    gap: '6px',
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'no-wrap',
  },
});

export default useStyles;
