import type { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    minWidth: '270px',
    padding: '8px 12px 8px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flex: '1 0 0',
    borderRadius: '4px',
    background: theme.palette.common.white,
    position: 'relative',
    cursor: 'pointer',
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2px',
    flex: '1 0 0',
  },
  title: {
    header: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.palette.grey[500],
      lineHeight: '140%',
    },
    rowSubTitle: {
      display: 'flex',
      gap: '4px',
      fontSize: '12px',
      flexWrap: 'wrap',
    },
  },
  icon: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    cursor: 'pointer',
  },
});

export default useStyles;
