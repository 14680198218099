import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, number, array } from 'yup';
import { ISelectedPositions } from 'interfaces/ISelectOptions';

export interface UserPositionsFormData {
  groupId: number;
  userId: number;
  positions: ISelectedPositions[];
}

function usePositionsForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        groupId: number().required('This field is required'),
        userId: number().required('This field is required'),
        positions: array().test({
          name: 'atLeastONe',
          message: 'Required at least one position!',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          test: (value: any) => {
            return value.some((v: ISelectedPositions) => v.checked);
          },
        }),
      }),
    []
  );

  return useForm<UserPositionsFormData>({
    defaultValues: {
      groupId: 0,
      userId: 0,
      positions: [],
    },
    resolver: yupResolver(validationSchema),
  });
}

export default usePositionsForm;
