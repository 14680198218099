import React from 'react';
import { ModalProps } from '@mui/material/Modal';
import Box, { BoxProps } from '@mui/material/Box';
import { IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './AssignGroupModal.styles';
import { Modal, Typo } from 'components/primitives';

interface IAssignGroupProps {
  title?: string;
  handleModal: (confirm: boolean) => void;
  maxWidth?: string;
}

type AssignGroupModalProps = IAssignGroupProps & ModalProps;

const AssignGroupModalView: React.FC<AssignGroupModalProps & BoxProps> = ({
  title = 'Assign Group',
  handleModal,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal sx={{ overflow: 'hidden' }} {...props}>
      <Box sx={{ maxWidth: props.maxWidth ? props.maxWidth : 'unset' }}>
        <Box sx={classes.header}>
          <Box sx={classes.headerTitle}>
            <IconButton onClick={() => handleModal(false)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
            <Typo variant='h4' sx={{ ml: theme.spacing(1) }}>
              {title}
            </Typo>
          </Box>
        </Box>
        {props.children}
      </Box>
    </Modal>
  );
};

export default AssignGroupModalView;
