import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  Grid,
  InputAdornment,
  Paper,
  FormHelperText,
  useTheme,
  Divider,
  ListSubheader,
} from '@mui/material';
import { MainLayout } from 'components/layout';
import { Button, Typo, TextField } from 'components/primitives';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ReqAccessProps } from './RequestAccess.props';
import useStyles from './RequestAccess.styles';
import { PhoneNumberFormat } from 'common/utils/PhoneNumberFormat';
import { ForgotStatusStep as StatusStep } from 'common/enum';

const RequestAccessView: React.FC<ReqAccessProps> = ({
  form,
  showPassword,
  toggleShowPassword,
  onSubmit,
  isMobile,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { control, handleSubmit, watch, formState } = form;
  const countyOptions = watch('selectOption');
  const agencyOptions = watch('agencyOption');
  const steps = watch('steps');
  const SelectError = formState.errors?.groupId?.message;
  const hasError = Object.keys(formState.errors).length > 0;

  const handlePhoneChange =
    (onChange: (value: string | undefined | null) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(PhoneNumberFormat(event.target.value));
    };

  return (
    <MainLayout>
      <Box sx={classes.root}>
        <Paper
          variant='outlined'
          sx={classes.container}
          style={{
            maxHeight:
              steps === StatusStep.step1
                ? isMobile
                  ? 'unset'
                  : hasError
                  ? '550px'
                  : '490px'
                : '350px',
          }}>
          <Box sx={classes.header}>
            <Box
              sx={classes.iconContainer}
              hidden={steps !== StatusStep.step1}
              onClick={() => props.handleBack()}>
              <ArrowBackIcon sx={{ color: '#707070', height: '32px', width: '32px' }} />
            </Box>
            <Typo variant='h4'>Request Access</Typo>
          </Box>
          <Box sx={classes.form} hidden={steps !== StatusStep.step1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvPersonGivenName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='First name'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvPersonSurName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Last name'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='username'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Username'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='email'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Email address'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='standard' size='small' sx={classes.wrapper}>
                  <Typo color={theme.palette.grey[700]} fontSize={12}>
                    Agency / County
                  </Typo>
                  <Controller
                    name={'groupId'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          sx={classes.selectWrapper}
                          value={value}
                          onChange={onChange}
                          disabled={false}>
                          <ListSubheader>--- Agency ---</ListSubheader>
                          {agencyOptions && agencyOptions.length
                            ? agencyOptions.map((val) => (
                                <MenuItem key={val.pvGroupID} value={val.pvGroupID}>
                                  {val.pvGroupName}
                                </MenuItem>
                              ))
                            : null}
                          <ListSubheader>--- County ---</ListSubheader>
                          {countyOptions && countyOptions.length
                            ? countyOptions.map((val) => (
                                <MenuItem key={val.pvGroupID} value={val.pvGroupID}>
                                  {val.pvGroupName}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                        <FormHelperText error={Boolean(SelectError)} hidden={!SelectError}>
                          {SelectError ? SelectError : ''}
                        </FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvOrganizationUnitName'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Organization/Unit'
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvOfficePhone'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Work Phone'
                      value={PhoneNumberFormat(value)}
                      onChange={handlePhoneChange(onChange)}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='pvMobilePhone'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Cell Phone'
                      value={PhoneNumberFormat(value)}
                      onChange={handlePhoneChange(onChange)}
                      errMsg={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='password'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Password'
                      type={showPassword ? 'text' : 'password'}
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ cursor: 'pointer', pr: 1 }}
                            position='end'
                            onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </InputAdornment>
                        ),
                        autoComplete: 'new-password',
                      }}
                      autoComplete='new-password'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name='confirmPassword'
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      label='Confirm Password'
                      type={showPassword ? 'text' : 'password'}
                      value={value}
                      onChange={onChange}
                      errMsg={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ cursor: 'pointer', pr: 1 }}
                            position='end'
                            onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </InputAdornment>
                        ),
                        autoComplete: 'new-password',
                      }}
                      autoComplete='new-password'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={classes.btnFooter}>
                <Button
                  sx={{ paddingX: '38px', textTransform: 'uppercase' }}
                  onClick={handleSubmit(onSubmit)}
                  progress={props.inProcess}>
                  {props.inProcess ? (
                    <span style={{ paddingRight: '12px' }}>Sending request</span>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={classes.lastStepContainer} hidden={steps !== StatusStep.step3}>
            <Box sx={classes.iconContainer}>
              {props.requestFailed ? (
                <ErrorIcon
                  sx={{ color: theme.palette.error.main, height: '56px', width: '56px' }}
                />
              ) : (
                <CheckCircleIcon sx={{ color: 'green', height: '56px', width: '56px' }} />
              )}
            </Box>
            <Typo fontSize={20} fontWeight={500}>
              Account request {props.requestFailed ? 'failed' : 'sent'}
            </Typo>
            <Typo fontSize={16} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {props.infoMsg}
            </Typo>
            <Divider variant='middle' sx={{ width: '100%' }} />
            <Typo
              fontSize={16}
              sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={() => props.handleBack()}>
              Return to login
            </Typo>
          </Box>
        </Paper>
        <Typo
          fontSize={12}
          sx={{ color: 'rgba(0, 0, 0, 0.54)', mt: '14px', px: '24px', textAlign: 'center' }}>
          All data in palmetto is to be considered FOUO and not for public dissemination
        </Typo>
      </Box>
    </MainLayout>
  );
};

export default RequestAccessView;
