import { useMemo } from 'react';
import { IGroupWithContacts } from 'interfaces/groups.interface';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const defaultValues: IGroupWithContacts = {
  pvGroupID: 0,
  pvGroupName: '',
  pvGroupTitle: null,
  pvGroupComment: null,
  pvVoid: 0,
  pvParentGroupID: 0,
  pvDomainID: 0,
  pvIsAgency: 0,
  pvParentGroupName: null,
  pvParentGroupTitle: null,
  pvParentGroupComment: null,
  pvParentGroupIsAgency: 0,
  contacts: [],
};

function useDetailForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        pvGroupName: string().required('Group Name is required'),
        pvGroupTitle: string().required('Group Title is required'),
      }),
    []
  );
  return useForm({ defaultValues, resolver: yupResolver(validationSchema) });
}

export default useDetailForm;
