import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enum/AuthProtect';
import { ISendContactMsg } from 'interfaces/notification.interface';

export const sendSupportMsg = (data: ISendContactMsg): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/contact-support',
    data,
  };
};

export const requestUsername = (data: { email: string }): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: `/forgot-username`,
    data,
  };
};

export const requestPasswordReset = (data: { email: string; username: string }): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'PUT',
    url: '/forgotpassword',
    data,
  };
};
